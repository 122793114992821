export function VCbase(
    initailVCFund,
    numofApplicant,
    SEF_COST_PER_APPLICANT,
    numofFamilymembers,
    SEF_COST_PER_DEPENDENT,
    RENEWAL_RESIDENCE_PERMIT_APPLICANT,
    VCFUND_TOTAL_INITIAL_FEE_PERCENT) {

    let initialCost = (numofApplicant * SEF_COST_PER_APPLICANT) + (numofFamilymembers * SEF_COST_PER_DEPENDENT)
        + ((numofApplicant + numofFamilymembers) * RENEWAL_RESIDENCE_PERMIT_APPLICANT) +
        (initailVCFund * (VCFUND_TOTAL_INITIAL_FEE_PERCENT / 100))

    initialCost = Math.round(initialCost);

    return initialCost;
}

export function VCFinalFundValue(Exityears, initailVCFund, VCFundCAGRpercent){
    //Final Fund Value = ((CAGR / 100) + 1) raised to exit year * Initial Fund Amount
    let finalAmt= Math.pow(((VCFundCAGRpercent/100) + 1), Exityears ) * initailVCFund
    return Math.round(finalAmt);
}

//Gives array of costs till exit year
//considering odd or even year and final year calculations
export function VCCostsArray(
    Exityears, 
    initailVCFund, 
    VCFUND_TOTAL_OUTGOING_FEE_PERCENT, 
    numofApplicant,
    RENEWAL_RESIDENCE_PERMIT_APPLICANT,
    numofFamilymembers,
    RENEWAL_RESIDENCE_PERMIT_DEPENDENT,
    VCFinalFundValue,
    VCFUND_PERFORMANCE_FEE_PERCENT
    ){
        let renewalFee=(numofApplicant*RENEWAL_RESIDENCE_PERMIT_APPLICANT) + (numofFamilymembers*RENEWAL_RESIDENCE_PERMIT_DEPENDENT);
        let basicCost= Math.round((initailVCFund*VCFUND_TOTAL_OUTGOING_FEE_PERCENT*0.01))
        let evenyearCost= basicCost + renewalFee;
        
        let FinalyearCostifEven= Math.round(basicCost + renewalFee + ((VCFinalFundValue - initailVCFund )* 0.01 * VCFUND_PERFORMANCE_FEE_PERCENT));
        let FinalyearCostifOdd= Math.round(basicCost + ((VCFinalFundValue - initailVCFund )* 0.01 * VCFUND_PERFORMANCE_FEE_PERCENT));
        let tempArr=Array.from(new Array(Exityears)).fill(0); //creating array of length==exit years and filling it with zeros
        let costArr=tempArr.map((ele, index)=>{
            if(index===tempArr.length-1){
                if((index+1)%2===0){
                    //Final Year is EVEN Year
                    return Math.round(-FinalyearCostifEven)
                }else{
                    //Final Year is ODD Year
                    return  Math.round(-FinalyearCostifOdd)
                }
            }else{
                if((index+1)%2===0){
                    //renewal year
                    return  Math.round(-evenyearCost)
                }else{
                    return Math.round(-basicCost)
                }
            }
        })

        return costArr;
}

export function VCCashflowArray(costArr, finalAmt, initialCost, initailVCFund){

    let exitYearBalance= finalAmt - Math.abs(costArr[costArr.length - 1]);
    let allCostsArr=[-(initialCost + initailVCFund), ...costArr];

    let VCCashflowArray=[...allCostsArr.slice(0,-1), exitYearBalance]
    return VCCashflowArray;
}

export function NetGrowthVC(costArr, initialCost, initailVCFund, Exityears, VCFundCAGRpercent){

    let incomeArr=[Math.round(initailVCFund*VCFundCAGRpercent*0.01)];
    let AccumulatedCostsArr=[-(initialCost)];
    let netGrowthRateArr=[];

    for(let i=0;i<(Exityears + 1);i++){
        if(i!==0){
            incomeArr[i]=Math.round(((initailVCFund + incomeArr[i-1]) * VCFundCAGRpercent * 0.01) + incomeArr[i-1])
            AccumulatedCostsArr[i]= AccumulatedCostsArr[i-1] + costArr[i-1]
            netGrowthRateArr[i-1]=100 + Math.round(((incomeArr[i-1]-Math.abs(AccumulatedCostsArr[i]))*100)/initailVCFund)
        }
    }
    return netGrowthRateArr;
}   