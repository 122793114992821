import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Header from '../Homepage/Header'
import Footer from '../Homepage/Footer'
import Inquiryform from '../Common Components/Inquiryform';
import '../../Styling/desktop/contactus.css';
import '../../Styling/desktop/visastyle.css';
import Servicecard from './Servicecard';
import Shortstaycard2 from '../Common Components/Shortstaycard2';
function Services2() {
    useEffect(()=>{
        document.title="Insurance | Service | Visa Discovery"
      },[])
    return (
        <Box width="100vw" maxWidth="100%" paddingTop="1rem">
            <Header />
            <Box className="services-cover-container" mt="-1rem" width="100%" height="45vh" textAlign="center">
                <Typography variant="h4" color="white" paddingTop="30vh ">Insurance</Typography>
            </Box>
            <Box className="services-box" sx={{ width: { xs: 'calc(100vw - 3rem)' }, mt: '2rem', mb: '2rem' }} margin="auto">
                <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ width: { xs: '100%', md: 'calc(100% - 30rem)' }, paddingRight: { xs: '0', md: '3rem' } }}>
                        <Typography mb=".5rem" variant="h4" ml="0px important">Buy Insurance</Typography>
                        <Typography variant="body2" style={{ textAlign: 'justify' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Typography>
                        <Typography mt="1rem" mb="1rem" variant="h4" ml="0px important">Service Providers</Typography>
                        <Servicecard
                            title="Star Health Insurance"
                            caption="Star Health Insurance is an online portal that connects you with gets you the widest range of insurance options"
                            website="Starhealth.com"
                        />
                        <Servicecard
                            title="Star Health Insurance"
                            caption="Star Health Insurance is an online portal that connects you with gets you the widest range of insurance options"
                            website="Starhealth.com"
                        />
                        <Servicecard
                            title="Star Health Insurance"
                            caption="Star Health Insurance is an online portal that connects you with gets you the widest range of insurance options"
                            website="Starhealth.com"
                        />
                        <Typography mt="4rem" mb="1rem" variant="h4" ml="0px important">Other Services</Typography>
                        <Box sx={{ width: { xs: 'calc(100vw - 3rem)', md: 'calc(100vw - 12rem)' }, flexDirection: { xs: 'column', md: 'row' } }} display="flex" gap="2rem" alignItems="center">
                            <Shortstaycard2
                                title="Learn Portuguese"
                                description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                                path='/services1'
                            />
                            <Shortstaycard2
                                title="Visa Application Agents"
                                description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                                path='/services3'
                            />
                            <Shortstaycard2
                                title="Foreign Exchange"
                                description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                                path='/services4'
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '30rem' }, mt: { xs: '2rem', md: '0' } }}>
                        <Inquiryform caller="Service: Insurance" />
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}

export default Services2
