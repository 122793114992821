import React, { useState, useEffect } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
function Topscrollbtn() {
  const [hide, setHide] = useState(true);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    if (offset > window.innerHeight) {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [offset]);
  return (
    <div className="scroll-btn-div" style={{ display: hide ? "none" : "flex" }}>
      <IconButton
        disableFocusRipple
        disableRipple
        onClick={() => window.scrollTo(0, 0)}
      >
        <ExpandLess fontSize="large" />
      </IconButton>
    </div>
  );
}

export default Topscrollbtn;
