import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import arrowsvg from "../../Images/Icon feather-arrow-right-circle-white.svg";
import touristimg from "../../Images/TLC Photo.png";
import "../../Styling/desktop/commoncomp.css";
import Dialog from "@mui/material/Dialog";
import PopupForm from "./PopupForm";
import { Link } from "react-router-dom";
function Bookconsultation(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        className={
          props.fixed ? "bookconsultation-fixed" : "bookconsultation-notfixed"
        }
        sx={{ height: { xs: "45rem", md: "77vh" } }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          onClick={() => setOpen(true)}
          sx={{
            width: { xs: "100%", sm: "50%", md: "auto" },
            maxWidth: { xs: "none", md: "14.5rem" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          style={{
            cursor: "pointer",
            height: "78%",
            padding: "1rem 1rem 0rem",
            backgroundColor: "#2F2FA2",
            borderRadius: "1rem",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            height="20%"
            width="100%"
            sx={{ minHeight: "85px" }}
            mb="8px"
          >
            <Box height="100%" sx={{ width: { xs: "88%", md: "90%" } }}>
              <Typography
                variant="body2"
                color="white"
                sx={{ fontSize: { xs: "1.5rem", md: "1rem" } }}
              >
                Book a Free Consultation to simplify your Visa Process
              </Typography>
            </Box>
            <img
              src={arrowsvg}
              style={{
                width: "18px",
                marginLeft: "5px",
                fill: "white",
                alignSelf: "flex-start",
              }}
              alt="arrow svg"
            ></img>
          </Box>
          <Box
            width="calc(100% + 2rem)"
            height="auto"
            marginLeft="-15.5px"
            display="inline-block"
            style={{ overflow: "hidden", borderRadius: "17px" }}
          >
            <img
              src={touristimg}
              style={{
                width: "100%",
                objectFit: "fill",
                position: "relative",
                bottom: "-8px",
              }}
              alt="protugal tourist"
            ></img>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "50%", md: "auto" },
            maxWidth: { xs: "none", md: "14.5rem" },
          }}
          style={{
            cursor: "pointer",
            height: "22%",
            marginTop: ".8rem",
            backgroundColor: "#E7D626",
            borderRadius: "1rem",
            padding: ".8rem",
          }}
        >
          <Link to={"/visa-discovery-tool"} style={{ textDecoration: "none" }}>
            <Box height="70%" width="100%">
              <Typography
                variant="body2"
                color="white"
                sx={{ fontSize: { xs: "1.5rem", md: "1rem" } }}
              >
                Discover the right Visa for you with our{" "}
                <span style={{ color: "#2F2FA2", fontWeight: "700" }}>
                  &nbsp; Visa Discovery Tool{" "}
                </span>
              </Typography>
            </Box>
            <div
              style={{
                textAlign: "right",
                width: "100%",
                height: "15%",
                float: "right",
              }}
            >
              <img
                src={arrowsvg}
                style={{ width: "1.5rem", height: "1.2rem", fill: "white" }}
                alt="arrow svg"
              ></img>
            </div>
          </Link>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            opacity: "0.4 !important",
            backgroundColor: "#2F2FA2",
          },
          zIndex: "99002",
          "& .MuiDialog-paper": {
            maxWidth: "none",
            borderRadius: { xs: "1rem", md: "1.5rem" },
            maxHeight: { xs: "none", md: "75vh" },
            width: { xs: "-webkit-fill-available", md: "auto" },
          },
        }}
      >
        <PopupForm title={props.title} openSetterFn={setOpen}></PopupForm>
      </Dialog>
    </>
  );
}

export default Bookconsultation;
