import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Bookconsultation from "../Common Components/Bookconsultation";
import Wheretoapply from "../Common Components/Wheretoapply";
import Header from "../Homepage/Header";
import Homesection5mid from "../Homepage/Homesection5mid";
import Footer from "../Homepage/Footer";
import Shortstaycard from "./Shortstaycard";
// import Additionalservices from '../Common Components/Additionalservices';
import medipic from "../../Images/Medical.jpg";
import seasonalpic from "../../Images/Seasonal Work - Short Stay.jpg";
import Conferencepic from "../../Images/Conference.jpg";
import touristpic from "../../Images/Short Stay.jpg";
import friendpic from "../../Images/short stay/short stay - Visiting FriendsFamily.jpg";
import seamenpic from "../../Images/short stay/short stay Transit Visa (Seamen).jpg";
import citizenpic from "../../Images/short stay/short stay EU Citizen.jpg";
import "../../Styling/desktop/visastyle.css";
import { Helmet } from "react-helmet";

function Shortstayvisa() {
  useEffect(() => {
    document.title = "Short Stay Visa Schengen | Visa Discovery";
  }, []);
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Short Stay Visas",
                item: "https://visadiscovery.com/portugal-short-stay-visa",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" padding="1rem 0 0 0" maxWidth="100%">
        <Header />
        <Box
          className="visas-container"
          width="100%"
          mt="calc(12vh + 1rem)"
          display="flex"
          sx={{
            padding: { xs: "0rem 1.5rem 3rem" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "80%" },
              padding: { xs: "0rem 0 3rem", md: "0rem 2rem 0 0" },
            }}
            height="100%"
          >
            <Typography gutterBottom variant="h1" fontSize="4.5rem">
              Short Stay Visas
            </Typography>
            <Typography gutterBottom variant="body2">
              If you are planning a short visit to Portugal or are travelling
              through Portugal, you may need to apply for a “Short Stay Visa” -
              for travelling to Portugal for less than 90 days. Portugal is one
              of the 26 European countries forming the Schengen Area which have
              no border controls between them. These countries issue a common
              visa, the Schengen visa for the stay upto 90 days.
            </Typography>
            <Typography gutterBottom variant="body2">
              This category of Visas is perfect for tourists and people
              in-transit, going out of the Schengen region.
            </Typography>
            <Typography
              variant="h4"
              color="#2F2FA2"
              fontWeight="bold"
              mt="1rem"
              mb="1rem"
            >
              Type of Short Stay Visas
            </Typography>
            <Box>
              <Grid container spacing={2} rowSpacing={6}>
                <Grid item sm={6} md={6} lg={4}>
                  <Shortstaycard
                    pic={Conferencepic}
                    tagName="Seasonal Work"
                    title="Business/Conference"
                    altText="business short stay visa portugal"
                    day="Upto 90 days"
                    cost="EUR 80"
                    description="Seasonal Work"
                    applicationtime="15 days"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <Shortstaycard
                    pic={touristpic}
                    tagName="Tourist Visa"
                    title="Tourist"
                    altText="portugal visa tourist"
                    day="Upto 90 Days"
                    cost="EUR 80"
                    description="Tourist Visa"
                    applicationtime="15 days"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <Shortstaycard
                    pic={friendpic}
                    tagName="Tourist Visa"
                    title="Visiting Friends/Family"
                    altText="tourist visa in portugal"
                    day="Upto 90 days"
                    cost="EUR 80"
                    description="Tourist Visa"
                    applicationtime="15 days"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <Shortstaycard
                    pic={seamenpic}
                    tagName="Transit Visa"
                    title="Transit Visa (Seamen)"
                    altText="short stay visa Schengen"
                    day="NA"
                    cost="EUR 80"
                    description="Transit Visa"
                    applicationtime="15 days"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <Shortstaycard
                    pic={citizenpic}
                    tagName="Tourist Visa"
                    title="EU Citizen - Friends and Family"
                    altText="short term visa portugal"
                    day="Upto 90 days"
                    cost="EUR 80"
                    description="Tourist Visa"
                    applicationtime="15 days"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <Shortstaycard
                    pic={medipic}
                    tagName="Tourist Visa"
                    title="Travel Medical Insurance"
                    altText="short term visa for portugal"
                    day="Upto 90 days"
                    cost="EUR 80"
                    description="Tourist Visa"
                    applicationtime="15 days"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <Shortstaycard
                    pic={seasonalpic}
                    tagName="Seasonal Work"
                    title="Seasonal Work"
                    altText="short stay visa for portugal"
                    day="Upto 90 days"
                    cost="EUR 80"
                    description="Seasonal Work"
                    applicationtime="15 days"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "20%" },
              mb: { xs: "1rem", md: "1rem" },
            }}
          >
            <Bookconsultation fixed="fixed" title="Short Stay Visa Page" />
          </Box>
        </Box>
        <Wheretoapply />
        {/* <Additionalservices /> */}
        <Homesection5mid />
        <Footer />
      </Box>
    </>
  );
}

export default Shortstayvisa;
