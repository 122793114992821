import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function TvFAQ({ refProp, bgColor }) {
    return (
        <>
            <Box width="100%" backgroundColor={bgColor} ref={refProp}>
                <Box className="section3 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                    <Box width="100%" mt="2rem" >
                        <Typography mb="1rem" variant="h4" ml="0px important">FAQs</Typography>
                        <Box sx={{
                            height: { xs: 'auto', lg: 'auto' }, width: { xs: '100%' },
                            mb: { xs: "3rem", lg: "1rem" },
                        }} className="faq-column">
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Who is eligible to apply for a Tech Visa?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    Professionals recruited by companies that develop activity in the area of technology and innovation. An important requirement to access this incentive is that these companies are properly certified by the Agency for Competitiveness and Innovation (IAPMEI), so that interested parties can obtain the Tech visa.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">What are the requirements to get certified by The Agency for Competitiveness and Innovation (IAPMEI)?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    To obtain certification, the following requirements must be met: <br />
                                    <ul style={{ marginTop: '10px', marginBottom: '10px'}}>
                                        <li>Be legally incorporated,</li>
                                        <li>Have a head office or permanent establishment in the Portuguese territory,</li>
                                        <li>Have no debts to the Tax Administration or the Social Security,</li>
                                        <li>Have no overdue wages,</li>
                                        <li>Not be a restructuring company,</li>
                                        <li>If the company is more than three years old, own a positive equity,</li>
                                        <li>Develop international tradeable goods or services activity,</li>
                                        <li>Have a positive assessment in respect of business potential,</li>
                                        <li>Be orientated towards foreign markets,</li>
                                        <li>Have identified the technical qualifications in need.</li>
                                    </ul>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">What Is the Duration of a Tech Visa?
                                </Typography>
                                <Typography variant="body2" mb="1rem">
                                With your Tech Visa, you will receive a residence permit which allows you to live and work in Portugal for upto two years. After this period, you can renew this permit at the SEF before it expires, as long as you still have a job offer from your employer. Then, you can continue to live and work in Portugal for the duration that the residence permit is issued. Five years after arriving in Portugal, you can apply for permanent residency.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Does a Tech Visa Lead to Permanent Residency in Portugal?</Typography>
                                <Typography variant="body2" mb="1rem">
                                Yes, after you have lived in Portugal for five years using a temporary resident permit, then you can apply for a permanent resident permit. Once you become a permanent resident, you will be open to the job market and no longer need to apply for a work permit. Permanent resident permits also have a longer duration, are more easily renewed, and you will enjoy similar benefits to a Portuguese citizen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Does a Tech Visa Lead to Portuguese Citizenship?</Typography>
                                <Typography variant="body2" >
                                Yes, if you have lived in Portugal as a permanent or temporary resident for at least six years, you can apply for the Portuguese citizenship. You must be proficient in Portuguese and have links to the Portuguese community.
                                </Typography>
                            </Box>
                        </Box>


                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default TvFAQ
