import React, { useRef, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import '../../Styling/desktop/homepage.css'
import '../../Styling/mobile/homepagemobile.css'
import { useHistory } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import LanguageSelector from '../Common Components/LanguageSelector';
function Header(props) {
    const history = useHistory();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [visasMoreMenuOpen, setvisasMoreMenuOpen] = React.useState(false);
    const [servicesMoreMenuOpen, setservicesMoreMenuOpen] = React.useState(false);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const visasMoreref = useRef();
    const servicesMoreref = useRef();
    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick)
    }, [])

    // console.log('ref exp',visasMoreref.current )
    // const handleMobileMenuClose = () => {
    //     setMobileMoreAnchorEl(null);

    // };

    const handleMobileMenuClick = (event) => {
        if (mobileMoreAnchorEl) {
            setMobileMoreAnchorEl(null);
        } else {
            setMobileMoreAnchorEl(event.currentTarget);
        }
    };
    function handleOutsideClick(e) {
        // console.log('handleOutside called with event val', e)
        if (window.matchMedia('(min-width: 900px)').matches) {
            if (!visasMoreref.current?.contains(e.target) && e.target !== visasMoreref.current) {
                setvisasMoreMenuOpen(false)
            }
            if (!servicesMoreref.current?.contains(e.target) && e.target !== servicesMoreref.current) {
                setservicesMoreMenuOpen(false)
            }
        }
    }

    const renderMobileMenu = (
        <Collapse className='header-options-mobile' in={isMobileMenuOpen}  sx={{position: props.notfixed ? 'static' : "fixed", width: "calc(100vw - 2rem)",
        margin: "0 auto", top: 'calc(1rem + 12vh)', textAlign: 'center', left: '0px', right: '0px'}}>
                <p onClick={() => setvisasMoreMenuOpen(prevState => !prevState)}>Visas</p>
                <Box className="visas-options-mobile" display={visasMoreMenuOpen ? "block" : 'none'} width="100%" sx={{ opacity: '1', backdropFilter: 'blur(30px)', backgroundColor: 'var(--bgsection1)', padding: '.5rem 0' }}>
                    <Link to={'/portugal-short-stay-visa'}>
                        <p>Short Stay Visas</p>
                    </Link>
                    <Link to={'/portugal-temporary-stay-visa'}>
                        <p>Temporary Stay Visas</p>
                    </Link>
                    <Link to={'/portugal-residency-visa'}>
                        <p>Residency Visas</p>
                    </Link>
                </Box>
                <Link to={'/visa-discovery-tool'}>
                    <p >Visa Discovery Tool</p>
                </Link>
                {/* <p onClick={() => setservicesMoreMenuOpen(prevState => !prevState)}>Services</p>
                {servicesMoreMenuOpen && (
                    <Box className="visas-options-mobile" width="100%" sx={{ opacity: '1', backdropFilter: 'blur(30px)', backgroundColor: 'var(--bgsection1)', padding: '.5rem 0' }}>
                        <Link to={'/services1'}>
                            <p>Learn Portuguese</p>
                        </Link>
                        <Link to={'/services2'}>
                            <p>Insurance</p>
                        </Link>
                        <Link to={'/services3'}>
                            <p>Visa Application Agents</p>
                        </Link>
                        <Link to={'/services4'}>
                            <p>Foreign Exchange</p>
                        </Link>
                    </Box>
                )} */}
                {/* <p>Who needs a Visa?</p> */}
                <Link to={"/know-your-rights"}>
                    <p>Know Your Rights</p>
                </Link>
                <Link to={'/knowledge-center'}>
                    <p >Knowledge Center</p>
                </Link>
                <Link to={'/about-us'}>
                    <p >About Us</p>
                </Link>
                <Link to={"/contact-us"}>
                    <p >Contact Us</p>
                </Link>           
        </Collapse>
    )
    return (
        <>
            <header className={props.notfixed ? "header notfixed" : "header fixed"} style={{ borderRadius: isMobileMenuOpen ? "1.5vw 1.5vw 0 0" : "1.5vw" }}>
                <Box display='flex' alignItems='center' width='100%' height='100%' justifyContent="space-between" sx={{ padding: { xs: '0 1rem', sm: '0 25px', md: '0 1.25rem', lg: '0 28px' } }}>
                    <Box className="logo-container" onClick={() => history.push('/')} 
                        sx={{ width: { xs: '210px', sm: '325px', md: '235px', lg: '250px' }, 
                        height: { xs: '42px', sm: '65px', md: '45px', lg: '50px' } }}
                        title="visa discovery - portugal visas"
                    >
                    </Box>
                    <Box className="header-options" sx={{ display: { xs: 'none', md: 'flex' } }} alignItems='center'>
                        <Box className="visas-options-mobile" position="relative" onMouseEnter={() => {
                            // console.log('visas Hovered') 
                            setvisasMoreMenuOpen(true)
                        }} onClick={() => setvisasMoreMenuOpen(prevState => !prevState)}
                            ref={visasMoreref}
                            onMouseLeave={() => { setvisasMoreMenuOpen(false) }}
                            width="4rem" height="12vh" display="flex" alignItems="center" justifyContent="center"
                        >

                            <p >Visas</p>
                            <Box className="visas-options-mobile" display={visasMoreMenuOpen ? "block" : 'none'} position="absolute" top="12vh" left="-48px"
                                onMouseLeave={() => setvisasMoreMenuOpen(false)}
                                sx={{ opacity: '1', width: "13rem", backgroundColor: 'white', padding: '1rem 1rem', textAlign: 'center', borderRadius: '1rem', boxShadow: '4px 4px 8px #00000029' }}>
                                <Link to={'/portugal-short-stay-visa'}>
                                <p  onMouseEnter={(e) => e.target.style.fontWeight = "700"} onMouseLeave={(e) => e.target.style.fontWeight = "400"}>Short Stay Visas</p>
                                </Link>    
                                <Link to={'/portugal-temporary-stay-visa'}>
                                <p  onMouseEnter={(e) => e.target.style.fontWeight = "700"} onMouseLeave={(e) => e.target.style.fontWeight = "400"}>Temporary Stay Visas</p>
                                </Link>    
                                <Link to={'/portugal-residency-visa'}>
                                <p  onMouseEnter={(e) => e.target.style.fontWeight = "700"} onMouseLeave={(e) => e.target.style.fontWeight = "400"}>Residency Visas</p>
                                </Link>    
                            </Box>
                        </Box>

                        <Link to={'/visa-discovery-tool'}>
                            <p>
                            Visa Discovery Tool
                            </p>
                        </Link>
                        {/* <Box className="visas-options-mobile" position="relative" onMouseEnter={() => {
                            setservicesMoreMenuOpen(true)
                        }} ref={servicesMoreref} width="5rem" height="12vh" display="flex" alignItems="center" justifyContent="center" onMouseLeave={() => setservicesMoreMenuOpen(false)}>
                            <Link to={'/services'}>
                                <p>Services</p>
                            </Link>
                            <Box className="visas-options-mobile" display={servicesMoreMenuOpen ? "block" : 'none'} position="absolute" top="12vh" left="-56px"
                                onMouseLeave={() => setservicesMoreMenuOpen(false)}
                                sx={{ opacity: '1', width: "14rem", backgroundColor: 'white', padding: '1rem 1rem', textAlign: 'center', borderRadius: '1rem', boxShadow: '4px 4px 8px #00000029' }}>
                                <Link to={'/services1'}>
                                    <p onMouseEnter={(e) => e.target.style.fontWeight = "700"} onMouseLeave={(e) => e.target.style.fontWeight = "400"}>Learn Portuguese</p>
                                </Link>
                                <Link to={'/services2'}>
                                    <p  onMouseEnter={(e) => e.target.style.fontWeight = "700"} onMouseLeave={(e) => e.target.style.fontWeight = "400"}>Insurance</p>
                                </Link>
                                <Link to={'/services3'}>
                                    <p  onMouseEnter={(e) => e.target.style.fontWeight = "700"} onMouseLeave={(e) => e.target.style.fontWeight = "400"}>Visa Application Agents</p>
                                </Link>
                                <Link to={'/services4'}>
                                    <p  onMouseEnter={(e) => e.target.style.fontWeight = "700"} onMouseLeave={(e) => e.target.style.fontWeight = "400"}>Foreign Exchange</p>
                                </Link>
                            </Box>
                        </Box> */}
                        {/* <p>Who needs a Visa?</p> */}
                        <Link to={"/know-your-rights"}>
                            <p >Know Your Rights</p>
                        </Link>
                        <Link to={'/knowledge-center'}>
                            <p >Knowledge Center</p>
                        </Link>
                        <Link to={'/about-us'}>
                            <p >About Us</p>
                        </Link>
                        <Link to={"/contact-us"}>
                            <p >Contact Us</p>
                        </Link>
                        <LanguageSelector/>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <LanguageSelector/>
                    </Box>

                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <div id="nav-icon4" onClick={handleMobileMenuClick} className={isMobileMenuOpen ? "open" : ""}>
                            <span></span>
                            <span></span>
                        </div>
                    </Box>
                </Box>
            </header>
            {renderMobileMenu}
        </>
    )

}

export default Header
