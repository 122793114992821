import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import '../../Styling/desktop/homepage.css'
import '../../Styling/desktop/commoncomp.css'
import strongpassportpic from '../../Images/VD Icons/6th Strongest Passport.svg';
import accesspic from '../../Images/VD Icons/Access to all Schengen.svg';
import notaxpic from '../../Images/VD Icons/No tax worldwide.svg';
import euroleadingplace from '../../Images/VD Icons/Europes Leading Destination 2017-2020.svg'

function Homesection4() {
    const card1 = (
        <Card className="cardhoverstyle" style={{ borderRadius: "1rem", backgroundColor: "white" }} sx={{ minHeight: { xs: '9rem', md: '9rem' }, textAlign: "center", display: 'flex', padding: '1rem', paddingBottom: '24px' }}>
             <Box className="icon-box-home-about">
                    <img src={strongpassportpic} alt="portugal visa - 6th strongest" ></img>
             </Box>
            <Typography variant="body2" width="100%" style={{ marginTop: 'auto' }}>6th Strongest Passport in the world</Typography>
        </Card>
    )
    const card2 = (
        <Card className="cardhoverstyle" style={{ borderRadius: "1rem", backgroundColor: "white" }} sx={{ minHeight: { xs: '9rem', md: '9rem' }, textAlign: "center", display: 'flex', padding: '1rem', paddingBottom: '24px' }}>
            <Box className="icon-box-home-about">
                    <img src={accesspic} alt="access for schengen visa" ></img>
             </Box>
            <Typography variant="body2" width="100%" style={{ marginTop: 'auto' }}>Access to all Schengen Countries</Typography>
        </Card>
    )
    const card3 = (
        <Card className="cardhoverstyle" style={{ borderRadius: "1rem", backgroundColor: "white" }} sx={{ minHeight: { xs: '9rem', md: '9rem' }, textAlign: "center", display: 'flex', padding: '1rem', paddingBottom: '24px' }}>
            <Box className="icon-box-home-about">
                    <img src={notaxpic} alt="no tax - residence visa portugal" ></img>
             </Box>
            <Typography variant="body2" width="100%" style={{ marginTop: 'auto' }}>No tax on worldwide income on residency</Typography>
        </Card>
    )
    const card4 = (
        <Card className="cardhoverstyle" style={{ borderRadius: "1rem", backgroundColor: "white" }} 
        sx={{ minHeight: { xs: '9rem', md: '9rem' }, textAlign: "center", display: 'flex', padding: '1rem', paddingBottom: '24px' }}
        onClick={() => window.open("https://www.google.com/url?q=https://www.worldtravelawards.com/award-europes-leading-destination-2020&sa=D&source=docs&ust=1639392939233000&usg=AOvVaw3OJCeJakZYHPhG-T1XzKVg", "_blank")}
        >
             <Box className="icon-box-home-about">
                    <img src={euroleadingplace} alt="portugal - leading destination" ></img>
             </Box>
            <Typography variant="body2" width="100%" style={{ marginTop: 'auto' }}>Europe's Leading Destination 2017-2020</Typography>
        </Card>
    )
    
    return (
        <Box className="home-section4-container" width="100vw" maxWidth="100%" display="flex" minHeight="750px" sx={{ flexDirection: { xs: 'column', md: 'row' }, height: { xs: 'auto', md: '100vh' } }}>
            <Box className="home-section4-img-box" width="100%" height="70vmin" sx={{ display: { xs: 'block', md: 'none' } }} />
            <Box sx={{ width: { xs: '100%', md: '50%' }, height: { xs: 'auto', md: '100%' } }} display="flex" alignItems="center">
                <Box width="100%" sx={{ height: { xs: '100%', md: 'min-content' }, padding: { xs: '2rem 1.5rem', md: '3rem' } }} backgroundColor="#F0F0FF" >
                    <Box sx={{ textAlign: { xs: 'center', md: 'left' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                        <Typography variant="h4" color="var(--tblue) ">Why Choose Portugal?</Typography>
                        <Typography mt="1rem" variant="body2">Portugal, with it's rich cultural and dynamic history has in its land all types of beauty you can think of - from diverse beaches to sandy, pristine beaches to rolling hills and mountains. You won’t have to travel far to get to your perfect destination.</Typography>
                        <Typography mt="1rem" variant="body2">Beyond the beauty, Portugal has become a leading destination for business in sectors like technology, tourism & hospitality textiles, manufacturing, real estate and much more. Portugal is also becoming the preferred location for many young, dynamic startups that relocate or create additional locations here, from across the world.</Typography>
                        <Box mt="1.5rem">
                            <Grid container rowSpacing={{ xs: 2, md: 2}} columnSpacing={{ xs: 2, md: 2 }}>
                                <Grid item xs={6}>
                                    {card1}
                                </Grid>
                                <Grid item xs={6}>
                                    {card2}
                                </Grid>
                                <Grid item xs={6}>
                                    {card3}
                                </Grid>
                                <Grid item xs={6}>
                                    {card4}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="home-section4-img-box" width="50%" height="100%" sx={{ display: { xs: 'none', md: 'block' } }} />
        </Box>
    )
}

export default Homesection4
