import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import '../../Styling/desktop/CostTool.css'
export default function ControlledRadioButtonsGroup({currValue, setcurrValue}) {
   

  const handleChange = (event) => {
    setcurrValue(event.target.value);
  };

  return (
    <>
    <FormControl sx={{width: '140px', height: '40px', backgroundColor: 'white', borderRadius: '12px', 
    position: 'relative', zIndex:'1'}}>
      <RadioGroup
        row
        aria-labelledby="currency-radio-buttons-group"
        name="currency-radio-buttons-group"
        value={currValue}
        onChange={handleChange}
        sx={{position: 'relative', height: '100%', width: '100%', justifyContent: 'space-around'}}
      >
        <FormControlLabel 
            sx={{marginLeft: '0px', 
                marginRight: '0px', 
                '& .MuiButtonBase-root':{display: 'none'},
                '& .MuiTypography-root':{
                    fontWeight: '400',
                    color: currValue==="EUR"? 'white':'#2F2FA2'
                }
                }} 
            value="EUR" 
            control={<Radio />} 
            label="EUR" 
        />
        <FormControlLabel 
            sx={{marginLeft: '0px', 
            marginRight: '0px', 
            '& .MuiButtonBase-root':{display: 'none'},
            '& .MuiTypography-root':{
                fontWeight: '400',
                color: currValue==="INR"? 'white':'#2F2FA2'
            }
            }} 
            value="INR" 
            control={<Radio />} 
            label="INR" 
        />
      </RadioGroup>
      <div className="currency_slider" 
        style={{
        transform: currValue==="INR"?'translateX(70px)':'translateX(0px)'
        }}></div>
    </FormControl>
  </>
  );
}
