import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Cards from "../Golden visa/Cards";
import rect35 from "../../Images/green visa/Green Visa - Overview.jpg";
import time from "../../Images/Time.svg";
import User from "../../Images/User.svg";
import Euro from "../../Images/Euro.svg";

function Greenvsection2({ refProp }) {
  return (
    <>
      <Box
        ref={refProp}
        className="section2 wrapper"
        sx={{
          width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          padding: { xs: "1rem 0", md: "2rem 0" },
        }}
        margin="auto"
      >
        <Box
          width="100%"
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              paddingRight: { xs: "0", md: "4rem" },
            }}
          >
            <Typography mb=".5rem" variant="h4" ml="0px important">
              Overview
            </Typography>
            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
              Portugal Green Visa has similar requirements with its Golden
              Visa.  Green Visa requires a minimum investment of €500,000 in
              environmental projects. This program will grant a residency permit
              for a family including dependent children.
            </Typography>
            <Box sx={{ minHeight: { xs: ".8rem", md: "1.5rem" } }}></Box>
            <Typography mb=".5rem" variant="h4" ml="0px important">
              Highlights
            </Typography>

            <Grid
              mt=".3rem"
              container
              columnSpacing={{ xs: 2, md: 3 }}
              rowSpacing={{ xs: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Cards caption="Stay Duration" svg={time} title="5 years" />
              </Grid>
              <Grid item xs={6}>
                <Cards caption="Fees" svg={Euro} title=" View Fee Calculator" />
              </Grid>
              <Grid item xs={6}>
                <Cards
                  caption="Minimum investment"
                  svg={Euro}
                  title="EUR 500,000"
                />
              </Grid>
              <Grid item xs={6}>
                <Cards
                  caption="Application Time"
                  svg={time}
                  title="3 to 8 months"
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              margin: { xs: "2rem auto", md: "0" },
            }}
          >
            <img
              src={rect35}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "1.3rem",
                boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
              }}
              alt="green visa portugal"
            ></img>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Greenvsection2;
