import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';
import Homepage from './Components/Homepage/Homepage'
import Vdtquestions from './Components/Visa Discovery Tool/Vdtquestions';
import Shortstayvisa from './Components/Short stay visa page/Shortstayvisa'
import Temporarystayvisa from './Components/Temporarystayvisa';
import Residencyvisa from './Components/Residencyvisa';
import Article1 from './Components/Article1';
import Article2 from './Components/Article2';
import Article3 from './Components/Article3';
import Article4 from './Components/Article4';
import Article5 from './Components/Article5';
import Goldenvisa from './Components/Golden visa/Goldenvisa';
import Knowledgecenter from './Components/Knowledgecenter';
import AboutUs from './Components/AboutUs'
import Knowyourrights from './Components/Knowyourrights';
import Workvisa from './Components/Work visa/Workvisa';
import Techvisa from './Components/Tech visa/Techvisa';
import Startupvisa from './Components/Startup visa/Startupvisa';
import ContactUs from './Components/ContactUs';
import Legalpage from './Components/Legalpage';
import Services1 from './Components/Services/Services1'
import Services2 from './Components/Services/Services2'
import Services4 from './Components/Services/Services4'
import Vdt4 from './Components/Visa Discovery Tool/Vdt4';
import CookiePopup from './Components/Common Components/CookiePopup'
import ScrollToTop from './Components/ScrollToTop'
import Services from './Components/Services/Services'
import Services3 from './Components/Services/Services3';
import Greenvisa from './Components/Green visa/Greenvisa';
import CostTool from './Components/Visa cost tool/CostTool';
import Vdtlanding from './Components/Visa Discovery Tool/Vdtlanding';
import IRRToolMain from './Components/IRR tool/IRRToolMain';
import GoogleTranslate from './Components/Common Components/GoogleTranslate';
import Topscrollbtn from './Components/Common Components/Topscrollbtn';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsOfService from './Components/TermsofService';
import News from './Components/News';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

let theme1 = createTheme({
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    h1: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 700,
      fontSize: '4rem',
      letterSpacing: '0px',
      // lineHeight: '1.9',
      color: '#2F2FA2'
    },
    subtitle1: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      // fontSize: '36px',
      letterSpacing: '0px',
      lineHeight: '1.4',
      color: '#111111'
    },
    body1: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 700,
      // fontSize: '24px',
      letterSpacing: '0px',
      // lineHeight: '1.3',
      color: '#111111'
    },
    body2: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: '1rem',
      letterSpacing: '0px',
      // lineHeight: '1.46',
      color: '#111111'
    },
    h4: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 700,
      // fontSize: '52px',
      letterSpacing: '0px',
      // lineHeight: '1.25',
      color: '#2F2FA2'
    },
    h5: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 700,
      // fontSize: '42px',
      letterSpacing: '0px',
      // lineHeight: '1.24',
      color: '#111111'
    },
    h6: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 700,
      // fontSize: '36px',
      letterSpacing: '0px',
      // lineHeight: '1.25',
    },
    caption: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: '0.8rem',
      letterSpacing: '0px',
      // lineHeight: '1.37',
      color: '#111111'
    },
    overline: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontWeight: 400,
      fontSize: '0.8rem',
      // letterSpacing: '70px',
      // lineHeight: '1.37',
      color: '#111111',
      textTransform: 'uppercase'
    }
  }
})

theme1 = responsiveFontSizes(theme1);

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme1}>
        <CssBaseline />
        <CookiePopup />
        <ScrollToTop />
        <GoogleTranslate/>
        <Topscrollbtn/>
        <Route render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={300}
              classNames="fade"
            >
              <Switch location={location}>
                <Route exact path="/" component={Homepage} />
                <Route path="/visa-discovery-tool" component={Vdtlanding} />
                <Route path="/visa-discovery-questions" component={Vdtquestions} />
                <Route path="/portugal-short-stay-visa" component={Shortstayvisa} />
                <Route path="/portugal-temporary-stay-visa" component={Temporarystayvisa} />
                <Route path="/portugal-residency-visa" component={Residencyvisa} />
                <Route path="/golden-visa-to-portugal" component={Goldenvisa} />
                <Route path="/portugal-ranks-1st-for-remote-work" component={Article1} />
                <Route path="/portugal-ranks-4th-for-best-place-to-retire" component={Article2} />
                <Route path="/agreement-for-recruitment-of-indian-worker-signed-between-india-portugal" component={Article3} />
                <Route path="/digital-nomad-visa-for-remote-workers" component={Article4} />
                <Route path="/portugal-s-much-anticipated-digital-nomad-visa-is-now-available" component={Article5} />
                <Route path="/knowledge-center" component={Knowledgecenter} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/know-your-rights" component={Knowyourrights} />
                <Route path="/work-visa-in-portugal" component={Workvisa} />
                <Route path="/portugal-tech-visa" component={Techvisa} />
                <Route path="/portugal-startup-visa" component={Startupvisa} />
                <Route path="/contact-us" component={ContactUs} />
                <Route path="/legal-page" component={Legalpage} />
                <Route path="/services" component={Services} />
                <Route path="/services1" component={Services1} />
                <Route path="/services2" component={Services2} />
                <Route path="/services3" component={Services3} />
                <Route path="/services4" component={Services4} />
                <Route path="/vdt4" component={Vdt4} />
                <Route path="/portugal-green-visa" component={Greenvisa} />
                <Route path="/golden-visa-cost-tool" component={CostTool} />
                <Route path="/irr-tool" component={IRRToolMain} />
                <Route path="/privacy-policy" component={PrivacyPolicy}/>
                <Route path="/terms-and-condition" component={TermsOfService}/>
                <Route path="/news" component={News}/>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
      </ThemeProvider>
    </Router>
  );
}

export default App;
