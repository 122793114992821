import { useEffect } from 'react';
import { RealEstatebase, REFinalFundValue, CapitalGainsCalculator, RECostsArray, RECashflowArray, NetGrowthRE } from './RealEstateFunctions';
import { IRR } from './IRRcalculator'

function useRealEstateFundCalculator(initailRealEstateFund, numofApplicant, 
    numofFamilymembers, Exityears, REGrowthRate, RERentalYield, setREfundIRR, setREfundnetGrowthArr){

        useEffect(()=>{
            let initialCost=RealEstatebase(initailRealEstateFund, numofApplicant,numofFamilymembers)
            let finalAmt=REFinalFundValue(Exityears, initailRealEstateFund, REGrowthRate);
            let capitalGains=CapitalGainsCalculator(finalAmt, initailRealEstateFund)
            let costArr=RECostsArray(
                Exityears, 
                initailRealEstateFund,  
                numofApplicant,
                numofFamilymembers,
                capitalGains
                )
            let cashflowArr=RECashflowArray(costArr, finalAmt, initialCost, initailRealEstateFund, RERentalYield) 
            let REFundIRR = IRR(cashflowArr)
            REFundIRR = parseFloat(Math.round(REFundIRR * 10000) / 100).toFixed(2);
            setREfundIRR(REFundIRR)
            let netGrowthRateArr=NetGrowthRE(costArr, initialCost, initailRealEstateFund, Exityears, RERentalYield, REGrowthRate);
            setREfundnetGrowthArr(netGrowthRateArr);
        }, [initailRealEstateFund, numofApplicant, numofFamilymembers, Exityears, REGrowthRate, RERentalYield])

}

export default useRealEstateFundCalculator