import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
function Cards({ caption, svg, title }) {
    const history = useHistory();
    const handleClick=()=>{
        if(title.trim()=="View Fee Calculator"){
            history.push('/golden-visa-cost-tool')
        }
    }
    return (
        <>
            <Card onClick={handleClick}
                style={{ borderRadius: "1.3rem", boxShadow: "4px 4px 20px #00000029", minWidth: '20px', height: '100%' }}
                sx={{ minHeight: { xs: '13rem', md: '10rem' } }}>
                <CardActionArea style={{ height: '100%' }}>
                    <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ minHeight: { xs: '5rem', md: '0' } }} display="flex" alignItems="center" justifyContent="space-between">
                            <Typography padding="1rem 0" variant="caption" fontWeight="400">{caption}</Typography>
                            <Box >
                                <img src={svg} alt="arrow" width="70%" height="50%"></img>
                            </Box>
                        </Box>
                        <Box margin="0rem 0" sx={{ minHeight: { xs: '5rem', md: '0' }, display: { xs: 'flex', md: 'block' }, alignItems: { xs: 'center', md: 'none' }, mt: 'auto' }}>
                            <Typography variant="body1" sx={{ fontSize: { xs: '1.3rem', md: "1.6rem !important" } }} fontWeight="400 !important">{title}</Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    )
}

export default Cards
