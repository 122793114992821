import React, { useState } from 'react';
import ReactApexChart from "react-apexcharts";

function LineChart({Exityears=0, REfundnetGrowthArr, VCfundnetGrowthArr}) {
            
    let VCfundArr=[100, ...VCfundnetGrowthArr];
    let REfundArr=[100, ...REfundnetGrowthArr];
    const chartSeries = [{
        name: "VC Fund",
        data: VCfundArr || new Array(Exityears).fill(0)
    }, {
        name: "Real Estate",
        data: REfundArr || new Array(Exityears).fill(0)
    }]

    const chartOptions = {
        chart: {
            height: 400,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        colors: ['#AC5555', '#0843B2'],
        stroke:{
            width: 2,
            show: true,
            curve: 'straight'
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            borderColor: '#A6CEE3',
            xaxis: {
                lines: {
                    show: true
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            }, 
        },
        xaxis: {
            categories: Array.from(new Array(Exityears + 1), (ele, idx)=>idx?'Year '+idx:'Set-Up'),
            tooltip:{
                enabled: false
            }
        },
        legend:{
            show: true,
            horizontalAlign: 'left',
            fontSize: '16px',
            offsetX: 10,
            offsetY: 5,
            itemMargin:{
                horizontal: 10
            },
            markers:{
                width: 18,
                height: 18,
                radius: 3,
                offsetX: -2,
                offsetY: 2
            }
        },
        tooltip:{
            y: {
                formatter: (value)=> value + '%',
                title: {
                    formatter: (seriesName) => seriesName,
                },
            }
        }
    }
    return <>
        <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={400} />
    </>;
}

export default LineChart;
