import * as React from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/system";

export default function Numericinput({numVal, setNumval, min}) {
  const [error, setError]=React.useState(false);
  return (
    <>
    <Box
      width="240px"
      height="50px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      textAlign='center'
      style={{ boxShadow: "4px 4px 20px #00000029", borderRadius: "10px" }}
    >
      <IconButton
        sx={{ borderRadius: "10px", backgroundColor: "#F0F0FF" }}
        onClick={() => {
          if(numVal-100000<min) {
            setError(true);
            return;
          }else{
            setError(false)
            setNumval(prevState=>prevState-100000)
          }
        }}
      >
        <RemoveIcon
          style={{ fill: "#2F2FA2", height: "100%", fontSize: "34px" }}
        />
      </IconButton>
      <Box style={{textAlign: 'center'}}>
          <Typography variant="body2">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(numVal)}</Typography>
      </Box>
      <IconButton
        sx={{ borderRadius: "10px", backgroundColor: "#F0F0FF" }}
        onClick={() =>{
          setError(false);
          setNumval(prevState=>Number(prevState)+100000)
        }}
      >
        <AddIcon
          style={{ fill: "#2F2FA2", height: "100%", fontSize: "34px" }}
        />
      </IconButton>
    </Box>
    <Typography variant='body2' display={error? 'block':'none'}  color='red' >Min. investment amout is {new Intl.NumberFormat('de-DE').format(min)}</Typography>
    </>
  );
}
