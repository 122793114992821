import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

export default function CostTableData({numOfDependents=0, ansQ1, currencyMultiplier=1, ansQ2}) {
    const SEF_COST_PER_APPLICANT=537.2; 
    const SEF_COST_PER_DEPENDENT=83.1; 
    const ISSUE_NEW_PERMIT_COSTEACH=5274.4;
    const RENEWAL_RESIDENCE_PERMIT_APPLICANT=5274.4; //Issue of the new residence permit Card
    const RENEWAL_RESIDENCE_PERMIT_DEPENDENT=2668.2; //Renewal residence permit Card
    const [costObj, setcostObj]=useState({
        fixedCost1: SEF_COST_PER_APPLICANT,
        fixedCost2: ISSUE_NEW_PERMIT_COSTEACH,
        fixedCost3: RENEWAL_RESIDENCE_PERMIT_APPLICANT,
        landregistrationCost: 0,
        saleAgrementCost: 0,
        municipalTax: 0,
        stampDuty: 0,
        surveyorFee: 0,
        lawyerFee: 0,
        companySetupCost: 0
    })
    

    const calculateTotal=()=>{
        let sum=0;
        for(let key in costObj){
            sum+=costObj[key];
        }
        return sum;
    }

    //to set table based on ans of 2st question
    useEffect(()=>{
        if(ansQ2){
            if(ansQ1==="Real Estate Investment (500k)"){
                if(ansQ2==="Building or urban fraction for commercial purposes, including land for construction"){
                    setcostObj({...costObj,
                        municipalTax: 32500,
                        stampDuty: 4000,
                        surveyorFee: 350,
                        lawyerFee: 3000,
                        companySetupCost: 0 ,  
                    })
                }else if(ansQ2==="Building or urban fraction for housing purposes"){
                    setcostObj({...costObj,
                        municipalTax: 30000,
                        stampDuty: 4000,
                        surveyorFee: 350,
                        lawyerFee: 3000,
                        companySetupCost: 0 ,  
                    })
                }else if(ansQ2==="Rustic Building"){
                    setcostObj({...costObj,
                        municipalTax: 25000,
                        stampDuty: 4000,
                        surveyorFee: 350,
                        lawyerFee: 3000,
                        companySetupCost: 0 ,  
                    })
                }
            }else if(ansQ1==="Real Estate Investment (350k)"){
                if(ansQ2==="Building or urban fraction for commercial purposes, including land for construction"){
                    setcostObj({...costObj,
                        municipalTax: 22750,
                        stampDuty: 4000,
                        surveyorFee: 350,
                        lawyerFee: 3000,
                        companySetupCost: 0 ,  
                    })
                }else if(ansQ2==="Building or urban fraction for housing purposes"){
                    setcostObj({...costObj,
                        municipalTax: 21000,
                        stampDuty: 4000,
                        surveyorFee: 350,
                        lawyerFee: 3000,
                        companySetupCost: 0 ,  
                    })
                }else if(ansQ2==="Rustic Building"){
                    setcostObj({...costObj,
                        municipalTax: 0,
                        stampDuty: 4000,
                        surveyorFee: 350,
                        lawyerFee: 3000,
                        companySetupCost: 0 ,  
                    })
                }
            }
        }
    },[ansQ2])

    useEffect(()=>{
            setcostObj({...costObj,
                fixedCost1: SEF_COST_PER_APPLICANT + (SEF_COST_PER_DEPENDENT*numOfDependents),  
                fixedCost2: ISSUE_NEW_PERMIT_COSTEACH * (numOfDependents + 1),
                fixedCost3: RENEWAL_RESIDENCE_PERMIT_APPLICANT + (RENEWAL_RESIDENCE_PERMIT_DEPENDENT*numOfDependents)
            })
    },[numOfDependents])
    
    //to set table based on ans of 1st question
    useEffect(()=>{
        if(ansQ1){
            if(ansQ1==="Real Estate Investment (500k)"||ansQ1==="Real Estate Investment (350k)"){
                console.log({numOfDependents});
                setcostObj({
                    ...costObj,
                    municipalTax: 0,
                    stampDuty: 0,
                    surveyorFee: 0,
                    lawyerFee: 0,
                    companySetupCost: 0 ,  
                    landregistrationCost: 350,
                    saleAgrementCost: 650
                })
            }else if(ansQ1==="Start a Company in Portugal"){
                setcostObj({...costObj,
                    municipalTax: 0,
                    stampDuty: 0,
                    surveyorFee: 0,
                    lawyerFee: 0, 
                    landregistrationCost: 0,
                    saleAgrementCost: 0,
                    companySetupCost: 2500
                })
            }else if(ansQ1==="Capital Investment (1.5M)"){
                setcostObj({
                    ...costObj,
                    municipalTax: 0,
                    stampDuty: 0,
                    surveyorFee: 0,
                    landregistrationCost: 0,
                    saleAgrementCost: 0,
                    companySetupCost: 0,
                    lawyerFee: 3500
                })
            }else if(ansQ1==="Invest in a Qualified Fund (500k)"){
                setcostObj({
                    ...costObj,
                    municipalTax: 0,
                    stampDuty: 0,
                    surveyorFee: 0,
                    landregistrationCost: 0,
                    saleAgrementCost: 0,
                    companySetupCost: 0,
                    lawyerFee: 3500
                })
            }else if(ansQ1==="R&D Investment (350k)"){
                setcostObj({
                    ...costObj,
                    municipalTax: 0,
                    stampDuty: 0,
                    surveyorFee: 0,
                    landregistrationCost: 0,
                    saleAgrementCost: 0,
                    companySetupCost: 0,
                    lawyerFee: 3500
                })
            }else if(ansQ1==="Art, Heritage & Cultural Investment (250k)"){
                setcostObj({
                    ...costObj,
                    municipalTax: 0,
                    stampDuty: 0,
                    surveyorFee: 0,
                    landregistrationCost: 0,
                    saleAgrementCost: 0,
                    companySetupCost: 0,
                    lawyerFee: 3500
                })
            }         
        }else{
            setcostObj({
                fixedCost1: SEF_COST_PER_APPLICANT,
                fixedCost2: ISSUE_NEW_PERMIT_COSTEACH,
                fixedCost3: RENEWAL_RESIDENCE_PERMIT_APPLICANT,
                landregistrationCost: 0,
                saleAgrementCost: 0,
                municipalTax: 0,
                stampDuty: 0,
                surveyorFee: 0,
                lawyerFee: 0,
                companySetupCost: 0
            })
        }
    },[ansQ1])

    const convertToEuroNum=number=>{
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }
    const convertToINRNum=number=>{
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(number);
    }

    const currencyFormatter=(number, currencyMultiplier)=>{
        number=ansQ1?number:0;
        if(currencyMultiplier===1){
            return convertToEuroNum(number);
        }else{
            return convertToINRNum(number*currencyMultiplier);
        }
    }
    
    const TableRows = [
        {
            option: 'SEF Process Analysis',
            value: currencyFormatter(costObj.fixedCost1, currencyMultiplier)
        },
        {
            option: 'Issue of the new residence permit Card',
            value: currencyFormatter(costObj.fixedCost2, currencyMultiplier)
        },
        {
            option: 'Renewal residence permit Card',
            value: currencyFormatter(costObj.fixedCost3, currencyMultiplier)
        },
        {
            option: 'Registration of the acquisition of the property in the Registry of Land Registry (approx. value)',
            value: currencyFormatter(costObj.landregistrationCost, currencyMultiplier)
        },
        {
            option: 'Property Purchase and Sale Agreement – Private Document Certified by a Lawyer',
            value: currencyFormatter(costObj.saleAgrementCost, currencyMultiplier)
        },
        {
            option: 'Municipal Transfer Tax - IMT',
            value: currencyFormatter(costObj.municipalTax, currencyMultiplier)
        },
        {
            option: 'Stamp Duty',
            value: currencyFormatter(costObj.stampDuty, currencyMultiplier)
        },
        {
            option: 'Surveyor Fees',
            value: currencyFormatter(costObj.surveyorFee, currencyMultiplier)
        },
        {
            option: 'Lawyer Fees (approx value)',
            value: currencyFormatter(costObj.lawyerFee, currencyMultiplier)
        },
        {
            option: 'Company Set Up Costs',
            value: currencyFormatter(costObj.companySetupCost, currencyMultiplier)
        },    
    ]

    return (<>
        <TableContainer component={Paper} sx={{ marginTop: '1rem' }}>
            <Table size="medium" aria-label="a dense table">
                <TableBody>
                    {TableRows.map((row) => (
                        <TableRow
                            key={row.option}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.option}
                            </TableCell>
                            <TableCell align="right" sx={{paddingLeft: '0px', minWidth: '100px'}}>{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Card sx={{marginTop: '1rem'}}>
            <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant='body1' fontSize="1.5rem" fontWeight='400'>Total</Typography>
                    <Typography variant='body1' fontSize="1.5rem" fontWeight='700'>{currencyFormatter(calculateTotal(), currencyMultiplier)}</Typography>
                </Box>
            </CardContent>
        </Card>

    </>)
}
