import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import blog3img from "../Images/work-in-portugal.jpg";
import Grid from "@mui/material/Grid";
import Bookconsultation from "./Common Components/Bookconsultation";
import blog1img from "../Images/best-place-for-remote-work.jpg";
import blog2img from "../Images/best-place-to-retire.jpg";
import externallinkicon from "../Images/External Link - Blue.svg";
import articleicon from "../Images/Article Icon - Grey.svg";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";
import blog4img from "../Images/digital-nomad-visa-portugal.jpg";
import blog5img from "../Images/portugal-digital-nomad-visa-available.jpg";
import { Helmet } from "react-helmet";

function Article3() {
  useEffect(() => {
    document.title =
      "An Agreement is Signed on the Recruitment of Indian Workers";
  }, []);
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id":
                "https://visadiscovery.com/agreement-for-recruitment-of-indian-worker-signed-between-india-portugal",
            },
            headline:
              "An Agreement is Signed on the Recruitment of Indian Workers",
            description:
              "An agreement is signed between Portugal and India on the recruitment of Indian workers. Learn more about this bilateral agreement that allows Indian citizens to work in Portugal here.",
            image:
              "https://visadiscovery.com/static/media/work-in-portugal.ee74a5e9.jpg",
            author: {
              "@type": "Organization",
              name: "Visa Discovery",
              url: "https://visadiscovery.com",
            },
            publisher: {
              "@type": "Organization",
              name: "",
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            datePublished: "",
          })}
        </script>
      </Helmet>
      <Box width="100vw" maxWidth="100%" paddingTop="1rem">
        <Header />
        <Box
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          }}
          margin="auto"
        >
          <Box
            display="flex"
            width="100%"
            sx={{
              mt: { xs: "calc(12vh + 1rem)", md: "calc(12vh + 2rem)" },
              flexDirection: { xs: "column", md: "row" },
              mb: { xs: "2rem", md: "5rem" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "calc(100% - 14rem)" },
                paddingRight: { xs: "0", md: "3rem" },
              }}
            >
              <Typography gutterBottom variant="overline">
                News & Updates
              </Typography>
              <Typography gutterBottom variant="h4">
                An agreement on the recruitment of Indian workers is signed
                between Portugal and India
              </Typography>
              <img
                src={blog3img}
                className="blog-img"
                alt="recruitment of indian workers"
              ></img>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ paddingTop: { xs: ".5rem", md: "3rem" } }}
                style={{ textAlign: "justify" }}
              >
                India has been signing agreements with a number of countries
                lately, and now it seems they have their eyes set on Portugal.
                The two nations signed an accord that will allow Indian citizens
                to work in Portugal as well - opportunities for everyone!
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                This bilateral agreement lays out the processes for allowing
                Indian individuals to enter Portugal and work there under a
                contract of employment, facilitating safe and legal labour
                migration flows between the two nations. It will be applicable
                to hiring for any type of job activity and regardless of the
                duration of the employment contract, providing Portuguese law's
                protections for Indian workers.
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                Any time from the date of entry if an employer intends to hire
                an Indian worker after the Agreement enters into force, the
                Employer shall notify the Institute of Employment and Vocational
                Training (IEFP), which shall notify the Indian authorities. The
                Institute itself will conduct a hiring process, following which
                the employee will sign an employment contract and be issued a
                visa allowing them to travel to the country and work.
              </Typography>

              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                During the visit of the Indian Secretary of State for Foreign
                Affairs and Culture, this labour mobility agreement was signed,
                with Francisco André, the Portuguese Secretary of State for
                Foreign Affairs and Cooperation, signing on behalf of the
                Portuguese Party. The instrument has been ratified in 2021.
              </Typography>
              <Box
                mt="2rem"
                mb="1rem"
                paddingRight="1rem"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">Related Articles</Typography>
                {/* <Box style={{ float: 'right', cursor: 'pointer' }} sx={{
                                    width: '75px'
                                }} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" color="#2F2FA2 !important">View All</Typography>
                                    <img src={arrowsvgblue} alt="arrow" style={{ width: "1rem" }}></img>
                                </Box> */}
              </Box>
              <Grid
                container
                spacing={2}
                sx={{ overflow: "auto", flexWrap: "nowrap" }}
              >
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/portugal-ranks-1st-for-remote-work"
                    file={blog1img}
                    title=" Portugal Ranks 1st as the best country in the world for remote
                    work."
                    content={`Travel search engine Momondo has created a “travel work index” to
                    help digital nomads find the best countries for productive working
                    conditions and opportunities on vacation. Portugal ranks 1st out
                    of ten in this category, with its climate (low crime rate)
                    combined with affordable cost-of living factors making it an
                    attractive destination abroad even if you're not looking
                    specifically beyond scenery!`}
                    altText="best country for remote work"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/portugal-ranks-4th-for-best-place-to-retire"
                    file={blog2img}
                    altText="best country for retirement"
                    title="Portugal Ranks 4th best country in the world for retirement."
                    content={`Portugal ranks fourth on this list of the ten best countries for
                      retired folk. It's not hard to see why; with its warm climate,
                      beautiful scenery and rich culture it would be easy enough pack up
                      your bags once retirement hits and head off into south-west Europe
                      where you'll find yourself living happily ever after!`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/digital-nomad-visa-for-remote-workers"
                    file={blog4img}
                    altText="portugal digital nomad visa"
                    title="Portugal introduces a Digital Nomad Visa for remote workers."
                    content={`Are you a digital nomad looking to escape to warmer shores before winter locks down? Then, here is your chance! Portugal has announced a work visa for remote employees, which might allow digital nomads to reside and work there for up to 12 months.`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/portugal-s-much-anticipated-digital-nomad-visa-is-now-available"
                    file={blog5img}
                    altText="apply for portugal digital nomad visa"
                    title="Much-awaited Portugal's Digital Nomad Visa is now available!"
                    content={`Remote workers can now apply for Portugal's Digital Nomad Visa,
                                        which will allow them to live and work lawfully in Portugal,
                                        starting on October 30th, 2022. Candidates must make at least
                                        €2,800 per month, or four times Portugal's minimum wage, to be
                                        eligible for the program.`}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "14rem" },
                mt: { xs: "2rem", md: "0rem" },
              }}
            >
              <Bookconsultation />
              <Box mt="2rem">
                <Typography color="#2F2FA2 !important" variant="h5">
                  Portugal
                </Typography>
                <Box mt="1rem" width="16rem">
                  <Box className="side-section1" width="100%">
                    <Link
                      to={{
                        pathname:
                          "https://www.visitportugal.com/en/sobre-portugal/biportugal",
                      }}
                      target="_blank"
                    >
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h7"
                          fontWeight="700"
                          mr="1rem"
                          sx={{ color: "#111111" }}
                        >
                          About Portugal
                        </Typography>
                        <img src={externallinkicon} alt="arrow"></img>
                      </Box>
                    </Link>
                    <Typography variant="body2" gutterBottom>
                      Everything you need to know about Portugal.
                    </Typography>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body2"
                        color="#898989 !important"
                        whiteSpace="nowrap"
                        mr="8px"
                      >
                        2 min read
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Box display="flex" ml="8px">
                        <Typography variant="body2" color="#898989 !important">
                          Article
                        </Typography>
                        <img
                          src={articleicon}
                          alt="arrow"
                          style={{ marginLeft: ".5rem", width: "1rem" }}
                        ></img>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="side-section2" width="100%" mt="1rem">
                    <Link
                      to={{ pathname: "https://www.visitportugal.com/en" }}
                      target="_blank"
                      style={{ color: "#111111" }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="h7" fontWeight="700" mr="1rem">
                          Visit Portugal
                        </Typography>
                        <img src={externallinkicon} alt="arrow"></img>
                      </Box>
                    </Link>
                    <Typography variant="body2" gutterBottom>
                      Comprehensive information on everything required to get
                      around in Portugal
                    </Typography>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body2"
                        color="#898989 !important"
                        whiteSpace="nowrap"
                        mr="8px"
                      >
                        2 min read
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Box display="flex" ml="8px">
                        <Typography variant="body2" color="#898989 !important">
                          Article
                        </Typography>
                        <img
                          src={articleicon}
                          alt="arrow"
                          style={{ marginLeft: ".5rem", width: "1rem" }}
                        ></img>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="side-section3" width="100%" mt="1rem">
                    <Link
                      to={{
                        pathname:
                          "https://atlanticbridge.com.br/wp-content/uploads/2019/09/Complete-Guide-to-Real-Estate-Investment-in-Portugal-DS.pdf",
                      }}
                      target="_blank"
                      style={{ color: "#111111" }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="h7" fontWeight="700" mr="1rem">
                          Invest in Portugal
                        </Typography>
                        <img src={externallinkicon} alt="arrow"></img>
                      </Box>
                    </Link>

                    <Typography variant="body2" gutterBottom>
                      A guide to investing in Real Estate in Portugal
                    </Typography>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body2"
                        color="#898989 !important"
                        whiteSpace="nowrap"
                        mr="8px"
                      >
                        2 min read
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Box display="flex" ml="8px">
                        <Typography variant="body2" color="#898989 !important">
                          Article
                        </Typography>
                        <img
                          src={articleicon}
                          alt="arrow"
                          style={{ marginLeft: ".5rem", width: "1rem" }}
                        ></img>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default Article3;
