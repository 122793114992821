import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import rect35 from '../../Images/golden visa/Golden Visa - Investment Options.jpg'
import { TableRows } from './TableRowData'

function InvestmentOptions({ bgColor, refProp }) {
    return (
        <>
            <Box width="100%" backgroundColor={bgColor} ref={refProp}>
                <Box className="section3 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                    <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }} mt="2rem" >
                        <Box sx={{ width: { xs: '100%', md: '50%' }, margin: { xs: '0 auto 2rem', md: '0' } }}>
                            <img src={rect35} alt="golden visa for portugal" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '1.3rem', boxShadow: 'rgb(0 0 0 / 16%) 4px 4px 8px' }}></img>
                        </Box>
                        <Box sx={{ width: { xs: '100%', md: '50%' }, paddingLeft: { xs: '0', md: '4rem' } }} >
                            <Typography mb=".5rem" variant="h4" ml="0px important">Investment Options</Typography>
                            <TableContainer >
                                <Table size="small" aria-label="a dense table">
                                    {/* <TableHead>
                                        <TableRow>
                                            <TableCell>Dessert (100g serving)</TableCell>
                                            <TableCell align="right">Calories</TableCell>
                                            <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                            <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                        </TableRow>
                                    </TableHead> */}
                                    <TableBody>
                                        {TableRows.map((row) => (
                                            <TableRow
                                                key={row.option}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.option}
                                                </TableCell>
                                                <TableCell align="right">{row.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                                An investment of €280,000 investment in commercial or residential real estate.
                            </Typography>
                            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                                A capital investment of at least €350,000 to an investment/venture capital fund based in Portugal.
                             </Typography>
                            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                                A capital donation of at least €250,000 towards preserving Portuguese national heritage
                            </Typography>
                            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                                A capital transfer of €1,000,000 to a Portuguese bank.
                            </Typography> */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default InvestmentOptions
