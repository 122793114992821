import { useEffect } from 'react';
import * as IRRConstants from '../IRRconstants';
import { VCbase, VCFinalFundValue, VCCostsArray, VCCashflowArray, NetGrowthVC } from './VCFundFunctions'
import { IRR } from './IRRcalculator'
function useVCFundCalculator(initailVCFund, numofApplicant, numofFamilymembers, Exityears, VCFundCAGRpercent, setVCFundIRR, setVCfundnetGrowthArr) {

    const {
        SEF_COST_PER_APPLICANT,
        SEF_COST_PER_DEPENDENT,
        RENEWAL_RESIDENCE_PERMIT_APPLICANT,
        RENEWAL_RESIDENCE_PERMIT_DEPENDENT,
        VCFUND_TOTAL_INITIAL_FEE_PERCENT,
        VCFUND_TOTAL_OUTGOING_FEE_PERCENT,
        VCFUND_PERFORMANCE_FEE_PERCENT
    } = IRRConstants;

    useEffect(() => {
        let initialCost = VCbase(initailVCFund,
            numofApplicant,
            SEF_COST_PER_APPLICANT,
            numofFamilymembers,
            SEF_COST_PER_DEPENDENT,
            RENEWAL_RESIDENCE_PERMIT_APPLICANT,
            VCFUND_TOTAL_INITIAL_FEE_PERCENT)

        let finalAmt = VCFinalFundValue(Exityears, initailVCFund, VCFundCAGRpercent)

        let costArr = VCCostsArray(
            Exityears,
            initailVCFund,
            VCFUND_TOTAL_OUTGOING_FEE_PERCENT,
            numofApplicant,
            RENEWAL_RESIDENCE_PERMIT_APPLICANT,
            numofFamilymembers,
            RENEWAL_RESIDENCE_PERMIT_DEPENDENT,
            finalAmt,
            VCFUND_PERFORMANCE_FEE_PERCENT
        )

        let VCCashflowArr = VCCashflowArray(costArr, finalAmt, initialCost, initailVCFund)
        let VCfundnetGrowthArr = NetGrowthVC(costArr, initialCost, initailVCFund, Exityears, VCFundCAGRpercent);
        let VCFundIRR = IRR(VCCashflowArr)
        VCFundIRR = parseFloat(Math.round(VCFundIRR * 10000) / 100).toFixed(2);
        setVCFundIRR(VCFundIRR)
        setVCfundnetGrowthArr(VCfundnetGrowthArr)
    }, [initailVCFund, numofApplicant, numofFamilymembers, Exityears, VCFundCAGRpercent])
}

export default useVCFundCalculator;
