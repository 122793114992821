import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import arrowsvg from '../../Images/Icon feather-arrow-right-circle-2.svg';
import globeicon from '../../Images/Icon ionic-md-globe.svg'
import phoneicon from '../../Images/Icon feather-phone.svg'
import mailicon from '../../Images/Icon feather-mail.svg'

function Wheretoapply({ refProp, bgColor }) {

    //AT PRESENT THESE OPEN AND CLOSE / OPTIONS ARE COMMON FOR ALL 3 INPUTS
    const [openContinent, setOpenContinent] = useState(false);
    const [openCountry, setOpenCountry] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [continent, setContinent] = useState('')
    const [country, setCountry] = useState('')
    const [location, setLocation] = useState(null)
    const [CountryOptions, setCountryOptions] = useState([]);
    const [LocationOptions, setLocationOptions] = useState([]);
    const ContinentOptions = ["Africa", "America", "Asia", "Europe", "Ocenia"];
    // const loading = open && options.length === 0;
    const loading = false;

    /*
    value and set value needs to be used to set values of continent, country, location
     */
    useEffect(() => {
        if (continent) {
            let countryArr = []
            let countryInnerObj = countryObj[continent];
            //refer the structure of countryObj below
            for (let key in countryInnerObj) {
                countryArr.push(key)
            }
            console.log(`length of ${continent} is ${countryArr.length}`)
            countryArr.sort()
            setCountryOptions(countryArr)
        } else {
            setCountryOptions([])
        }
    }, [continent])

    useEffect(() => {
        if (country) {
            let locationArr = countryObj[continent][country]
            setLocationOptions(locationArr)
        } else {
            setLocationOptions([])
        }
    }, [country])

    return (
        <Box width="100vw" maxWidth="100%" backgroundColor={bgColor ? bgColor : "#F5F5F5"} ref={refProp}>
            <Box className="wheretosection wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', md: 'calc(100vw - 12rem)' }, padding: { xs: '2rem 0 11rem', md: '2rem 0 10rem' } }} margin="auto" >
                <Box textAlign="center" margin="0 auto">
                    <Typography variant="h4">Where to Apply?</Typography>
                </Box>
                {/* <Box mt="1.5rem">
                    <Typography variant="body2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non viverra metus. Pellentesque eget rutrum augue. Fusce facilisis laoreet porta. Vivamus cursus volutpat finibus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec et mollis sapien, a eleifend nulla. Suspendisse commodo lectus orci, a gravida odio dictum quis. Morbi fringilla auctor neque ac consequat. Phasellus eget arcu interdum arcu dictum rutrum. Aliquam bibendum massa quis massa feugiat porta. Vestibulum vel dui nisi. Ut pharetra at ante sollicitudin sollicitudin. Integer efficitur ex varius velit eleifend aliquam. Sed risus augue, scelerisque et dignissim ut, tincidunt et magna. Phasellus tempor purus diam.</Typography>
                </Box> */}
                <Box mt="1rem">
                    <Typography gutterBottom variant="overline">SELECT CONTINENT</Typography>
                    <Autocomplete
                        id="continent"
                        sx={{ width: '100%', mb: ".8rem", backgroundColor: 'white' }}
                        value={continent}
                        onChange={(event, newValue) => {
                            setContinent(newValue);
                        }}
                        open={openContinent}
                        onOpen={() => {
                            setOpenContinent(true);
                        }}
                        onClose={() => {
                            setOpenContinent(false);
                        }}
                        // getOptionLabel={(option) => option.title}
                        options={ContinentOptions}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Type to search"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Typography gutterBottom variant="overline">SELECT COUNTRY</Typography>
                    <Autocomplete
                        id="country"
                        sx={{ width: '100%', mb: ".8rem", backgroundColor: 'white' }}
                        noOptionsText="Please Select Continent First"
                        value={country}
                        onChange={(event, newValue) => setCountry(newValue)}
                        open={openCountry}
                        onOpen={() => {
                            setOpenCountry(true);
                        }}
                        onClose={() => {
                            setOpenCountry(false);
                        }}
                        options={CountryOptions}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Type to search"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Typography gutterBottom variant="overline">SELECT LOCATION</Typography>
                    <Autocomplete
                        id="location"
                        sx={{ width: '100%', mb: ".8rem", backgroundColor: 'white' }}
                        noOptionsText="Please Select Country First"
                        value={location}
                        onChange={(event, newValue) => { setLocation(newValue); console.table(newValue) }}
                        open={openLocation}
                        onOpen={() => {
                            setOpenLocation(true);
                        }}
                        onClose={() => {
                            setOpenLocation(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={LocationOptions}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Type to search"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
                <Box width="100%" display={location ? 'block' : 'none'}>
                    <Typography gutterBottom variant="overline">WHERE TO APPLY</Typography>
                    <Box width="100%" padding="1.2rem 1rem"
                        backgroundColor="#2F2FA2" borderRadius="12px"
                        display="flex" justifyContent="space-between"
                        sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { xs: 'center', md: 'normal' }
                        }}>
                        <Box sx={{ height: { xs: '', md: '100%' }, maxWidth: '100%' }} >
                            <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                <Typography variant="subtitle1" color="white">{location?.name ? location?.name : "-- Select above options --"}</Typography>
                            </Box>
                            <Box width="100%" display="flex" mt=".5rem"
                                sx={{ justifyContent: { xs: 'center', md: 'normal' }, rowGap: '12px' }} flexWrap="wrap">
                                <Box display={location?.website ? 'flex' : 'none'} mr="1rem" alignItems="center" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                    <img src={globeicon} alt='arrow' style={{ width: "1.2rem", marginRight: ".5rem" }}></img>
                                    <a href={location?.website ? location.website : '#'} target="_blank">
                                        <Typography variant="body2" color="white" sx={{wordBreak: 'break-word'}}>{location?.website ? location?.website : "NA"}</Typography>
                                    </a>
                                </Box>
                                <Box display={location?.contact ? 'flex' : 'none'} mr="1rem" alignItems="center" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                    <img src={phoneicon} alt='arrow' style={{ width: "1.2rem", marginRight: ".5rem" }}></img>
                                    {
                                        location?.contact.split(', ').map((number, index, array) => (
                                            <React.Fragment key={number}>
                                                <a href={`tel: ${number}`} >
                                                    <Typography variant="body2" color="white" sx={{wordBreak: 'break-word'}}>{index === array.length - 1 ? `${number}` : number + ", "}</Typography>
                                                </a>
                                                &nbsp;
                                            </React.Fragment>
                                        ))

                                    }
                                </Box>
                                <Box display={location?.email ? 'flex' : 'none'} mr="1rem" alignItems="center" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                    <img src={mailicon} alt='arrow' style={{ width: "1.2rem", marginRight: ".5rem" }}></img>
                                    {
                                        location?.email.split(', ').map((email, index, array) => (
                                            <React.Fragment key={email}>
                                                <a href={`mailto: ${email}`}>
                                                    <Typography variant="body2" color="white" sx={{wordBreak: 'break-word'}}>{index === array.length - 1 ? email : email + ', '}</Typography>
                                                </a>
                                                &nbsp;
                                            </React.Fragment>

                                        ))
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <a href={location?.website ? location.website : '#'} target="_blank" style={{ display: location?.website ? 'flex' : 'none' }}>
                            <Box display="flex" height="1.5rem" width="8rem" justifyContent="space-evenly"
                                sx={{ mt: { xs: '.5rem', md: '0' }, gap: { xs: '12px', md: '16px' } }}>
                                <Typography variant="body2" color="white">Visit Website</Typography>
                                <img src={arrowsvg} alt='arrow' style={{ width: "1.2rem" }}></img>
                            </Box>
                        </a>
                    </Box>
                </Box>
            </Box>
        </Box>

    )

}


const countryObj = {
    'Africa': {
        'Tunisia': [
            {
                'name': "Consular Section of the Embassy of Portugal in Tunis",
                'email': "sconsular.tunes@mne.pt",
                'contact': "+(216) 71 893 981, +(216) 71 788 189 ",
                'website': "https://luanda.consuladoportugal.mne.gov.pt/"
            }
        ],
        'Senegal': [
            {
                'name': "Consular Section of the Portuguese Embassy in Dakar",
                'email': "dakar@mne.pt",
                'contact': "+(221) 338 592 660, + (221) 338 606 006",
                'website': "https://dakar.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Sao Tome and Principe': [
            {
                'name': "Consular Section of the Embassy of Portugal in São Tomé",
                'email': "sconsular.stome@mne.pt",
                'contact': "+(239) 222 41 51",
                'website': "https://saotome.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Democratic Republic of Congo': [
            {
                'name': "Consular Section of the Embassy of Portugal in Kinshasa",
                'email': "sconsular.kinshasa@mne.pt",
                'contact': "+(243) 811 922 520",
                'website': "https://kinshasa.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Kenya': [
            {
                'name': "Consular Section of the Embassy of Portugal in Nairobi",
                'email': "nairobi@mne.pt",
                'contact': "+(254) 20 7651 103 , +(254) 70 76 96 482, +(254) 71 58 83 361",
                'website': "https://nairobi.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Nigeria': [
            {
                'name': "Consular Section of the Embassy of Portugal in Abuja",
                'email': "abuja@mne.pt",
                'contact': "+234 (0) 9037808670, +234 (0) 7031170761, +234 (0) 8064091985",
                'website': ""
            }
        ],
        'Namibia': [
            {
                'name': "Consular Section of the Portuguese Embassy in Windhoek",
                'email': "windhoek@mne.pt, sconsular.windhoek@mne.pt",
                'contact': "+(264) 61 259791, +(264) 81 7156019",
                'website': "https://windhoek.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Morocco': [
            {
                'name': "Consular Section of the Portuguese Embassy in Rabat",
                'email': "sconsular.rabat@mne.pt",
                'contact': "+(212) 537 756 446 / 47 / 49 / 50",
                'website': "https://rabat.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Libya': [
            {
                'name': "Consular Section of the Embassy of Portugal in Tripoli",
                'email': "",
                'contact': "",
                'website': ""
            }
        ],
        'Guinea Bissau': [
            {
                'name': "Consular Section of the Portuguese Embassy in Bissau",
                'email': "sconsular.bissau@mne.pt, bissau@mne.pt",
                'contact': "+(245) 96 698 00 00, +(245) 96 699 00 00",
                'website': "https://bissau.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Equatorial Guinea': [
            {
                'name': "Consular Section of the Portuguese Embassy in Malabo",
                'email': "malabo@mne.pt",
                'contact': "+ (240) 222 085 676",
                'website': ""
            }
        ],
        'Ethiopia': [
            {
                'name': "Consular Section of the Embassy of Portugal in Addis Ababa",
                'email': "embportaddis@gmail.com",
                'contact': "+(251) 11 557 5456,+(251) 11 557 4764,+(251) 11 557 5806",
                'website': "https://adisabeba.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Angola': [
            {
                'name': "Consulate General of Portugal in Luanda",
                'email': "consulado.luanda@mne.pt",
                'contact': "+(244) 923 165 400",
                'website': "https://luanda.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Benguela",
                'email': "consulado.benguela.atendimento@gmail.com, consulado.benguela@mne.pt",
                'contact': "+(244) 272 232 462",
                'website': "https://benguela.consuladoportugal.mne.gov.pt/"
            }
        ],
        'South Africa': [
            {
                'name': "Consulate General of Portugal in Cape Town",
                'email': "consulado.cabo@mne.pt",
                'contact': "+(27 21) 4180080/1, +(27) 603174285",
                'website': ""
            },
            {
                'name': "Consulate General of Portugal in Johannesburg",
                'email': "consulado.joanesburgo@mne.pt",
                'contact': "+(27) 78 344 6495, +(27) 11 622 0645",
                'website': "http://www.cgj.org.za/"
            },
            {
                'name': "Consular Section of the Embassy of Portugal in Pretoria",
                'email': "sconsular.pretoria@mne.pt",
                'contact': "+(27) 123464285, +(27) 824625032",
                'website': "http://www.embaixadaportugal.org.za/"
            }
        ],
        'Zimbabwe': [
            {
                'name': "Consular Section of the Embassy of Portugal in Harare",
                'email': "harare@mne.pt, sconsular.harare@mne.pt",
                'contact': "+ (263) 772 318 441",
                'website': ""
            }
        ],
        'Egypt': [
            {
                'name': "Consular Section of the Embassy of Portugal in Cairo",
                'email': "sconsular.cairo@mne.pt , cairo@mne.pt",
                'contact': "+(20) 1032231181, +(20) 2273 50779/81",
                'website': "https://cairo.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Cape Green': [
            {
                'name': "Consular Section of the Portuguese Embassy in Praia",
                'email': "sconsular.praia@mne.pt",
                'contact': ",+(238) 26 23 036, +(238) 26 26 097",
                'website': "https://praia.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Consular Office of Portugal in Mindelo",
                'email': "ec.mindelo@mne.pt",
                'contact': "+(238) 232 31 30",
                'website': ""
            }
        ],
        'Algeria': [
            {
                'name': "Consular Section of the Embassy of Portugal in Algiers",
                'email': "secretariado.argel@mne.pt, nsconsular.argel@mne.pt",
                'contact': ",+ 213 (0) 23054143",
                'website': "https://argel.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Mozambique': [
            {
                'name': "Consulate General of Portugal in Maputo",
                'email': "consulado.maputo@mne.pt",
                'contact': "+(258) 843 987 647, (258) 823 166 540, +(258) 21 490 150/51/55/57",
                'website': "https://maputo.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Beira",
                'email': "consulado.beira@mne.pt",
                'contact': "+(258) 233 260 66/76, +(258) 845944833, +(258) 233 222 96",
                'website': "https://beira.consuladoportugal.mne.gov.pt"
            }
        ],
    },
    'America': {
        'Brazil': [
            {
                'name': "Consular Office of Portugal in Santos",
                'email': "santos@mne.pt",
                'contact': "+(55 13) 32 23 66 74, +(55 13) 32 23 10 68",
                'website': ""
            },
            {
                'name': "Consular Section of the Embassy of Portugal in Brasilia",
                'email': "brasilia@mne.pt",
                'contact': "+(55) 61 30 32 96 00, +(55) 61 99823 4983",
                'website': "https://brasilia.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Portuguese Consulate in Belo Horizonte",
                'email': "consulado.belohorizonte@mne.pt",
                'contact': "",
                'website': "https://www.consuladoportugalbh.org.br/"
            },
            {
                'name': "Consulate General of Portugal in Sao Paulo",
                'email': "consulado.saopaulo@mne.pt, eventos.saopaulo@mne.pt, doubts.saopaulo@mne.pt, supportsocial.saopaulo@mne.pt, finaceiro.saopaulo@mne.pt, cabinetcg.saopaulo@mne.pt",
                'contact': "+(55 11) 308 41 800",
                'website': "http://www.consuladoportugalsp.org.br/"
            },
            {
                'name': "Consulate General of Portugal in Salvador da Bahia",
                'email': "consulado.bahia@mne.pt",
                'contact': "+(55 71) 986 437 128 |+351 961 706 472 | +351 21 792 97 14, +(55 71) 33 41 06 36",
                'website': "https://salvadordabaia.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Rio de Janeiro",
                'email': "riojaneiro@mne.pt",
                'contact': "+(55 21) 3861 6450 / + (55 21) 3509 1850",
                'website': "https://riodejaneiro.consuladoportugal.mne.gov.pt/"
            }
        ],
        'Uruguay': [
            {
                'name': "Consular Section of the Embassy of Portugal in Montevideo",
                'email': "montevideo@mne.pt",
                'contact': "+(598) 27084061",
                'website': "https://montevideo.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Peru': [
            {
                'name': "Consular Section of the Embassy of Portugal in Lima",
                'email': "limaportugal@hotmail.com, nlima@mne.pt",
                'contact': "+ (51) 1 6287164 / 5 / 6",
                'website': "https://lima.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Panama': [
            {
                'name': "Consular Section of the Embassy of Portugal in Panama City",
                'email': "sconsular.panama@mne.pt, panama@mne.pt",
                'contact': "+(507) 387-6588/9, +(507) 6852 0056",
                'website': "https://panama.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Mexico': [
            {
                'name': "Consular Section of the Embassy of Portugal in Mexico City",
                'email': "mexico@mne.pt, sconsular.mexico@mne.pt",
                'contact': "+ (5255) 55207897",
                'website': "https://mexico.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'USA': [
            {
                'name': "Consular Section of the Embassy of Portugal in Washington DC",
                'email': "info.washington@mne.pt, sconsular.washington@mne.pt",
                'contact': "+(1) 202 332 3007, +(1) 202 257 4152",
                'website': "https://washingtondc.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Portuguese Consulate in New Bedford",
                'email': "newbedford@mne.pt , consulado.newbedford@mne.pt",
                'contact': "+(1) 508 993 57 41, +(1) 508 997 61 51",
                'website': "www.consulateportugalnewbedford.org"
            },
            {
                'name': "Consulate General of Portugal in San Francisco",
                'email': "sfrancisco@mne.pt",
                'contact': "+(1 415) 346 34 00/01",
                'website': "https://saofrancisco.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in New York",
                'email': "consulado.newyork@mne.pt",
                'contact': "+1 646 845 0042",
                'website': "https://novaiorque.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Newark",
                'email': "consulado.newark@mne.pt",
                'contact': "+(1 973) 643 21 58, +(1 973) 643 42 00",
                'website': "https://newark.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Boston",
                'email': "consulado.boston@mne.pt",
                'contact': "+(1 617) 536 87 40",
                'website': "https://boston.consuladoportugal.mne.gov.pt"
            }
        ],
        'Cuba': [
            {
                'name': "Consular Section of the Embassy of Portugal in Havana",
                'email': "sconsular.havana@mne.pt, havana@mne.pt",
                'contact': "(+537) 204-2871,+(537) 204 79 95, n(+53) 0 5280-4454",
                'website': "https://havana.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Colombia': [
            {
                'name': "Consular Section of the Embassy of Portugal in Bogotá",
                'email': "bogota@mne.pt, sconsular.bogota@mne.pt",
                'contact': "+ 57 (1) 3072990",
                'website': "https://bogota.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Chile': [
            {
                'name': "Consular Section of the Embassy of Portugal in Santiago de Chile",
                'email': "santiagochile@mne.pt, sconsular.santiago@mne.pt",
                'contact': "(+56 2) 22 03 05 42/3, (+56 2) 22 03 66 15",
                'website': "https://santiago.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Canada': [
            {
                'name': "Consular Section of the Embassy of Portugal in Ottawa",
                'email': "ottawa@mne.pt, sconsular.ottawa@mne.pt",
                'contact': "+(1) 613 7290883",
                'website': "https://otava.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Vancouver",
                'email': "consulado.vancouver@mne.pt",
                'contact': "+(1 604) 688 65 14",
                'website': "https://vancouver.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Montreal",
                'email': "consulado.montreal@mne.pt",
                'contact': "+(1 514) 499 03 59",
                'website': "https://montreal.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Toronto",
                'email': "consulado.toronto@mne.pt",
                'contact': "+(1 416) 217 ​​09 66",
                'website': "https://toronto.consuladoportugal.mne.gov.pt/"
            }
        ],
        'Argentina': [
            {
                'name': "Consular Section of the Embassy of Portugal in Buenos Aires",
                'email': "buenosaires@mne.pt",
                'contact': "+(54 11) 4313 7601",
                'website': "https://buenosaires.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Venezuela': [
            {
                'name': "Consulate General of Portugal in Valencia",
                'email': "valencia@mne.pt",
                'contact': "+(58 241) 823 87 89, +(58 241) 823 99 69, n+(58 241) 823 46 45",
                'website': "https://valencia.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Caracas",
                'email': "cgcaracas@mne.pt",
                'contact': "+(58 212) 267 23 82, +(58 212) 264 05 47, +(58 212) 267 89 89",
                'website': "https://caracas.consuladoportugal.mne.gov.pt/"
            }
        ]
    },
    'Asia': {
        'Thailand': [
            {
                'name': "Consular Section of the Embassy of Portugal in Bangkok",
                'email': "sconsular.bangkok@mne.pt, bangkok@mne.pt",
                'contact': "",
                'website': "https://banguecoque.embaixadaportugal.mne.gov.pt/pt/seccao-consular/informacao-geral"
            }
        ],
        'Singapore': [
            {
                'name': "Consular Section of the Embassy of Portugal in Singapore",
                'email': "sconsular.singapura@mne.pt, singapura@mne.pt",
                'contact': "(+65) 85717800, (+65) 6224 2256",
                'website': "https://singapura.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Qatar': [
            {
                'name': "Consular Section of the Embassy of Portugal in Doha",
                'email': "scdoha@mne.pt, doha@mne.pt, sconsular.doha@mne.ptn",
                'contact': "+(974) 4038 5670, +(974) 3363 9594",
                'website': "https://doha.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Pakistan': [
            {
                'name': "Consular Section of the Portuguese Embassy in Islamabad",
                'email': "sconsular.islamabad@mne.pt, islamabad@mne.pt",
                'contact': "+(92) 518447041/3,  +(92) 300 850 46 19",
                'website': "https://islamabad.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Japan': [
            {
                'name': "Consular Section of the Embassy of Portugal in Tokyo",
                'email': "sconsular.toquio@mne.pt",
                'contact': "+(81) (0) 352260614, +(81) (0) 352127322",
                'website': "https://toquio.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Israel': [
            {
                'name': "Consular Section of the Portuguese Embassy in Tel Aviv",
                'email': "telavive@mne.pt, sconsular.telavive@mne.pt",
                'contact': "+(972) 3 695 63 61/73, +(972) 3 695 63 72, +972 (0)54 545 11 72",
                'website': "https://telavive.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Iran': [
            {
                'name': "Consular Section of the Embassy of Portugal in Tehran",
                'email': "teerao@mne.pt",
                'contact': "+(98 21) 22543237, +(98 21) 22764060, +(98) 990 578 2617",
                'website': "https://teerao.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Indonesia': [
            {
                'name': "Consular Section of the Portuguese Embassy in Jakarta",
                'email': "sconsular.jakarta@mne.pt",
                'contact': "+(62) 21 319 08 030",
                'website': "https://jakarta.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'East Timor': [
            {
                'name': "Consular Section of the Embassy of Portugal in Dili",
                'email': "dili@mne.pt, atendimento.dili@mne.pt, sconsular.dili@mne.ptnn",
                'contact': "+(670) 331 00 50, (670) 331 02 18, +(670) 331 15 20",
                'website': "https://dili.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'India': [
            {
                'name': "Consular Section of the Portuguese Embassy in New Delhi",
                'email': "sconsular.novadeli@mne.pt, visas.novadeli@mne.pt",
                'contact': "+(91) 11 4607 1001",
                'website': "https://novadeli.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Goa",
                'email': "consulado.goa@mne.pt",
                'contact': "+(91) 832 6719255/56",
                'website': ""
            }
        ],
        'United Arab Emirates': [
            {
                'name': "Consular Section of the Embassy of Portugal in Abu Dhabi",
                'email': "abudhabi@mne.pt",
                'contact': "+(971) 265 05541",
                'website': "https://abudhabi.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'South Korea': [
            {
                'name': "Consular Section of the Portuguese Embassy in Seoul",
                'email': "Seul@mne.pt, sconsular.seul@mne.pt",
                'contact': "+(82) 2 36752251",
                'website': "https://seul.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'China': [
            {
                'name': "Consular Section of the Portuguese Embassy in Beijing",
                'email': "pequim@mne.pt, sconsular.pequim@mne.pt",
                'contact': "+(8610) 65 32 34 97",
                'website': "https://pequim.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Shanghai",
                'email': "Shanghai@mne.pt",
                'contact': "+(86) 216 288 67 67, +(86) 15921553994",
                'website': "https://xangai.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Macau",
                'email': "macau@mne.pt",
                'contact': "+(853) 2835 6660 / 61 / 62",
                'website': "http://www.cgportugal.org"
            },
            {
                'name': "Consulate General of Portugal in Guangzhou",
                'email': "cantao@mne.pt",
                'contact': "+86 020 87581621",
                'website': "https://cantao.consuladoportugal.mne.gov.pt/"
            }
        ],
        'Kazakhstan': [
            {
                'name': "Consular Section of the Embassy of Portugal in Nur-Sultan",
                'email': "",
                'contact': "+7 717 276 8302, +7 775 786 7682",
                'website': ""
            }
        ],
        'Saudi Arabia': [
            {
                'name': "Consular Section of the Portuguese Embassy in Riyadh",
                'email': "sconsular.riade@mne.pt, riade@mne.pt",
                'contact': "+(966)114826964",
                'website': "https://riade.embaixadaportugal.mne.gov.pt/"
            }
        ],
    },
    'Europe': {
        'Switzerland': [
            {
                'name': "Consular Office of Portugal in Sion",
                'email': "sion@mne.pt",
                'contact': "+(41) 27 323 15 11",
                'website': ""
            },
            {
                'name': "Portuguese Consular Office in Lugano",
                'email': "econsular.lugano@mne.pt",
                'contact': "+(41) 912 603 860",
                'website': ""
            },
            {
                'name': "Consular Section of the Portuguese Embassy in Bern",
                'email': "sconsular.berna@mne.pt",
                'contact': "+(41) 31 351 17 74, +(41) 31 351 17 73",
                'website': "https://berna.embaixadaportugal.mne.gov.pt/pt/"
            },
            {
                'name': "Consulate General of Portugal in Zurich",
                'email': "consulado.zurich@mne.pt; zurich@mne.pt",
                'contact': "+ (41 44) 200 30 44 /45, + (41 44) 200 30 40",
                'website': "http://www.consuladogeralportugalzurich.ch"
            },
            {
                'name': "Consulate General of Portugal in Geneva",
                'email': "consulado.genebra@mne.pt",
                'contact': "+(41) 22 791 76 36",
                'website': "https://genebra.consuladoportugal.mne.gov.pt/"
            }
        ],
        'France': [
            {
                'name': "Portuguese Consular Office in Nantes",
                'email': "",
                'contact': "+(33) (0)156338100",
                'website': ""
            },
            {
                'name': "Consulate General of Portugal in Paris",
                'email': "cgparis@mne.pt, passports.cgparis@mne.pt, consulado.paris@mne.pt",
                'contact': "+(33) (0) 695 835 711, +(33) (0) 156 338 100",
                'website': "www.consuladoportugalparis.org"
            },
            {
                'name': "Consulate General of Portugal in Marseille",
                'email': "consulado.marselha@mne.pt",
                'contact': "+(33 491) 29 95 30/31/32/34/35/36/37, +(33) (0) 618 72 27 90",
                'website': "http://consulportugalmars.jimdo.com/"
            },
            {
                'name': "Consulate General of Portugal in Lyon",
                'email': "consulado.lyon@mne.pt",
                'contact': "+(33) 680939929, +(33 478) 173 446, +(33 478) 173 440",
                'website': "https://lyon.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Strasbourg",
                'email': "consulado.estrasburgo@mne.pt",
                'contact': ",(+33) 388 456 040",
                'website': "https://estrasburgo.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Bordeaux",
                'email': "consulado.bordeus@mne.pt",
                'contact': "+ (33) 6 28 06 44 09, +(33) 5 56 00 68 20",
                'website': "https://bordeus.consuladoportugal.mne.gov.pt/"
            }
        ],
        'Germany': [
            {
                'name': "Consular Office of Portugal in Hattersheim am Main (Frankfurt)",
                'email': "frankfurt@mne.pt",
                'contact': "+(49) 61 909 753 490",
                'website': ""
            },
            {
                'name': "Consular Section of the Embassy of Portugal in Berlin",
                'email': "",
                'contact': "+(49) 30 2290011, +(49) 30 2291388",
                'website': "https://berlim.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Hamburg",
                'email': "consulado.hamburgo@mne.pt",
                'contact': "+(49) 40 355 348 50, +(49) 40 355 348 58",
                'website': ""
            },
            {
                'name': "Consulate General of Portugal in Stuttgart",
                'email': "Stuttgart@mne.pt",
                'contact': "+(49 711) 227 396",
                'website': "https://estugarda.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Dusseldorft",
                'email': "consulado.dusseldorf@mne.pt",
                'contact': "+(4 921 1) 138 780",
                'website': "https://dusseldorf.consuladoportugal.mne.gov.pt/"
            }
        ],
        'Ukraine': [
            {
                'name': "Consular Section of the Portuguese Embassy in Kiev",
                'email': "sconsular.kiev@mne.pt",
                'contact': "+(380) 679124180, +(380) 442875871 / 72, +(380) 442874787",
                'website': "https://kiev.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Turkey': [
            {
                'name': "Consular Section of the Portuguese Embassy in Ankara",
                'email': "ankara@mne.pt",
                'contact': "+(90) 312 405 60 28/29",
                'website': "https://ankara.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Sweden': [
            {
                'name': "Consular Section of the Embassy of Portugal in Stockholm",
                'email': "sconsular.estocolmo@mne.pt",
                'contact': "+46 (0) 8 545 670 67",
                'website': "https://estocolmo.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Serbia': [
            {
                'name': "Consular Section of the Embassy of Portugal in Belgrade",
                'email': "sconsular.belgrado@mne.pt",
                'contact': "+(381) 63262120, +(381) 112662895 / 112662897",
                'website': "https://belgrado.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Russia': [
            {
                'name': "Consular Section of the Portuguese Embassy in Moscow",
                'email': "sconsular.moscovo@mne.pt, moscovo@mne.pt",
                'contact': "+7 (495) 9813410, +(7495) 9813414, +7 965 348 13 28",
                'website': "https://moscovo.embaixadaportugal.mne.gov.pt/pt/seccao-consular/informacao-geral"
            }
        ],
        'Romania': [
            {
                'name': "Consular Section of the Embassy of Portugal in Bucharest",
                'email': "sconsular.bucareste@mne.pt, bucharest@mne.pt",
                'contact': "+(40) 21 230 41 18, +(40) 746 22 44 55",
                'website': "https://bucareste.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Czech Republic': [
            {
                'name': "Consular Section of the Portuguese Embassy in Prague",
                'email': "praga@mne.pt, praga.embassy@mne.pt",
                'contact': "+(420) 257311231, +(420) 257311230",
                'website': "https://praga.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Poland': [
            {
                'name': "Consular Section of the Embassy of Portugal in Warsaw",
                'email': "sconsular.varsovia@mne.pt, varsovia@mne.pt",
                'contact': "+(48 22) 511 10 11/10/12",
                'website': "http://www.ambasada-portugalii.pl/"
            }
        ],
        'Netherlands': [
            {
                'name': "Consular Section of the Portuguese Embassy in The Hague",
                'email': "haia@mne.pt , sconsular.haia@mne.pt",
                'contact': "+(31) 070 701 7100 ,  n+(31) (0)70 30 20 150",
                'website': "https://haia.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Norway': [
            {
                'name': "Consular Section of the Embassy of Portugal in Oslo",
                'email': "embassyportugal.oslo@mne.pt , sconsular.oslo@mne.pt",
                'contact': "+(47) 23332855, +(47) 23332857",
                'website': "https://oslo.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Italy': [
            {
                'name': "Consular Section of the Portuguese Embassy in Rome",
                'email': "sconsular.roma@mne.pt, secretariado.roma@mne.pt",
                'contact': "+(39) 0645238800, (+39) 06 844 801",
                'website': "https://roma.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Ireland': [
            {
                'name': "Consular Section of the Portuguese Embassy in Dublin",
                'email': "sconsular.dublin@mne.pt, dublin@mne.pt",
                'contact': "+(353) 15369581, +(353) 830536843, +(353) 1 669 9100",
                'website': "https://dublin.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Hungary': [
            {
                'name': "Consular Section of the Portuguese Embassy in Budapest",
                'email': "sconsular.budapeste@mne.pt, budapeste@mne.pt",
                'contact': "+(361) 201 7616/ 17/18, +36 30 601 9706",
                'website': "https://budapeste.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Greece': [
            {
                'name': "Consular Section of the Embassy of Portugal in Athens",
                'email': "embportg@otenet.gr, ptembath@otenet.gr, sconsular.atenas@mne.ptn",
                'contact': "+(30) 21072 90052, +(30) 21072 90061, +(30) 21072 90096",
                'website': "https://atenas.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Finland': [
            {
                'name': "Consular Section of the Portuguese Embassy in Helsinki",
                'email': "sconsular.helsinquia@mne.pt, helsinquia@mne.pt",
                'contact': "+(358) 96824370, +(358) 968243718",
                'website': "https://helsinquia.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Spain': [
            {
                'name': "Consular Section of the Embassy of Portugal in Madrid",
                'email': "sconsular.madrid@mne.pt , madrid@mne.pt",
                'contact': "+(34) 917 890 500, +(34) 646896254",
                'website': "https://madrid.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Seville",
                'email': "consulado.sevilha@mne.pt",
                'contact': "+(34 95) 423 11 52 , +(34) 917 890 500",
                'website': "https://www.consuladoportugalsevilha.org/"
            },
            {
                'name': "Consulate General of Portugal in Barcelona",
                'email': "consulado.barcelona@mne.pt",
                'contact': "+(34 93) 318 81 54, +(34) 609301101, +(34) 917 890 500 ",
                'website': ""
            }
        ],
        'Slovakia': [
            {
                'name': "Consular Section of the Portuguese Embassy in Bratislava",
                'email': "bratislava@mne.pt",
                'contact': "+(421) 220 768 454",
                'website': ""
            }
        ],
        'Denmark': [
            {
                'name': "Consular Section of the Portuguese Embassy in Copenhagen",
                'email': "copenhaga@mne.pt, sconsular.copenhaga@mne.pt",
                'contact': "+(45) 33 36 00 71, +(45) 33 13 13 01",
                'website': "https://copenhaga.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Croatia': [
            {
                'name': "Consular Section of the Portuguese Embassy in Zagreb",
                'email': "zagreb@mne.pt",
                'contact': "+(385) 1 488 22 10",
                'website': "https://zagreb.embaixadaportugal.mne.gov.pt/"
            },

        ],
        'Cyprus': [
            {
                'name': "Consular Section of the Embassy of Portugal in Nicosia",
                'email': "nicosia@mne.pt",
                'contact': "+(357) 97 734 085, +(357) 22 375 131",
                'website': ""
            }
        ],
        'Bulgaria': [
            {
                'name': "Consular Section of the Portuguese Embassy in Sofia",
                'email': "sofia@mne.pt",
                'contact': "+(359) 878237039, +(359) (2) 448 4107",
                'website': "https://sofia.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Belgium': [
            {
                'name': "Consular Section of the Embassy of Portugal in Brussels",
                'email': "sconsular.bruxes@mne.pt",
                'contact': "+(32) 228 643 70, +(32) 228 643 77 , +(32) 2 290 08 83",
                'website': "https://bruxes.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'Austria': [
            {
                'name': "Consular Section of the Embassy of Portugal in Vienna",
                'email': "sconsular.berlim@mne.pt, berlim@mne.pt",
                'contact': "+(431) 585 37 20",
                'website': "https://viena.embaixadaportugal.mne.gov.pt/"
            }
        ],
        'United Kingdom': [
            {
                'name': "Consulate General of Portugal in Manchester",
                'email': "consulado.manchester@mne.pt",
                'contact': "+(44) (0)161 236 09 90, +(44) (0) 20 343 336 24",
                'website': "https://manchester.consuladoportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in London",
                'email': "consulado.londres@mne.pt",
                'contact': "+(44) (0) 20 343 336 24, +(44) (0) 207 291 37 70",
                'website': "http://www.portugalemlondres.org/"
            }
        ],
        'Luxembourg': [
            {
                'name': "Consulate General of Portugal in Luxembourg",
                'email': "consulado.lux@mne.pt",
                'contact': "+(352) 27302813, +(352) 45 33 47 1",
                'website': "https://luxemburgo.consuladoportugal.mne.gov.pt"
            }
        ]
    },
    'Ocenia': {
        'Australia': [
            {
                'name': "Consular Section of the Portuguese Embassy in Canberra",
                'email': "canberra@mne.pt",
                'contact': "+(61) (2) 62604970",
                'website': "https://camberra.embaixadaportugal.mne.gov.pt/"
            },
            {
                'name': "Consulate General of Portugal in Sydney",
                'email': "consulado.sidney@mne.pt",
                'contact': "+(612) 92 62 21 99",
                'website': "https://sidney.consuladoportugal.mne.gov.pt/"
            }
        ]
    }
}
export default Wheretoapply
