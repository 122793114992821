import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { Box} from '@mui/system'
import arrowsvg from '../../Images/Icon feather-arrow-right-circle-blue.svg'
import bgImage from '../../Images/pexels-sheila-731217.png'
import vdt2pic from '../../Images/vdt2.png'
import vdt3pic from '../../Images/vdt3.png'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import logoImg from '../../Images/Visa Discovery Logo desktop.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useHistory} from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import '../../Styling/desktop/vdtquestions.css'
import Footer from '../Homepage/Footer';
import LanguageSelector from '../Common Components/LanguageSelector'
function Vdtquestions() {
    const history=useHistory();
    const [questionNum, setquestionNum] = useState(1);
    const [ansQ1, setansQ1] = useState("")
    const [ansQ2, setansQ2] = useState("")
    const [ansQ3, setansQ3] = useState("")
    const [viewMore, setviewMore]=useState(false);
    const [optionsforQ2, setoptionsforQ2]=useState(null);
    const [optionsforQ3, setoptionsforQ3]=useState(null);
    useEffect(()=>{
        document.title="Questions: Visa Discovery Tool | Visa Discovery"
      },[])
    const optionsforQ1 = ["0 to 3 months", "1 Year or less", "Residency"];
    const question1block = (
            <Box>
                <Typography gutterBottom variant="h6" color="#2F2FA2" >How long do you plan to stay in Portugal?</Typography>
                <Box className="vdt-options" width="100%" height="20rem" display="flex" flexDirection="column" sx={{flexWrap:{xs: 'nowrap', md: 'nowrap'}}} style={{gap: "1rem", overflowY:"auto", padding: "10px"}}>
                    {
                        optionsforQ1.map((option, index) => (
                            <Box
                                key={index} 
                                boxShadow="4px 4px 20px #00000029" borderRadius="8px" sx={{maxWidth:{xs: "100%", md: "none"}, width: {xs: 'auto', md: 'auto'}}}
                                display="flex" alignItems="center" justifyContent="center" style={{ cursor: "pointer", padding: "1rem" }}
                                backgroundColor={option === ansQ1 ? "#2F2FA2" : "white"}
                                textAlign="center"
                                onClick={() => handleansclick(option)}
                            >
                                <Typography variant="body1" fontWeight="normal" color={option === ansQ1 ? "white" : "black"}>{option}</Typography>
                            </Box>
                        ))
                    }
                </Box>
            </Box>
    )
    
    const handleansclick = (option) => {
        if (questionNum === 1) {
            if(option==="0 to 3 months") setoptionsforQ2(["Tourism/Leisure", "Transit?", "Work"])
            else if(option==="1 Year or less") setoptionsforQ2(["Work", "Study" , "Medical"])
            else setoptionsforQ2(["Work", "Study","Family Regrouping", "Leisure", "Religion"])
            setquestionNum(2);
            setansQ1(option);
            
        }else if (questionNum === 2) {
            if(option==="Tourism/Leisure") setoptionsforQ3(["Tourist", "Visiting Friends/Family"]);
            else if(option==="Family Regrouping") setoptionsforQ3(["Family reunification purposes"]);
            else if(option==="Leisure") setoptionsforQ3(["Retirement Purposes", "People living out of individual revenue", 
               "Invest and Live in Portugal", "Invest in Environmental Projects and Live in Portugal", "Work and Travel in Portugal"]);
            else if(option==="Medical") setoptionsforQ3(["Medical treatment purposes",  "Accompanying family member undergoing medical treatment purposes"]);
            else if(option==="Religion") setoptionsforQ3(["Religious Purposes"]);
            else if(option==="Study") {
                if(ansQ1==="1 Year or less"){
                    setoptionsforQ3(["Study Programs Visa", "Student Exchange", "Unpaid Internship", "Course in Educational or professional training institution","Work Training"]);
                }else if(ansQ1==="Residency"){
                    setoptionsforQ3(["Higher Education Teaching", "Study", "Internship"]);
                }            
            }
            else if(option==="Transit?") setoptionsforQ3(["Transit Visa"]);
            else if(option==="Work"){
                if(ansQ1==="0 to 3 months")   setoptionsforQ3(["Business/Conference", "Seasonal Work"]);
                else if(ansQ1==="1 Year or less") setoptionsforQ3(["Seasonal Work (more than 90 days)", "Independent work Visa", "Scientific Research Visa",
                "Higher Education Visa","Professional Activity Visa", "Highly Qualified Activity Visa",
                "Amateur Sports Visa","Intra Corporate Transfer",
                "Temporary stay visa for transfer of workers purposes for state parties to the WTO, for provision of services or professional training",
                "Volunteer Work","Commitments pursuant to international agreements or conventions", "Work Training",
                "Exceptional and duly justified cases", "Unpaid Internship", "Course in Educational or professional training institution","Work Training"]);
                else{
                    setoptionsforQ3(["Subordinate work purposes","Internship",
                    "Independent work purposes",
                    "Entrepreneurship",
                    "Highly Qualified Activity Visa",
                    "Cultural Visa",
                    "Highly qualified subordinate activity",
                    "Research",
                    "Volunteer work purposes.",
                    "Techonology Work",
                    "Start or Run a company in Portugal", "Work and Travel in Portugal"
                    ]);
                }
            }
            
            setquestionNum(3);
            setviewMore(false);
            setansQ2(option)
        }else{
            setansQ3(option);
            setviewMore(false);
        }
    }
    const handleNextclick=()=>{
        if(questionNum===2){
            setquestionNum(3);
        }else{
            const location = {
                pathname: '/vdt4',
                state: { ansQ1, ansQ2, ansQ3}
              }
            history.push(location)
        }
    }
    
    return (
        <>
        <Box style={{ display: "flex", width: "100vw", maxWidth: "100%"}} sx={{flexDirection: {xs: 'column', md: 'row'}, alignItems:{xs: 'center', md: 'initial'}}}>
            <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem', md: '3rem 4rem' } }}>
                <Box width="100%" height="3.5rem" display="flex" alignItems="center" justifyContent="space-between">
                    <img onClick={() => history.push('/')} style={{ maxWidth: "80%", cursor: 'pointer' }} src={logoImg} alt="visas for portugal" width="auto" height="100%" ></img>
                    <Box style={{ cursor: 'pointer' }} display="flex " width="30%" height="100%" alignItems="center" justifyContent="flex-end">
                        <LanguageSelector />
                    </Box>
                </Box>
                <Box width="100%" marginTop="2.5rem" marginBottom="2rem">
                    <Typography gutterBottom variant="h5">Visa Discovery Tool</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography gutterBottom variant="overline">Question {questionNum}</Typography>
                    <Typography gutterBottom variant="overline" paddingRight="2rem" style={{ textTransform: "none", color: "#898989" }}>{questionNum} of 3 </Typography>
                </Box>
                {
                    questionNum===1? question1block :questionNum===2? <>
                    <Box>
                        <Typography gutterBottom variant="h6" color="#2F2FA2" >What matches your reason to travel?</Typography>
                        <Box className="vdt-options" width="100%" height="20rem" sx={{display:{xs: 'flex', md: 'none'}}}  flexDirection="column"  style={{gap: "1rem", overflowY:"auto", padding: "10px"}}>
                            {
                                optionsforQ2?.map((option, index) => (
                                    <Box
                                        key={index} 
                                        boxShadow="4px 4px 20px #00000029" borderRadius="8px" sx={{maxWidth:{xs: "100%", md: "none"}, width: {xs: 'auto', md: '85%'}}} 
                                        display="flex" alignItems="center" justifyContent="center" style={{ cursor: "pointer", padding: "1rem" }}
                                        backgroundColor={option === ansQ2 ? "#2F2FA2" : "white"}
                                        textAlign="center"
                                        onClick={() => handleansclick(option)}
                                    >
                                        <Typography variant="body1" fontWeight="normal" color={option === ansQ2 ? "white" : "black"}>{option}</Typography>
                                    </Box>
                                ))
                            }
                        </Box>
                        
                        <Collapse className="collapse-options" in={viewMore} collapsedSize="20rem" 
                                sx={{display:{xs: 'none', md: 'flex'}, 
                                    '& .MuiCollapse-wrapperInner':{display: 'flex', flexDirection: 'column', gap: '1rem'},
                                    '& .MuiBox-root:hover': {backgroundColor: '#2F2FA2'},
                                    '& .MuiBox-root:hover p':{color: 'white'}
                                    }} 
                                    flexDirection="column"  style={{padding: "15px", paddingBottom: '25px'}}
                            >
                                {
                                    optionsforQ2?.map((option, index) => (
                                        <Box 
                                            key={index} 
                                            boxShadow="4px 4px 20px #00000029" borderRadius="8px" sx={{maxWidth:{xs: "100%", md: "none"}, width: {xs: 'auto', md: 'auto'}}} 
                                            display="flex" alignItems="center" justifyContent="center" style={{ cursor: "pointer", padding: "1rem"}}
                                            backgroundColor={option === ansQ2 ? "#2F2FA2" : "white"}
                                            textAlign="center"
                                            onClick={() => handleansclick(option)}
                                        >
                                            <Typography variant="body1" fontWeight="normal" color={option === ansQ2 ? "white" : "black"}>{option}</Typography>
                                        </Box>
                                    ))
                                }
                        </Collapse>
                        <Box sx={{display: {xs: 'none', md: 'block'}}}>
                            <Button onClick={()=>setviewMore(!viewMore)} variant="outlined" endIcon={viewMore?<ExpandLess/>:<ExpandMore/>}
                                sx={{height: '30px', borderRadius: '15px', border: 'none !important', backgroundColor: '#2F2FA2 !important',display: optionsforQ2.length>=5?'flex':'none',
                                color: 'white !important', margin: '0 auto', '& .MuiButton-endIcon':{marginRight: '-6px', marginLeft: '4px'}, marginTop: '-15px'}}
                            >
                              {viewMore?'see less':'see more'}
                            </Button>
                        </Box>
                    </Box>
                    </>:<Box>
                            <Typography gutterBottom variant="h6" color="#2F2FA2" >What is the purpose of your trip?</Typography>
                            <Box className="vdt-options" width="100%" height="20rem" sx={{display:{xs: 'flex', md: 'none'}}} flexDirection="column"  style={{gap: "1rem", overflowY:"auto", padding: "10px"}}>
                                {
                                    optionsforQ3?.map((option, index) => (
                                        <Box
                                            key={index} 
                                            boxShadow="4px 4px 20px #00000029" borderRadius="8px" sx={{maxWidth:{xs: "100%", md: "none"}, width: {xs: 'auto', md: 'auto'}}} 
                                            display="flex" alignItems="center" justifyContent="center" style={{ cursor: "pointer", padding: "1rem"}}
                                            backgroundColor={option === ansQ3 ? "#2F2FA2" : "white"}
                                            textAlign="center"
                                            onClick={() => handleansclick(option)}
                                        >
                                            <Typography variant="body1" fontWeight="normal" color={option === ansQ3 ? "white" : "black"}>{option}</Typography>
                                        </Box>
                                    ))
                                }
                            </Box>
                            <Collapse className="collapse-options" in={viewMore} collapsedSize="20rem" 
                                sx={{display:{xs: 'none', md: 'flex'}, 
                                    '& .MuiCollapse-wrapperInner':{display: 'flex', flexDirection: 'column', gap: '1rem'},
                                    '& .MuiBox-root:hover': {backgroundColor: '#2F2FA2'},
                                    '& .MuiBox-root:hover p':{color: 'white'}
                                    }} 
                                    flexDirection="column"  style={{padding: "15px", paddingBottom: '25px'}}
                            >
                                {
                                    optionsforQ3?.map((option, index) => (
                                        <Box 
                                            key={index} 
                                            boxShadow="4px 4px 20px #00000029" borderRadius="8px" sx={{maxWidth:{xs: "100%", md: "none"}, width: {xs: 'auto', md: 'auto'}}} 
                                            display="flex" alignItems="center" justifyContent="center" style={{ cursor: "pointer", padding: "1rem"}}
                                            backgroundColor={option === ansQ3 ? "#2F2FA2" : "white"}
                                            textAlign="center"
                                            onClick={() => handleansclick(option)}
                                        >
                                            <Typography variant="body1" fontWeight="normal" color={option === ansQ3 ? "white" : "black"}>{option}</Typography>
                                        </Box>
                                    ))
                                }
                            </Collapse>
                            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                                <Button onClick={()=>setviewMore(!viewMore)} variant="outlined" endIcon={viewMore?<ExpandLess/>:<ExpandMore/>}
                                    sx={{height: '30px', borderRadius: '15px', border: 'none !important', backgroundColor: '#2F2FA2 !important',display: optionsforQ3.length>=5?'flex':'none',
                                    color: 'white !important', margin: '0 auto', '& .MuiButton-endIcon':{marginRight: '-6px', marginLeft: '4px'}, marginTop: '-15px'}}
                                >
                                  {viewMore?'see less':'see more'}
                                </Button>
                            </Box>
                        </Box>
                }
                <Box mt="2rem" width="100%" height="2rem" alignItems="center" justifyContent="space-between" padding="0 2rem" display={questionNum === 1 ? 'none' : "flex"}>
                    <Box width="5rem" height="100%" display="flex" alignItems="center" justifyContent="center" border="0.08px solid #898989" borderRadius="8px" style={{cursor: 'pointer'}} 
                    onClick={()=>{if(questionNum===3){setansQ3(""); setviewMore(false)}; setquestionNum(questionNum-1)}}>
                        <ArrowBackIcon style={{ fill: "#898989", fontSize: "1rem" }} />
                        <Typography variant="body2" padding="0 4px">Back</Typography>
                    </Box>
                    <Box width="5rem" height="100%" display={questionNum===3?ansQ3?"flex":"none":ansQ2?"flex":"none"} alignItems="center" justifyContent="center" border="0.08px solid #898989" borderRadius="8px" style={{cursor: 'pointer'}}
                    onClick={handleNextclick}
                    >
                        <Typography variant="body2" padding="0 4px">{questionNum===3?"Submit":"Next"}</Typography>
                        <ArrowForwardIcon style={{ fill: "#898989", fontSize: "1rem" }} />
                    </Box>
                </Box>
            </Box>
            <Box className="vdt-side-box" sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem' }, height: {xs: 'auto', md: '100vh'} }} backgroundColor="#F0F0FF" boxShadow="inset 4px 3px 6px #00000029" style={{position: 'sticky', top: '0px'}}>
                <Box className="vdt-img" margin="auto"  width="100%" sx={{padding:{xs: '0 0 1rem 0', md: '0px 60px 1rem'}}}>
                    <img src={questionNum===1?bgImage:questionNum===2?vdt2pic:vdt3pic} width="100%" height="auto" alt="visa questions"></img>
                </Box>
                <Box textAlign="center">
                    <Typography gutterBottom variant="h6">
                        {
                            questionNum===1?"Simplify your travel with our online service store":questionNum===2?"Learn Portuguese Online":"Buy Travel Insurance Online - Hassle-free"
                        }
                        
                    </Typography>
                    <Box width="7.5rem" height="3rem" backgroundColor="white" border="0.1px solid #898989" borderRadius="5px" margin="auto" display="flex" alignItems="center" justifyContent="center">
                        <Typography>Know More</Typography>
                        <OpenInNewIcon fontSize="0.5rem" />
                    </Box>
                </Box>
            </Box>
        </Box>
        <Footer/>
        </>
    )
}

export default Vdtquestions
