import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import rect46img from '../../Images/Rectangle 46.png'
import arrowsvg from '../../Images/Icon feather-arrow-right-circle.svg'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import {Link} from 'react-router-dom'
function Shortstaycard2({title, description, path="/"}) {
    console.log({path})
    return (
        <Card style={{ borderRadius: "1.3rem", boxShadow: "4px 4px 20px #00000029", minWidth: '220px', maxWidth:"350px", height: {xs: 'auto', md: '100%'} }} sx={{ mr: { xs: '0rem', md: '0' } }}>
            <CardActionArea>
                <Link to={path} style={{textDecoration: 'none'}}>
                <CardMedia
                    component="img"
                    height="50%"
                    image={rect46img}
                    alt="destination portugal"
                />
                <CardContent sx={{padding: '1.25rem'}}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6" fontWeight="700">{title}</Typography>
                        <Box display="inline-block" alignSelf="flex-start">
                            <img src={arrowsvg} alt="arrow" width="70%" height="70%"></img>
                        </Box>
                    </Box>
                    <Box margin="1rem 0">   
                        <Typography variant="body2">{description}</Typography>
                    </Box>
                </CardContent>
                </Link>
            </CardActionArea>
        </Card>
    )
}

export default Shortstaycard2
