import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import rect35 from '../../Images/golden visa process time fees.jpg'

function Wvsection5({ bgColor, refProp }) {
    return (
        <>
            <Box ref={refProp} width="100%" backgroundColor={bgColor}>
                <Box className="section5 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                    <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }} mt="2rem" >
                        <Box sx={{ width: { xs: '100%', md: '50%' }, paddingLeft: { xs: '0', md: '4rem' } }} >
                            <Typography mb=".5rem" variant="h4" ml="0px important">Processes, Timelines and Fees</Typography>
                            <Typography gutterBottom variant="h5" color='#2F2FA2'>
                                Process
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                Once you receive a job offer, the Portuguese Work Visa is divided into three parts:
                            </Typography>
                            <Box sx={{ minHeight: { xs: '.8rem', md: '1rem' } }}></Box>
                            <Box display="flex" alignItems="center" >
                                <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">1</Box>
                                <Typography variant="h7">Employer files for Work Permit </Typography>
                            </Box>
                            <Typography ml="3rem" mb="1rem" variant="body2">
                                As a base requirement, employers must file for the permit after they have offered
                                you the job and before you join/move to Portugal.
                            </Typography>
                            <Box display="flex" alignItems="center" >
                                <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">2</Box>
                                <Typography variant="h7">Work Visa application </Typography>
                            </Box>
                            <Typography ml="3rem" mb="1rem" variant="body2">
                                Once you have a work permit from your employer, you can apply for a Portuguese
                                Work VIsa at the Portuguese Embassy closest to you.
                            </Typography>
                            <Box display="flex" alignItems="center" >
                                <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">3</Box>
                                <Typography variant="h7">Portugal Residence Permit </Typography>
                            </Box>
                            <Typography ml="3rem" mb="1rem" variant="body2">
                                Once you are in Portugal, you have to apply for your Portuguese Residence Permit
                                at the Portuguese Immigration and Borders Service (SEF). This permit allows you to
                                live in Portugal for more than 1 year.

                            </Typography>
                            <Typography mb=".5rem" variant="h5" ml="0px important" color='#2F2FA2'>Timelines</Typography>
                            <Typography gutterBottom variant="body2">
                            Once you receive a Work Permit from your employer, the Work Visa application is
                            completed in 2 stages and the timelines are dependant on that -
                            </Typography>
                            <Box sx={{ minHeight: { xs: '.8rem', md: '1rem' } }}></Box>
                            <Box display="flex" alignItems="center" mb="1rem">
                                <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">1</Box>
                                <Typography variant="h7">SEF Process - upto 60 days </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb="2rem">
                                <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">2</Box>
                                <Typography variant="h7">Portuguese Embassy - upto 3 months</Typography>
                            </Box>
                            
                            <Typography mb="1rem" variant="h5" ml="0px important" color='#2F2FA2'>Fees</Typography>
                            <Typography mb=".5rem" variant="body2" paddingLeft="0.1rem">Cost of the entry visa issued by the Portuguese Embassy – €90*</Typography>
                            <Typography mb=".5rem" variant="body2" paddingLeft="0.1rem">To submit the application for a residence permit for work at the SEF – €83</Typography>
                            <Typography mb="1rem" variant="body2" paddingLeft="0.1rem">To receive the residence permit for work from the SEF – €72</Typography>
                            <Typography  variant="caption" paddingLeft="0.1rem">*may be subject to change from country to country</Typography>
                        </Box>

                        <Box sx={{ width: { xs: '100%', md: '50%' }, margin: { xs: '2rem auto', md: '0' } }}>
                            <img src={rect35} alt="portugal work visa apply online" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '1.3rem', boxShadow: 'rgb(0 0 0 / 16%) 4px 4px 8px' }}></img>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Wvsection5
