import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Cards from "../Golden visa/Cards";
import rect35 from "../../Images/startup visa/Startup Visa - Overview.jpg";
import time from "../../Images/Time.svg";
import User from "../../Images/User.svg";
import Euro from "../../Images/Euro.svg";

function Svsection2({ refProp }) {
  return (
    <>
      <Box
        ref={refProp}
        className="section2 wrapper"
        sx={{
          width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          padding: { xs: "1rem 0", md: "2rem 0" },
        }}
        margin="auto"
      >
        <Box
          width="100%"
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              paddingRight: { xs: "0", md: "4rem" },
            }}
          >
            <Typography mb=".5rem" variant="h4" ml="0px important">
              Overview
            </Typography>
            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
              The Startup Visa is perfect for anyone who has already started a
              startup or hasn't yet started working on their new idea, looking
              to build and grow it in the Portuguese and European markets.
              Alongwith Portuguese residency, this Visa also gets you access to
              a wide variety of benefits provided by the government of Portugal
              and the startup-friendly business culture of the country.
            </Typography>
            <Box sx={{ minHeight: { xs: ".8rem", md: "1.5rem" } }}></Box>
            <Typography mb="1rem" variant="h4" ml="0px important">
              Highlights
            </Typography>

            <Grid
              mt=".3rem"
              container
              columnSpacing={{ xs: 2, md: 3 }}
              rowSpacing={{ xs: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Cards caption="Stay Duration" svg={time} title="Residency" />
              </Grid>
              <Grid item xs={6}>
                <Cards caption="Fees" svg={Euro} title="EUR 1,000" />
              </Grid>
              <Grid item xs={6}>
                <Cards
                  caption="Requirement "
                  svg={User}
                  title="Tech focused Startup"
                />
              </Grid>
              <Grid item xs={6}>
                <Cards
                  caption="Application Time"
                  svg={time}
                  title="Upto 3 months"
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              margin: { xs: "2rem auto 1rem", md: "0" },
            }}
          >
            <img
              src={rect35}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "1.3rem",
                boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
              }}
              alt="portugal startup visa program"
            ></img>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Svsection2;
