import React, {useState} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import '../../Styling/desktop/commoncomp.css'
import Grid from '@mui/material/Grid';
import '../../Styling/desktop/contactus.css';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import Alert from '@mui/material/Alert';
import UpdatesCheckbox from './UpdatesCheckbox';
function Inquiryform({caller}) {
    const [fullName, setfullName]=useState("");
    const [Email, setEmail]=useState("");
    const [Message, setMessage]=useState("");
    const [successAlert, setsuccessAlert]=useState(false)
    const [errorAlert, seterrorAlert]=useState(false)
    const [btndisabled, setbtndisabled]=useState(false);
    const [receiveUpdatecheckbox, setreceiveUpdatecheckbox]=useState(false)

    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log("handlesubmit called")
        setbtndisabled(true);
        const SHEET_URL="https://script.google.com/macros/s/AKfycbz4mq9w2vgW6ROUFHQ834_BbS4MqNfZWQ8sxy9fqbvhGogtDltX2Kl9Y45Zv2SHRoSoiA/exec"
        let today = new Date();
        let date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dataObj={
            sheetName: 'ServiceInquiry',
            ID: uuidv4(),
            Name: fullName,
            Email: Email,
            Message: Message,
            PageURL: window.location.href,
            PageTitle: document.title,
            ServiceType: caller,
            Date: date,
            Time:time,
            receiveUpdate: receiveUpdatecheckbox,
        }
        let formData=new FormData();
        for(let key in dataObj) {
            formData.append(key, dataObj[key]);
        }
        let mail_promise=await axios.post('http://127.0.0.1:8080/send_mail', dataObj);
        let googleSheet_promise=await axios.post(SHEET_URL, formData);

        try{
            let responseArr=await Promise.all([mail_promise, googleSheet_promise]);
            // console.log(responseArr);
            if(responseArr[0].data.mail_status==="success"&&responseArr[1].data.result==="success"){
                setTimeout(()=>setsuccessAlert(false), 3000)
                setsuccessAlert(true);
                setbtndisabled(false);
            }else{
                throw 'Something went wrong'
            }
        }catch(err){
            console.log(err);
            setTimeout(()=>seterrorAlert(false), 3000)
            seterrorAlert(true);
            setbtndisabled(false)
        }
        setfullName("");
        setEmail("");
        setMessage("");
        setreceiveUpdatecheckbox(false)
    }
    return (
        <>
            <Box sx={{ height: {xs: 'auto', md: '33rem'}, width: '100%', padding: {xs: '2rem 1.5rem', md: '4rem 3rem'}, backgroundColor: "#F0F0FF", borderRadius: '1rem' }}>
                <div style={{width:"100%", height:"15%"}} >
                    <Typography variant="h4" fontSize="2rem" sx={{textAlign: {xs: 'center', md: 'left'}, mb:{xs: '1rem', md: '0'}}}>Inquire Now</Typography>
                </div>
                <div className="inquiry-form-container" style={{ width: '100%', height:"85%", display: 'flex', alignItems:"center", flexDirection:"column" }}>
                    <form onSubmit={handleSubmit} style={{width: '100%', height: '100%'}}>
                        <Typography gutterBottom variant="body2" fontSize="1rem">Full Name</Typography>
                        <input required value={fullName} onChange={(e)=>setfullName(e.target.value)}className="inquire-form-input" type="text" id="full-name" name="full-name" placeholder='John Doe'/><br />
                        <Typography gutterBottom variant="body2" fontSize="1rem">Email</Typography>
                        <input required value={Email} onChange={(e)=>setEmail(e.target.value)} className="inquire-form-input" type="email" id="email" name="email" placeholder='john@email.com'/><br/>
                        <Typography gutterBottom variant="body2" fontSize="1rem">Message</Typography>
                        <textarea required value={Message} onChange={(e)=>setMessage(e.target.value)}className="inquire-form-msg"  id="msg" name="Message"placeholder='Your message'></textarea><br/>
                        <UpdatesCheckbox receiveUpdatecheckbox={receiveUpdatecheckbox} setreceiveUpdatecheckbox={setreceiveUpdatecheckbox}/>
                        <Button disabled={btndisabled} type="submit" style={{width:"100%", height:"3rem", borderRadius: '.5rem', backgroundColor:"#2F2FA2", color: 'white', fontSize:"1rem"}}>{btndisabled?"Please Wait...":"Submit"}</Button>
                        <Alert sx={{display: successAlert?'flex':'none', margin: '0 auto', width: 'fit-content'}} icon={<CheckIcon fontSize="inherit" />} severity="success">Successfully Submitted!</Alert>
                        <Alert sx={{display: errorAlert?'flex':'none', margin: '0 auto', width: 'fit-content'}} severity="error">Error Submiting! Try Again</Alert>        
                    </form>
                </div>
            </Box>
            <Box sx={{height: {xs: 'auto', md: '7.5rem'}, width:{xs: 'auto', md: '100%'}}} marginTop="1.5rem">
                <Grid container rowSpacing={{ xs: 2, md: 3 }} columnSpacing={{ xs: 1, md: 1 }} sx={{maxWidth: {xs: '250px', md: 'fit-content'}, ml:{xs: 'calc((100% - 250px) / 2)', md: '-8px'}}}>
                    <Grid item xs={12} md={6}>
                        <Typography className="contactoptions phone" variant="body2" >Call +91 99999 88888</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className="contactoptions email" variant="body2" >info@visadiscovery.com</Typography>    
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className="contactoptions calender" variant="body2">Book an Appointment</Typography>    
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Typography className="contactoptions whatsapp" variant="body2">Whatsapp Us</Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Inquiryform
