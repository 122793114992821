import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import arrowsvg from '../../Images/Icon feather-arrow-right-circle.svg'
import skitterphoto from '../../Images/pexels-skitterphoto-9253.png'
import residencypic from "../../Images/Residency Visa - Home Page.jpg"
import shortstaypic from "../../Images/Short Stay Visa - Home Page.jpg"
import tempstaypic from "../../Images/Temporary Stay Visa - Home Page.jpg"
import { Link } from 'react-router-dom';
function Homesection2() {
  const card1 = (
    <Card sx={{ maxWidth: 'min(100%, 350px)', mb: { xs: '2rem', md: '0' } }} style={{ borderRadius: "1.3rem", boxShadow: "4px 4px 20px #00000029" }}>
      <CardActionArea>
        <Link to={'/portugal-short-stay-visa'} style={{ textDecoration: 'none', maxWidth: 'min(100%, 350px)', height: '100%' }}>
          <CardMedia
            component="img"
            height="50%"
            image={shortstaypic}
            alt="destination portugal"
          />
          <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="overline">UNDER 90 DAYS</Typography>
              <Box >
                <img src={arrowsvg} alt="arrow" width="70%" height="70%"></img>
              </Box>
            </Box>
            <Box>
              <Typography mb="1rem" mt="0.4rem" variant="h5">Short Stay Visas</Typography>
            </Box>
            <Box paddingBottom="1.5rem">
              <Typography variant="body2">Visas for travel to Portugal and other Schengen countries for less than 3
                months. This visa type is perfect for tourists looking to travel through the country.
              </Typography>
            </Box>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>

  )
  const card2 = (

    <Card sx={{ maxWidth: 'min(100%, 350px)', mb: { xs: '2rem', md: '0' } }} style={{ borderRadius: "1.3rem", boxShadow: "4px 4px 20px #00000029" }}>
      <CardActionArea>
        <Link to={'/portugal-temporary-stay-visa'} style={{ textDecoration: 'none', maxWidth: 'min(100%, 350px)', height: '100%' }}>
          <CardMedia
            component="img"
            height="50%"
            image={tempstaypic}
            alt="destination portugal"
          />
          <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="overline">UNDER 1 YEAR</Typography>
              <Box >
                <img src={arrowsvg} alt="arrow" width="70%" height="70%"></img>
              </Box>
            </Box>
            <Box>
              <Typography mb="1rem" mt="0.4rem" variant="h5">Temporary Stay Visas</Typography>
            </Box>
            <Box paddingBottom="1.5rem">
              <Typography variant="body2">Visas for people who are looking to stay in Portugal for under a year for any
                professional or medical reasons.</Typography>
            </Box>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>

  )
  const card3 = (

    <Card sx={{ maxWidth: 'min(100%, 350px)', mb: { xs: '2rem', md: '0' } }} style={{ borderRadius: "1.3rem", boxShadow: "4px 4px 20px #00000029" }}>
      <CardActionArea>
        <Link to={'/portugal-residency-visa'} style={{ textDecoration: 'none', maxWidth: 'min(100%, 350px)', height: '100%' }}>
          <CardMedia
            component="img"
            height="50%"
            image={residencypic}
            alt="destination portugal"
          />
          <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="overline">MORE THAN A YEAR</Typography>
              <Box >
                <img src={arrowsvg} alt="arrow" width="70%" height="70%"></img>
              </Box>
            </Box>
            <Box>
              <Typography mb="1rem" mt="0.4rem" variant="h5">Residency Visas</Typography>
            </Box>
            <Box paddingBottom="2.5rem">
              <Typography variant="body2">Different options of Visas for anyone looking to relocate to Portugal and take
                benefit of it’s diverse beauty and incredible opportunities.</Typography>
            </Box>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  )
  return (
    <Box width="100vw" sx={{
      height: { xs: 'auto' },
      padding: { xs: 'calc(12.5vh + 3rem) 1rem 3rem', md: '3rem 1.5rem' }, maxWidth: { xs: '100%', md: 'min(100vw, 1200px)' },
      margin: '0 auto'
    }}
      display="flex" flexDirection="column" justifyContent="space-around" >
      <Typography margin="auto" gutterBottom variant="h4" color="var(--tblue)" mb="2rem">Types of Portuguese Visas</Typography>
      <Box display="flex" flexWrap="wrap" justifyContent="space-around" gap="1rem"
        sx={{ mt: { xs: '2rem', md: '0' } }}>
        {card1}
        {card2}
        {card3}
      </Box>
    </Box>
  )
}

export default Homesection2
