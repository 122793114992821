import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Header2 from "../Common Components/Header2";
import Wheretoapply from "../Common Components/Wheretoapply";
import Header from "../Homepage/Header";
import Footer from "../Homepage/Footer";
import Svsection2 from "./Svsection2";
import Svsection4 from "./Svsection4";
import Svsection5 from "./Svsection5";
import Svsection6 from "./Svsection6";
// import Additionalservices from '../Common Components/Additionalservices'
import Requestconsultation from "../Golden visa/Requestconsultation";
import { Typography } from "@mui/material";
import "../../Styling/desktop/homepage.css";
import arrow2svg from "../../Images/Icon feather-arrow-right-circle-2.svg";
import pic from "../../Images/Start Up Visa.jpg";
import Dialog from "@mui/material/Dialog";
import PopupForm from "../Common Components/PopupForm";
import "../../Styling/desktop/goldenvisa.css";
import { Helmet } from "react-helmet";

function Startupvisa() {
  const [open, setOpen] = React.useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  useEffect(() => {
    document.title = "Portugal Startup Visa | Visa Discovery";
  }, []);
  //making an array of objs with heading name and its ref and passing this to Header2
  const headingArr = [
    {
      headingName: "Details & Eligibility",
      headingRef: ref1,
    },
    {
      headingName: "Requirements",
      headingRef: ref2,
    },
    {
      headingName: "Process and Fees",
      headingRef: ref3,
    },
    {
      headingName: "Where to apply",
      headingRef: ref4,
    },
    {
      headingName: "FAQ",
      headingRef: ref5,
    },
    // {
    //     headingName: 'Additional Services',
    //     headingRef: ref6
    // },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Residency Visas",
                item: "https://visadiscovery.com/portugal-residency-visa",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Startup Visa",
                item: "https://visadiscovery.com/portugal-startup-visa",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" maxWidth="100%">
        <Header notfixed="header-nonfixed" />
        <Box
          className="section1 wrapper"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            padding: { xs: "1rem 0", md: "2rem 0" },
            minHeight: { xs: "unset", md: "88vh" },
          }}
          margin="auto"
        >
          <Box
            width="100%"
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                paddingRight: { xs: "0", md: "4rem" },
              }}
            >
              <Typography mb=".5rem" variant="h1" ml="0px important">
                Start Up Visa
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                paddingLeft="0.4rem"
                fontSize="1.5rem !important"
                fontWeight="400 !important"
              >
                The StartUP Visa is a residence visa for non EU-Schenghen
                startup founders looking to be a part of one of the fastest
                developing startup locations in all of Europe.
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                paddingLeft="0.4rem"
                fontSize="1.5rem !important"
                fontWeight="400 !important"
              >
                This program is managed and executed by IAPMEI and involves the
                National Network of Incubators throughout the country. This
                ensures that your startup will be set up next to a community of
                entrepreneurs already building their ideas in the ecosystem and
                well acquainted with the Portuguese and European market.
              </Typography>
              <Box
                onClick={() => setOpen(true)}
                style={{
                  height: "3rem",
                  borderRadius: ".5rem",
                  backgroundColor: "#2F2FA2",
                  color: "white",
                  cursor: "pointer",
                }}
                sx={{ margin: { xs: "1rem auto", md: "0.4rem .4rem" } }}
                display="inline-flex"
                alignItems="center"
                justifyContent="space-evenly"
                gap="1rem"
                padding="0 2rem"
              >
                <Typography variant="body1" color="white" whiteSpace="nowrap">
                  REQUEST CONSULTATION
                </Typography>
                <img src={arrow2svg}></img>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: { xs: "1rem auto", md: "0" },
              }}
            >
              <img
                src={pic}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "1.3rem",
                  boxShadow: "4px 4px 8px #00000029",
                }}
                alt="portugal startup visa"
              ></img>
            </Box>
          </Box>
        </Box>
        <Header2 headerObjArr={headingArr} />
        <Box className="sticky-request-container">
          <Requestconsultation openSetterFn={setOpen} />
          <Svsection2 refProp={ref1} />
          <Svsection4 bgColor="#F5F5F5" refProp={ref2} />
          <Svsection5 refProp={ref3} />
          <Wheretoapply refProp={ref4} />
          <Svsection6 refProp={ref5} bgColor="white" />
          {/* <Additionalservices bottomMargin="not required" refProp={ref6}/> */}
        </Box>
        <Footer />
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              opacity: "0.4 !important",
              backgroundColor: "#2F2FA2",
            },
            zIndex: "99002",
            "& .MuiDialog-paper": {
              maxWidth: "none",
              borderRadius: { xs: "1rem", md: "1.5rem" },
              maxHeight: { xs: "none", md: "75vh" },
              width: { xs: "-webkit-fill-available", md: "auto" },
            },
          }}
        >
          <PopupForm title="Golden Visa" openSetterFn={setOpen}></PopupForm>
        </Dialog>
      </Box>
    </>
  );
}

export default Startupvisa;
