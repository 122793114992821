import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/golden visa process time fees.jpg";
import arrowbluesvg from "../../Images/Icon feather-arrow-right-circle-blue.svg";
import { Link } from "react-router-dom";
function Greenvsection5({ bgColor, refProp }) {
  return (
    <>
      <Box width="100%" backgroundColor={bgColor} ref={refProp}>
        <Box
          className="section5 wrapper"
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            padding: { xs: "1rem 0", md: "2rem 0" },
          }}
          margin="auto"
        >
          <Box
            width="100%"
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            mt="2rem"
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                paddingRight: { xs: "0", md: "4rem" },
              }}
            >
              <Typography mb=".5rem" variant="h4" ml="0px important">
                Processes, Timelines and Fees
              </Typography>
              <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                The Green Visa program starts with
              </Typography>
              <Box sx={{ minHeight: { xs: ".8rem", md: "1rem" } }}></Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  1
                </Box>
                <Typography variant="h7">
                  Identify Investment Opportunities with our team of experts.
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  2
                </Box>
                <Typography variant="h7">
                  Application and Investment.
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  3
                </Box>
                <Typography variant="h7">
                  Visit Portugal to start the legal process and provide
                  biometrics
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  4
                </Box>
                <Typography variant="h7">Visa Approval - 2-8 months</Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="3rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  5
                </Box>
                <Typography variant="h7">
                  Visit Portugal - for 7 days in year 1 and years 3 and 5.
                </Typography>
              </Box>
              <Typography mb="1rem" variant="h4" ml="0px important">
                Fees
              </Typography>
              <Box width="fit-content">
                <Link
                  to={"/golden-visa-cost-tool"}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mb="0px"
                    variant="body2"
                    paddingLeft="0.1rem"
                    style={{ color: "var(--tblue)" }}
                  >
                    View Fee Calculator
                  </Typography>
                  <img src={arrowbluesvg} alt=""></img>
                </Link>
              </Box>
              {/* <Typography mb="2rem" variant="body2" paddingLeft="0.1rem">Average Agent Fees - EUR 100-150</Typography> */}
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: { xs: "0 auto", md: "0" },
              }}
            >
              <img
                src={rect35}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "460px",
                  objectFit: "cover",
                  borderRadius: "1.3rem",
                  boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
                }}
                alt="apply portugal green visa"
              ></img>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Greenvsection5;
