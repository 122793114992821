import React, { useEffect } from "react";
import Homesection1 from "./Homesection1";
import Homesection2 from "./Homesection2";
import Homesection3 from "./Homesection3";
import Homesection4 from "./Homesection4";
import Homesection5 from "./Homesection5";
import Homesection5mid from "./Homesection5mid";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

function Homepage() {
  return (
    <>
      <Helmet>
        <title>Visas for Portugal Simplified | Visa Discovery</title>
        <meta
          name="description"
          content="Get all the information and application processes for all visas for Portugal. Discover the right visa among Portugal golden visa, nomad visa, green visa, and much more with Visa Discovery."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Visa Discovery",
            "url": "https://visadiscovery.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "{search_term_string}",
              "query-input": "required name=search_term_string",
            }
          })}
        </script>
        {/* Organization Schema: */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Visa Discovery",
            "url": "https://visadiscovery.com/",
            "logo": "https://visadiscovery.com/static/media/Visa%20Discovery%20Logo%20desktop.0b3ad58c.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91 99999 88888",
              "contactType": "customer service",
            }
          })}
        </script>
      </Helmet>
      <Homesection1 />
      <Homesection2 />
      <Homesection3 />
      <Homesection4 />
      <Homesection5 />
      <Homesection5mid />
      <Footer />
    </>
  );
}

export default Homepage;
