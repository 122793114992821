import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import rect35 from '../../Images/Work Visa - Type.jpg'

function TypesofWv({ bgColor, refProp }) {
    return (
        <>
            <Box width="100%" backgroundColor={bgColor} ref={refProp}>
                <Box className="section3 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                    <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }} mt="2rem" >
                        <Box sx={{ width: { xs: '100%', md: '50%' }, margin: { xs: '1rem auto 2rem', md: '0' } }}>
                            <img src={rect35} alt="types of work visa for portugal" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '1.3rem', boxShadow: 'rgb(0 0 0 / 16%) 4px 4px 8px' }}></img>
                        </Box>
                        <Box sx={{ width: { xs: '100%', md: '50%' }, paddingLeft: { xs: '0', md: '4rem' } }} >
                            <Typography mb=".5rem" variant="h4" ml="0px important">Types of Portuguese Work Visas</Typography>
                            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                                You can apply for the following types of work visas, depending on your profession or qualifications:
                            </Typography>
                            <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                            <Box>
                                <Box display="flex" alignItems="center">
                                    <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">1</Box>
                                    <Typography variant="h6">Skilled Workers.
                                    </Typography>
                                </Box>
                                <Box ml="2.5rem" mt=".5rem">
                                    <Typography gutterBottom variant="body2">
                                        You must have a job offer in one of the qualifying professions or positions. For
                                        example, scientists, medical professionals, IT professionals, engineers, or someone
                                        working in high-ranking or administrative positions in a company
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                            <Box>
                                <Box display="flex" alignItems="center">
                                    <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">2</Box>
                                    <Typography variant="h6">Self-Employment.
                                    </Typography>
                                </Box>
                                <Box ml="2.5rem" mt=".5rem">
                                    <Typography gutterBottom variant="body2">
                                        If you own a business or offer services you can apply for a self-employment visa in Portugal as an independent worker
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                            <Box>
                                <Box display="flex" alignItems="center">
                                    <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">3</Box>
                                    <Typography variant="h6">Highly-Skilled Workers.
                                    </Typography>
                                </Box>
                                <Box ml="2.5rem" mt=".5rem">
                                    <Typography gutterBottom variant="body2">
                                        If you have exceptional qualifications in your profession and are paid at least 1.5
                                        times the national average gross annual salary of Portugal.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                            <Box>
                                <Box display="flex" alignItems="center">
                                    <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">4</Box>
                                    <Typography variant="h6">Cultural Activities or Sports Activities.
                                    </Typography>
                                </Box>
                                <Box ml="2.5rem" mt=".5rem">
                                    <Typography gutterBottom variant="body2">
                                        If you will be involved in a cultural activity carried out in Portugal, in a project which
                                        is recognized by the relevant Portuguese authorities.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                            <Box>
                                <Box display="flex" alignItems="center">
                                    <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">5</Box>
                                    <Typography variant="h6">Teachers
                                    </Typography>
                                </Box>
                                <Box ml="2.5rem" mt=".5rem">
                                    <Typography gutterBottom variant="body2">
                                        You are eligible for this visa if you have a job offer from an educational or vocational
                                        training institution or from a research centre in Portugal.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                            <Box>
                                <Box display="flex" alignItems="center">
                                    <Box className="visa-section5-box" sx={{width: {xs: '1.5rem', md: '2rem'}}} textAlign="center" borderRadius=".5rem" color="white" marginRight="1rem">6</Box>
                                    <Typography variant="h6">“Tech” Visa.
                                    </Typography>
                                </Box>
                                <Box ml="2.5rem" mt=".5rem">
                                    <Typography gutterBottom variant="body2">
                                        You can apply for this type of work visa if you are employed by a company that is
                                        “active in the area of technology and innovation”.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default TypesofWv
