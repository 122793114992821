import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/golden visa requirements.jpg";
import "../../Styling/desktop/goldenvisa.css";
import tick from "../../Images/Tick.svg";
function Tvsection4({ bgColor, refProp }) {
  return (
    <Box ref={refProp} width="100vw" maxWidth="100%" backgroundColor={bgColor}>
      <Box
        className="section4 wrapper"
        sx={{
          width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          padding: { xs: "1rem 0", md: "2rem 0" },
        }}
        margin="auto"
      >
        <Box
          width="100%"
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          mt="2rem"
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              margin: { xs: "0 auto 1rem", md: "0" },
            }}
          >
            <img
              src={rect35}
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "680px",
                objectFit: "cover",
                borderRadius: "1.3rem",
                boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
              }}
              alt="portugal tech visa requirements"
            ></img>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              paddingLeft: { xs: "0", md: "4rem" },
            }}
          >
            <Typography mb=".5rem" variant="h4" ml="0px important">
              Requirements
            </Typography>
            <Box sx={{ minHeight: { xs: ".5rem", md: "1rem" } }}></Box>
            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
              Along with your visa application form, you will need to provide
              the following:
            </Typography>
            <Box sx={{ minHeight: { xs: ".5rem", md: "1rem" } }}></Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Term of Responsibility from a certified company
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                National of a non-EU country not living in any EU states
                permanently
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Fulfills all applicable tax requirements.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Your criminal records from your country of residence. If you
                have lived in another country for more than one year, you have
                to get criminal records from there as well.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Must be at least 18 years old.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Must have successfully completed a Bachelor’s degree or
                equivalent (level 6), in accordance with ISCED-2011, or level 5
                (tertiary level education) with a 5 year experience in specific
                technical roles.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proficiency in Portuguese, French, English, or Spanish,
                depending on the responsibilities to be fulfilled.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Passport and previous visa copies
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Passport-size pictures (following Schengen visa picture
                guidelines.)
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proof of accommodation in Portugal
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                A document giving permission to the SEF to check your Portuguese
                criminal records.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Tvsection4;
