import React from 'react'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import questionmark from '../../Images/Icon awesome-question-circle.svg'
import './IRRstyle.css'
function IRRtooltip({titletxt="Some helper text"}) {

  return (
    <Tooltip title={titletxt} enterTouchDelay={0} leaveTouchDelay={3000}>
      <IconButton>
        <img src={questionmark} alt="" style={{width: '14px', height: '14px'}}></img>
      </IconButton>
    </Tooltip>
  )
}

export default IRRtooltip