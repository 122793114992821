import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import blog3img from "../../Images/work-in-portugal.jpg";
import blog1img from "../../Images/best-place-for-remote-work.jpg";
import blog2img from "../../Images/best-place-to-retire.jpg";
import blog4img from "../../Images/digital-nomad-visa-portugal.jpg";
import blog5img from "../../Images/portugal-digital-nomad-visa-available.jpg";
import BlogCard from "../BlogCard";

function Homesection5() {
  return (
    <Box
      sx={{
        padding: {
          xs: "2rem 1.5rem 8rem",
          md: "6.5rem clamp(3rem, 6vw, 100px) 12rem",
        },
      }}
    >
      <Box>
        <Typography variant="h4" color="var(--tblue) ">
          Recent News
        </Typography>
        <Box marginTop="1rem" sx={{ mb: { xs: "3rem", md: "0" } }}>
          <Box
            display="flex"
            padding="1rem"
            sx={{
              overflow: { xs: "scroll", md: "auto" },
              gap: { xs: "1rem", md: "1.5rem" },
              width: { xs: "100%", md: "100%" },
            }}
          >
            <BlogCard
              link="/portugal-s-much-anticipated-digital-nomad-visa-is-now-available"
              file={blog5img}
              altText="apply for portugal digital nomad visa"
              title="Much-awaited Portugal's Digital Nomad Visa is now available!"
              content={`Remote workers can now apply for Portugal's Digital Nomad Visa,
                                        which will allow them to live and work lawfully in Portugal,
                                        starting on October 30th, 2022. Candidates must make at least
                                        €2,800 per month, or four times Portugal's minimum wage, to be
                                        eligible for the program.`}
              height="auto"
            />
            <BlogCard
              link="/portugal-ranks-1st-for-remote-work"
              file={blog1img}
              title=" Portugal Ranks 1st as the best country in the world for remote
                    work."
              content={`Travel search engine Momondo has created a “travel work index” to
                    help digital nomads find the best countries for productive working
                    conditions and opportunities on vacation. Portugal ranks 1st out
                    of ten in this category, with its climate (low crime rate)
                    combined with affordable cost-of living factors making it an
                    attractive destination abroad even if you're not looking
                    specifically beyond scenery!`}
              height="auto"
              altText="best country for remote work"
            />
            <BlogCard
              link="/agreement-for-recruitment-of-indian-worker-signed-between-india-portugal"
              file={blog3img}
              altText="recruitment of indian workers"
              title="An agreement on the recruitment of Indian workers is signed
                    between Portugal and India"
              content={`India has been signing agreements with a number of countries
                    lately, and now it seems they have their eyes set on Portugal. The
                    two nations signed an accord that will allow Indian citizens to
                    work in Portugal as well - opportunities for everyone!`}
              height="auto"
            />
            <BlogCard
              link="/portugal-ranks-4th-for-best-place-to-retire"
              file={blog2img}
              altText="best country for retirement"
              title="Portugal Ranks 4th best country in the world for retirement."
              content={`Portugal ranks fourth on this list of the ten best countries for
                      retired folk. It's not hard to see why; with its warm climate,
                      beautiful scenery and rich culture it would be easy enough pack up
                      your bags once retirement hits and head off into south-west Europe
                      where you'll find yourself living happily ever after!`}
              height="auto"
            />
            <BlogCard
              link="/digital-nomad-visa-for-remote-workers"
              file={blog4img}
              altText="portugal digital nomad visa"
              title="Portugal introduces a Digital Nomad Visa for remote workers."
              content={`Are you a digital nomad looking to escape to warmer shores before winter locks down? Then, here is your chance! Portugal has announced a work visa for remote employees, which might allow digital nomads to reside and work there for up to 12 months.`}
              height="auto"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Homesection5;
