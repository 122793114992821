import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import '../../Styling/desktop/homepage.css'
import arrow2svg from '../../Images/Icon feather-arrow-right-circle-2.svg'
import pic from '../../Images/Golden Visa.jpg'
import '../../Styling/desktop/goldenvisa.css'
function gvsection1({openSetterFn}) {
    return (
        <>
            <Box className="section1 wrapper" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '0', md: '2rem 0' }, minHeight:{xs: 'unset', md:'88vh'} }} margin="auto">
                <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, paddingRight: { xs: '0', md: '4rem' } }} >
                        <Typography mb=".5rem" variant="h1" ml="0px important">Golden Visa</Typography>
                        <Typography gutterBottom variant="body2" paddingLeft="0.4rem" fontSize="1.5rem !important" fontWeight="400 !important">
                            The Portugal Golden Visa program is the most popular scheme in Europe attracting
                            investors for its flexibility and benefits. The program is a five-year residence-by-investment
                            program for non-EU nationals. Portugal is a full member of the European Union, so, the
                            residence permit allows visa-free access to all of Europe's Schengen regions - comprising 26
                            countries and only requires an average stay of seven days a year in Portugal over the
                            five-year period.
                        </Typography>
                        <Box onClick={()=>openSetterFn(true)} style={{ height: "3rem", borderRadius: '.5rem', backgroundColor: "#2F2FA2", color: 'white', cursor: 'pointer' }} sx={{margin: { xs: '1rem auto', md: '0.4rem .4rem' } }} display="inline-flex" alignItems="center" justifyContent="space-evenly" gap="1rem" padding="0 2rem">
                            <Typography variant="body1" color="white" whiteSpace="nowrap">REQUEST CONSULTATION</Typography>
                            <img src={arrow2svg}></img>
                        </Box>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, margin: { xs: '1rem auto', md: '0' } }}>
                        <img src={pic} style={{ width: '100%', height: '100%',objectFit:'cover', borderRadius: '1.3rem' , boxShadow: '4px 4px 8px #00000029' }} alt="golden visa to portugal"></img>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default gvsection1