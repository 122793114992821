import React, {useState, useContext} from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'


function CookiePopup() {
    const [cookieAccepted, setcookieAccepted]=useState(false)
    if(document.cookie.split('; ').find(ele=>ele.startsWith('visasite='))) return <></>
    const handleCookieOK=(e)=>{
        setcookieAccepted(true);
        document.cookie="visasite= cookiethatcomesfromserverisputhere; max-age= 2592000;";
    }
    return (
        <Box display={cookieAccepted?"none":"flex"} position="fixed" maxWidth="90vw" bottom="1rem" zIndex="99900" 
            sx={{flexDirection:{xs: 'column', md: 'row'}, padding: '1rem', backgroundColor: '#F0F0FF', borderRadius: '.8rem', left:{xs: '5vw', md: '.5rem'}, boxShadow: "4px 4px 20px #00000029"}}
        >
            <Box sx={{textAlign:{xs: 'center', md: 'left'}}}>
                <Typography gutterBottom style={{cursor:"pointer"}} variant="body2" color="#2F2FA2" fontWeight="700">Cookie Policy</Typography>
                <Typography variant="body2">We collect limited information about your visit. Refer our <span style={{cursor:"pointer", color: '#2F2FA2', fontWeight:"700"}}>Privacy Policy</span> for more information.</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" backgroundColor="#FFFFFF" borderRadius=".5rem" 
                textAlign="center" style={{cursor:"pointer"}}
                sx={{ml:{xs:'0', md: '1.5rem'}, mt:{xs:'1rem', md: '0'}, width:{xs:'100%', md: 'auto'}, heigth: {xs:'auto', md: '100%'}, padding: '.5rem 1rem'}}
                onClick={handleCookieOK}
            >
                <Typography variant="body2">OK</Typography>
            </Box>
        </Box>
    )
}

export default CookiePopup
