import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import arrowsvgblue from "../Images/Icon feather-arrow-right-circle-blue.svg";
import arrowsvggrey from "../Images/Icon feather-arrow-right-circle.svg";
import externallinkicon from "../Images/External Link - Blue.svg";
import articleicon from "../Images/Article Icon - Grey.svg";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import Bookconsultation from "./Common Components/Bookconsultation";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useHistory, Link } from "react-router-dom";
import blog3img from "../Images/work-in-portugal.jpg";
import blog2img from "../Images/best-place-to-retire.jpg";
import blog1img from "../Images/best-place-for-remote-work.jpg";
import { Helmet } from "react-helmet";

function Knowledgecenter() {
  const history = useHistory();
  const newsCard1 = (
    <Card
      style={{
        borderRadius: "1.3rem",
        boxShadow: "4px 4px 20px #00000029",
        minWidth: "220px",
        flex: "1",
      }}
      sx={{ mr: { xs: "0rem", md: "0" } }}
    >
      <CardActionArea sx={{ height: "100%" }}>
        <Link
          to={"/portugal-ranks-1st-for-remote-work"}
          style={{ textDecoration: "none" }}
        >
          <CardMedia
            component="img"
            height="150px"
            image={blog1img}
            alt="destination portugal"
          />
          <CardContent className="blog_card_content">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="1rem"
            >
              <Box>
                <Typography
                  variant="overline"
                  fontWeight="400"
                  style={{ lineHeight: "1.5", display: "inline-block" }}
                >
                  News & Updates
                </Typography>
                <Typography
                  mt="1rem"
                  style={{ lineHeight: "1", color: "#111111" }}
                  variant="h6"
                >
                  Portugal Ranks 1st as the best country in the world for remote
                  work.
                </Typography>
              </Box>
              <img
                src={arrowsvggrey}
                alt="arrow"
                width="18px"
                style={{ marginLeft: "8px", alignSelf: "flex-start" }}
              ></img>
            </Box>
            <Box margin="0rem 0">
              <Typography pb="8px" variant="subtitle1" className="blog_text">
                Travel search engine Momondo has created a “travel work index”
                to help digital nomads find the best countries for productive
                working conditions and opportunities on vacation. Portugal ranks
                1st out of ten in this category, with its climate (low crime
                rate) combined with affordable cost-of living factors making it
                an attractive destination abroad even if you're not looking
                specifically beyond scenery!
              </Typography>
            </Box>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
  const newsCard2 = (
    <Card
      style={{
        borderRadius: "1.3rem",
        boxShadow: "4px 4px 20px #00000029",
        minWidth: "220px",
        flex: "1",
      }}
      sx={{ mr: { xs: "0rem", md: "0" } }}
    >
      <CardActionArea sx={{ height: "100%" }}>
        <Link
          to={"/portugal-ranks-4th-for-best-place-to-retire"}
          style={{ textDecoration: "none" }}
        >
          <CardMedia
            component="img"
            height="150px"
            image={blog2img}
            alt="destination portugal"
          />
          <CardContent className="blog_card_content">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="1rem"
            >
              <Box>
                <Typography
                  variant="overline"
                  fontWeight="400"
                  style={{ lineHeight: "1.5", display: "inline-block" }}
                >
                  News & Updates
                </Typography>
                <Typography
                  mt="1rem"
                  style={{ lineHeight: "1", color: "#111111" }}
                  variant="h6"
                >
                  Portugal Ranks 4th best country in the world for retirement.
                </Typography>
              </Box>
              <img
                src={arrowsvggrey}
                alt="arrow"
                width="18px"
                style={{ marginLeft: "8px", alignSelf: "flex-start" }}
              ></img>
            </Box>
            <Box margin="0rem 0">
              <Typography pb="8px" variant="subtitle1" className="blog_text">
                Portugal ranks fourth on this list of the ten best countries for
                retired folk. It's not hard to see why; with its warm climate,
                beautiful scenery and rich culture it would be easy enough pack
                up your bags once retirement hits and head off into south-west
                Europe where you'll find yourself living happily ever after!
              </Typography>
            </Box>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
  const newsCard3 = (
    <Card
      style={{
        borderRadius: "1.3rem",
        boxShadow: "4px 4px 20px #00000029",
        minWidth: "220px",
        flex: "1",
      }}
      sx={{ mr: { xs: "0rem", md: "0" } }}
    >
      <CardActionArea sx={{ height: "100%" }}>
        <Link
          to={
            "/agreement-for-recruitment-of-indian-worker-signed-between-india-portugal"
          }
          style={{ textDecoration: "none" }}
        >
          <CardMedia
            component="img"
            height="150px"
            image={blog3img}
            alt="destination portugal"
          />
          <CardContent className="blog_card_content">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="1rem"
            >
              <Box>
                <Typography
                  variant="overline"
                  fontWeight="400"
                  style={{ lineHeight: "1.5", display: "inline-block" }}
                >
                  News & Updates
                </Typography>
                <Typography
                  mt="1rem"
                  style={{ lineHeight: "1", color: "#111111" }}
                  variant="h6"
                >
                  An agreement on the recruitment of Indian workers is signed
                  between Portugal and India
                </Typography>
              </Box>
              <img
                src={arrowsvggrey}
                alt="arrow"
                width="18px"
                style={{ marginLeft: "8px", alignSelf: "flex-start" }}
              ></img>
            </Box>
            <Box margin="0rem 0">
              <Typography pb="8px" variant="subtitle1" className="blog_text">
                India has been signing agreements with a number of countries
                lately, and now it seems they have their eyes set on Portugal.
                The two nations signed an accord that will allow Indian citizens
                to work in Portugal as well - opportunities for everyone!
              </Typography>
            </Box>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
  const card2 = (
    <Card
      onClick={() => history.push("/portugal-ranks-1st-for-remote-work")}
      style={{
        borderRadius: "1.3rem",
        boxShadow: "4px 4px 20px #00000029",
        minWidth: "220px",
        width: "100%",
      }}
      sx={{ mb: { xs: "1.5rem", md: "2.5rem" } }}
    >
      <CardActionArea>
        <CardContent>
          <Typography
            padding="0rem 0"
            variant="overline"
            component="div"
            fontWeight="400"
          >
            FORM
          </Typography>
          <Box width="2rem" style={{ float: "right" }}>
            <img src={arrowsvggrey} alt="arrow" width="70%" height="50%"></img>
          </Box>
          <Box margin="0rem 0">
            <Typography variant="h6">General Visa Application Form</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
  return (
    <>
      <Helmet>
        <title>Knowledge Center - Golden Visa for Portugal and others</title>
        <meta
          name="description"
          content="Everything you need to know about Portugal, living in Portugal, Golden Visa for Portugal, and other types of Portugal visas. Learn more here at our knowledge center."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Knowledge Center",
                item: "https://visadiscovery.com/knowledge-center",
              },
            ],
          })}
        </script>
      </Helmet>

      <Box width="100vw" maxWidth="100%" paddingTop="1rem" className="page">
        <Header />
        <Box
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          }}
          margin="auto"
        >
          <Box
            display="flex"
            width="100%"
            sx={{
              mt: { xs: "calc(12vh + 2rem)", md: "calc(12vh + 2rem)" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "calc(100% - 14rem)" },
                paddingRight: { xs: "0", md: "3rem" },
              }}
            >
              <Typography mb=".5rem" variant="h4" ml="0px important">
                Knowledge Centre
              </Typography>
              <Box
                width="100%"
                display="flex"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  mt: { xs: "1rem", md: "2rem" },
                  gap: { xs: "2rem", md: "1.5rem" },
                }}
              >
                <Box sx={{ width: { xs: "100%", md: "33%" } }}>
                  <Typography color="#2F2FA2 !important" variant="h5">
                    Portugal
                  </Typography>
                  <Box mt="1rem" width="100%">
                    <Box className="side-section1" width="100%">
                      <Link
                        to={{
                          pathname:
                            "https://www.visitportugal.com/en/sobre-portugal/biportugal",
                        }}
                        target="_blank"
                      >
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="h7"
                            fontWeight="700"
                            mr="1rem"
                            sx={{ color: "#111111" }}
                          >
                            About Portugal
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>
                      <Typography variant="body2" gutterBottom>
                        Everything you need to know about Portugal.
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="side-section2" width="100%" mt="1rem">
                      <Link
                        to={{ pathname: "https://www.visitportugal.com/en" }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Visit Portugal
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>
                      <Typography variant="body2" gutterBottom>
                        Comprehensive information on everything required to get
                        around in Portugal
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="side-section3" width="100%" mt="1rem">
                      <Link
                        to={{
                          pathname:
                            "https://atlanticbridge.com.br/wp-content/uploads/2019/09/Complete-Guide-to-Real-Estate-Investment-in-Portugal-DS.pdf",
                        }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Invest in Portugal
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>

                      <Typography variant="body2" gutterBottom>
                        A guide to investing in Real Estate in Portugal
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: "none", md: "block" } }}
                />
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ display: { xs: "block", md: "none" } }}
                />
                <Box sx={{ width: { xs: "100%", md: "33%" } }}>
                  <Typography color="#2F2FA2 !important" variant="h5">
                    Living in Portugal
                  </Typography>
                  <Box mt="1rem" width="100%">
                    <Box className="side-section2" width="100%">
                      <Link
                        to={{
                          pathname:
                            "https://www.numbeo.com/cost-of-living/country_result.jsp?country=Portugal",
                        }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Cost of Living
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>

                      <Typography variant="body2" gutterBottom>
                        Tool to understand the cost of living in different
                        cities in Portugal
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="side-section2" width="100%" mt="1rem">
                      <Link
                        to={{
                          pathname:
                            "https://www.internations.org/portugal-expats/guide/living",
                        }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Lifestyle
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>

                      <Typography variant="body2" gutterBottom>
                        Navigating your way through the Portuguese lifestyle
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="side-section3" width="100%" mt="1rem">
                      <Link
                        to={{
                          pathname:
                            "https://www.ricksteves.com/europe/portugal/festivals",
                        }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Festivals in Portugal
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>
                      <Typography variant="body2" gutterBottom>
                        List of all major festivals in Portugal
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: "none", md: "block" } }}
                />
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ display: { xs: "block", md: "none" } }}
                />
                <Box sx={{ width: { xs: "100%", md: "33%" } }}>
                  <Typography color="#2F2FA2 !important" variant="h5">
                    Learning Portuguese
                  </Typography>
                  <Box mt="1rem" width="100%">
                    <Box className="side-section1" width="100%">
                      <Link
                        to={{
                          pathname:
                            "https://www.duolingo.com/course/pt/en/Learn-Portuguese",
                        }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Self Learning
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>
                      <Typography variant="body2" gutterBottom>
                        Learn on an app.
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="side-section2" width="100%" mt="1rem">
                      <Link
                        to={{
                          pathname:
                            "https://www.udemy.com/course/complete-portuguese-course/",
                        }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Online Course
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>
                      <Typography variant="body2" gutterBottom>
                        A beginners course
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="side-section3" width="100%" mt="1rem">
                      <Link
                        to={{
                          pathname:
                            "https://preply.com/en/online/portuguese-tutors",
                        }}
                        target="_blank"
                        style={{ color: "#111111" }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography variant="h7" fontWeight="700" mr="1rem">
                            Learn with a tutor
                          </Typography>
                          <img src={externallinkicon} alt="arrow"></img>
                        </Box>
                      </Link>

                      <Typography variant="body2" gutterBottom>
                        Private lessons with a native Portuguese teacher.
                      </Typography>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="#898989 !important"
                          whiteSpace="nowrap"
                          mr="8px"
                        >
                          2 min read
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Box display="flex" ml="8px">
                          <Typography
                            variant="body2"
                            color="#898989 !important"
                          >
                            Article
                          </Typography>
                          <img
                            src={articleicon}
                            alt="arrow"
                            style={{ marginLeft: ".5rem", width: "1rem" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                mt="2rem"
                mb="1rem"
                paddingRight="1rem"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">Latest News</Typography>
                <Box
                  style={{ float: "right", cursor: "pointer" }}
                  sx={{
                    width: "75px",
                  }}
                >
                  <a href="/news" style={{ display: "flex", gap: "6px" }}>
                    <Typography variant="body2" color="#2F2FA2 !important">
                      View All
                    </Typography>
                    <img
                      src={arrowsvgblue}
                      alt="arrow"
                      style={{ width: "1rem" }}
                    ></img>
                  </a>
                </Box>
              </Box>
              <Box
                display="flex"
                padding="1rem"
                sx={{
                  overflow: { xs: "scroll", md: "auto" },
                  gap: { xs: "1rem", md: "1.5rem" },
                  width: { xs: "100%", md: "100%" },
                }}
              >
                {newsCard3}
                {newsCard1}
                {newsCard2}
                {/* {card} */}
              </Box>
              <Box mt="2rem"></Box>
              {/* 
                            {card2}
                            {card2}
                            {card2} */}
            </Box>
            <Box sx={{ mb: { xs: "3rem", md: "3rem" } }}>
              <Bookconsultation fixed="fixed" />
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default Knowledgecenter;
