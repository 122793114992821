import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function Svsection6({ refProp, bgColor }) {
    return (
        <>
            <Box ref={refProp} width="100%" backgroundColor={bgColor? bgColor: "#F5F5F5"}>
                <Box className="section3 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                    <Box width="100%" mt="2rem" >
                        <Typography mb="1rem" variant="h4" ml="0px important">FAQs</Typography>
                        <Box sx={{
                            height: { xs: 'auto'}, width: { xs: '100%'},
                            mb: { xs: "3rem", lg: "1rem" }
                        }} className="faq-column" >
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">What is the StartUp Visa incubation fee?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    The incubation fee is €175,000, of which €25,000 are used as share capitalization in the
                                    startup and will also pay for the company's required services.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">What is the incubation period?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    The incubation has a 3-year duration, starting from the approval of the candidate’s
                                    Residence Permit application. In case of denial, the incubation will not begin and the
                                    incubation fees will be returned to the candidate.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important"> How many individuals can apply for the StartUp Visa program, per startup?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    The StartUp Visa allows up to 5 entrepreneurs per project.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Can I apply for the StartUp Visa with multiple projects?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    No. One can only apply for one project at a time.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">When should the startup company be set up in Portugal?
                                </Typography>
                                <Typography variant="body2" mb="1rem">
                                    The startup company will be set up during the incubation contract and must be established
                                    by the end of this contract.
                                </Typography>
                            </Box>
                        </Box>


                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Svsection6
