import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import logoImg from "../../Images/Visa Discovery Logo desktop.png";
import bgImg from "../../Images/vdt-splash-screen.webp";
import arrowsvg from "../../Images/Icon feather-arrow-right-circle-blue.svg";
import LaunchIcon from "@mui/icons-material/Launch";
import Footer from "../Homepage/Footer";
import LanguageSelector from "../Common Components/LanguageSelector";
import { Helmet } from "react-helmet";

function Vdtlanding() {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>
          Discover the Best Portugal Visa From India - Visa Discovery
        </title>
        <meta
          name="description"
          content="Discover the best Portugal visa from India, Sri Lanka, Pakistan, Bangladesh, Nepal, and other countries with our easy Portugal visa discovery tool."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Visa Discovery Tool",
                item: "https://visadiscovery.com/visa-discovery-tool",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box
        style={{
          display: "flex",
          width: "100vw",
          maxWidth: "100%",
          backgroundColor: "#F0F0FF",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%" },
            padding: { xs: "2rem 1.5rem 0rem", md: "3rem 4rem 0rem" },
          }}
        >
          <Box
            width="100%"
            height="3.5rem"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              onClick={() => history.push("/")}
              style={{ maxWidth: "80%", cursor: "pointer" }}
              src={logoImg}
              alt="visas for portugal"
              width="auto"
              height="100%"
            ></img>
            <Box
              style={{ cursor: "pointer" }}
              display="flex "
              width="30%"
              height="100%"
              alignItems="center"
              justifyContent="flex-end"
            >
              <LanguageSelector />
            </Box>
          </Box>
          <Box
            width="100%"
            marginTop="2.5rem"
            marginBottom="3rem"
            textAlign="center"
          >
            <Typography gutterBottom variant="h5">
              Visa Discovery Tool
            </Typography>
          </Box>
          <Box width="100%" marginBottom="2rem" textAlign="center">
            <Typography
              gutterBottom
              variant="h1"
              style={{ fontWeight: "normal" }}
            >
              Discover the best{" "}
              <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Portuguese Visa
              </span>{" "}
              for you
            </Typography>
          </Box>
          <Box width="100%" marginBottom="2rem" textAlign="center">
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Answer 3 simple questions and discover the ideal visa type for
              your Portugal Visa Application
            </Typography>
          </Box>
          <Box
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/visa-discovery-questions")}
            p="12px 20px"
            backgroundColor="#2F2FA2"
            width="fit-content"
            borderRadius="12px"
            m="0 auto 2rem"
            display="flex"
            alignItems="center"
            gap="10px"
          >
            <Typography variant="body2" style={{ color: "white" }}>
              Get Started
            </Typography>
            <LaunchIcon style={{ fill: "white" }}></LaunchIcon>
          </Box>
          <Box
            width="100%"
            minHeight="320px"
            style={{
              backgroundImage: `url(${bgImg})`,
              backgroundSize: "cover",
            }}
          >
            {/* <img src={bgImg} alt='' style={{width: '100%', height: '100%', objectFit: 'cover', minHeight: '320px'}}></img> */}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Vdtlanding;
