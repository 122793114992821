import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import arrowsvg from '../../Images/Icon feather-arrow-right-circle-blue.svg'
import bgImage from '../../Images/pexels-canva-studio-3277806.svg'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import logoImg from '../../Images/Visa Discovery Logo desktop.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, Redirect } from 'react-router-dom'
import '../../Styling/desktop/vdtquestions.css'
import Servicecard from '../Services/Servicecard'
import Footer from '../Homepage/Footer'
import LanguageSelector from '../Common Components/LanguageSelector'
function Vdt4(props) {
    // const ansQ1 = "Tourism or Leisure";const ansQ2 = "0 to 3 months";const ansQ3 = "Seasonal Work" 
    const history=useHistory()
    const [suggestionArr, setsuggestionArr]=useState(null);
    useEffect(()=>{
        document.title="Results: Visa Discovery Tool | Visa Discovery"
        if(ansQ3){
            if(ansQ3==="Accompanying family member undergoing medical treatment purposes"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E7 Accompanying family member undergoing medical treatment purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Amateur Sports Visa"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E5 - Amateur Sports Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Business/Conference"){
                let pathname="/portugal-short-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"Seasonal Work - Short stay",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Commitments pursuant to international agreements or conventions"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E6 - Commitments pursuant to international agreements or conventions",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Course in Educational or professional training institution"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E9 - Course in Educational or professional training institution",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Cultural Visa"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D3 - Cultural Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Entrepreneurship"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D2 - Entrepreneurship",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Exceptional and duly justified cases"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"Exceptional and dully justified cases",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Family reunification purposes"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D6 - Family reunification purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="High School Student Exchange"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D4 - High School Student Exchange",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Higher Education Visa"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E4 - Higher Education Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Higher Education Teaching"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D3 - Higher Education Teaching",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Highly qualified subordinate activity"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D3 - Highly qualified subordinate activity",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Highly Qualified Activity Visa"&&ansQ1==="Residency"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D3 - Highly Qualified Activity Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Highly Qualified Activity Visa"&&ansQ1==="1 Year or less"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E4 - Highly Qualified Activity Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Independent work Visa"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E3 - Independent work Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Intra Corporate Transfer"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E2 - Intra Corporate Transfer",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Internship"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D4 - Internship",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Independent work purposes"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D2 - Independent work purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Invest and Live in Portugal"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D9 - Golden Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Invest in Environmental Projects and Live in Portugal"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D9 - Green Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Retirement Purposes"||ansQ3==="Religious Purposes"||ansQ3==="People living out of individual revenue"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D7 - Retirement Purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Religious Purposes"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D7 - Religious Purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="People living out of individual revenue"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D7 - People living out of individual revenue",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Research"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D4 - Research",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Medical treatment purposes"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E1 Medical treatment purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Professional Activity Visa"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E4 - Professional Activity Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Seasonal Work"){
                let pathname="/portugal-short-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"Seasonal Work - Short stay",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Seasonal Work (more than 90 days)"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E8 - Seasonal Work (more than 90 days)",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Scientific Research Visa"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E4 - Scientific Research Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Study Programs Visa"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E6 - Study Programs Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Student Exchange"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E6 - Student Exchange",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Subordinate work purposes"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D1 - Subordinate work purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Study"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D4 - Study",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Start or Run a company in Portugal"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D2 - Work Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Tourist"){
                let pathname="/portugal-short-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"Tourist Visa - Short stay",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Transit Visa"){
                let pathname="/portugal-short-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"Transit Visa - Short stay",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Temporary stay visa for transfer of workers purposes for state parties to the WTO, for provision of services or professional training"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E2 - Temporary stay visa for transfer of workers purposes for state parties to the WTO, for provision of services or professional training",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Techonology Work"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D2 - Tech Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Visiting Friends/Family"){
                let pathname="/portugal-short-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"Tourist Visa - Short stay",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Unpaid Internship"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E6 - Unpaid Internship",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Work Training"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E6 - Work Training",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Volunteer Work"){
                let pathname="/portugal-temporary-stay-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"E6 - Volunteer Work",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Volunteer work purposes"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D4 - Volunteer work purposes",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }else if(ansQ3==="Work and Travel in Portugal"){
                let pathname="/portugal-residency-visa"
                let captiontxt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                let obj={
                    titletxt:"D2 Nomad Visa",
                    pathname,
                    captiontxt
                }
                setsuggestionArr([obj]);
            }
        }
    },[])
    if(!props?.location?.state) return <Redirect to="/visa-discovery-questions"></Redirect>
    const { ansQ1, ansQ2, ansQ3} = props?.location?.state;
    const responses = [ansQ1, ansQ2, ansQ3];
    console.log(props.location.state);
    const responsesblock = (
        <Box width="100%">
            <Box width="100%" height="auto" display="flex" flexDirection="row" flexWrap="wrap" gap="1rem">
                {
                    responses.map((option, index) => (
                        <Box
                            key={index} 
                            border="0.5px solid #898989" borderRadius="8px" padding="1rem" sx={{flexGrow: "1"}}
                            display="flex" alignItems="center" justifyContent="center"
                            textAlign="center"
                        >
                            <Typography variant="body1" fontWeight="normal">{option}</Typography>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
    
    return (
        <>
        <Box style={{ display: "flex", width: "100vw", maxWidth: "100%" }} sx={{flexDirection: {xs: 'column', md: 'row'}, alignItems:{xs: 'center', md: 'initial'}}}>
            <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem', md: '3rem 4rem' } }}>
                <Box width="100%" height="3.5rem" display="flex" alignItems="center" justifyContent="space-between">
                    <img onClick={() => history.push('/')} style={{ maxWidth: "80%", cursor: 'pointer' }} src={logoImg} alt="visas for portugal" width="auto" height="100%"></img>
                    <Box style={{ cursor: 'pointer' }}  display="flex " width="30%" height="100%" alignItems="center" justifyContent="flex-end">
                        <LanguageSelector />
                    </Box>
                </Box>
                <Box width="100%" marginTop="2.5rem" marginBottom="2rem">
                    <Typography gutterBottom variant="h5">Visa Discovery Tool</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography  variant="overline">YOUR SELECTION</Typography>
                    <Box display="inline-flex" gap=".5rem" alignItems="center" style={{cursor: 'pointer'}} onClick={()=>history.push("/visa-discovery-questions")}>
                        <ArrowBackIcon style={{ fill: "#898989", fontSize: "1rem" }} />
                        <Typography  variant="caption" style={{ textTransform: "none", color: "#898989" }}>Change Selection </Typography>
                    </Box>
                </Box>
                {responsesblock}
                <Typography mt="2rem" mb="1rem" variant="body1">Based on your answers, here’s the best visa for you -</Typography>
                {
                    suggestionArr?.map(obj=>{
                        console.log("required obj is", obj);
                        return (
                        <Servicecard
                            title={obj.titletxt}
                            caption={obj.captiontxt}
                            path={obj.pathname}
                        />
                        )                        
                    })
                }
                
            </Box>
            <Box className="vdt-side-box" sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem' }, height: {xs: 'auto', md: '100vh'} }} backgroundColor="#F0F0FF" boxShadow="inset 4px 3px 6px #00000029" style={{position: 'sticky', top: '0px'}}>
                <Box className="vdt-img" margin="auto"  width="100%" sx={{padding:{xs: '0 0 1rem 0', md: '0px 60px 1rem'}}}>
                    <img src={bgImage} width="100%" height="auto" alt="visa responses"></img>
                </Box>
                <Box textAlign="center">
                    <Typography gutterBottom variant="h6">Tension free Visa Application</Typography>
                    <Box width="7.5rem" height="3rem" backgroundColor="white" border="0.1px solid #898989" borderRadius="5px" margin="auto" display="flex" alignItems="center" justifyContent="center">
                        <Typography>Know More</Typography>
                        <OpenInNewIcon fontSize="0.5rem" />
                    </Box>
                </Box>
            </Box>
        </Box>
        <Footer/>
        </>
    )
}

export default Vdt4
