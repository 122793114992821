import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Header from '../Homepage/Header'
import Footer from '../Homepage/Footer'
import '../../Styling/desktop/contactus.css';
import Shortstaycard2 from '../Common Components/Shortstaycard2';
function Services() {
    const history = useHistory();
    useEffect(()=>{
        document.title="All Services | Visa Discovery"
      },[])
    return (
        <Box width="100vw" maxWidth="100%" paddingTop="1rem">
            <Header />
            <Box className="services-cover-container" mt="-1rem" width="100%" height="45vh" textAlign="center">
                <Typography variant="h4" color="white" paddingTop="30vh ">Services</Typography>
            </Box>
            <Box sx={{ width: { xs: 'calc(100vw - 3rem)', md: 'calc(100vw - 12rem)' }, mt: '2rem', mb: '2rem' }} margin="auto">
                <Typography mb="1rem" variant="h4" ml="0px important">List of Services</Typography>
                <Box display="flex" flexWrap='wrap' padding="1rem" sx={{ overflow: { xs: 'scroll', md: 'auto' }, gap: { xs: '1.5rem', md: '1.5rem' }, flexDirection: { xs: 'column', md: 'row' } }} width="100%">
                    <Shortstaycard2
                        title="Learn Portuguese"
                        description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                        path='/services1'
                    />
                    <Shortstaycard2
                        title="Insurance"
                        description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                        path='/services2'
                    />
                    <Shortstaycard2
                        title="Visa Application Agents"
                        description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                        path='/services3'
                    />
                    <Shortstaycard2
                        title="Foreign Exchange"
                        description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                        path='/services4'
                    />
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}

export default Services
