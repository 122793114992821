import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/golden visa requirements.jpg";
import "../../Styling/desktop/goldenvisa.css";
import tick from "../../Images/Tick.svg";
function Greenvsection4({ bgColor, refProp }) {
  return (
    <Box width="100vw" maxWidth="100%" backgroundColor={bgColor} ref={refProp}>
      <Box
        className="section4 wrapper"
        sx={{
          width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          padding: { xs: "1rem 0", md: "2rem 0" },
        }}
        margin="auto"
      >
        <Box
          width="100%"
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          mt="2rem"
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              margin: { xs: "0 auto 2rem", md: "0" },
            }}
          >
            <img
              src={rect35}
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "530px",
                objectFit: "cover",
                borderRadius: "1.3rem",
                boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
              }}
              alt="green visa in europe"
            ></img>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              paddingLeft: { xs: "0", md: "4rem" },
            }}
          >
            <Typography mb=".5rem" variant="h4" ml="0px important">
              Requirements
            </Typography>
            <Box sx={{ minHeight: { xs: ".5rem", md: "1rem" } }}></Box>
            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
              To qualify for the Green Visa Program, the options are -
            </Typography>
            <Box sx={{ minHeight: { xs: ".5rem", md: "1rem" } }}></Box>
            <Box>
              <Box display="flex" alignItems="center">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  1
                </Box>
                <Typography variant="h6">Stay Requirements </Typography>
              </Box>
              <Box ml="3rem" mt=".2rem">
                <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                  Once you obtain your Green Visa;{" "}
                  <span style={{ fontWeight: "bold" }}>Portugal</span> requires
                  you to stay an average of seven days a year in the country to
                  uphold your Green Visa status. If your Green Visa permit is
                  valid for a period of two years, Portugal requires you to
                  spend a total of 14 days in the country within the two-year
                  period.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ minHeight: { xs: ".5rem", md: "1rem" } }}>
              <Box display="flex" alignItems="center">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  2
                </Box>
                <Typography variant="h6">Citizenship Requirements</Typography>
              </Box>
              <Box ml="3rem" mt=".2rem">
                <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                  A Green Visa is a path to residency in a country.  Residency
                  is a path to citizenship, provided that you meet the specific
                  naturalization requirements of the country.
                </Typography>
              </Box>
              <Box ml="1.5rem">
                <Box display="flex" alignItems="center" margin=".5rem 0">
                  <img src={tick} style={{ width: ".8rem" }}></img>
                  <Typography ml="1rem" variant="body2">
                    Five years of residing an average of seven days per year in
                    Portugal
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" margin=".5rem 0">
                  <img src={tick} style={{ width: ".8rem" }}></img>
                  <Typography ml="1rem" variant="body2">
                    A2 Portuguese Language test
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" margin=".5rem 0">
                  <img src={tick} style={{ width: ".8rem" }}></img>
                  <Typography ml="1rem" variant="body2">
                    No unpaid taxes in Portugal
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" margin=".5rem 0">
                  <img src={tick} style={{ width: ".8rem" }}></img>
                  <Typography ml="1rem" variant="body2">
                    Clean criminal record from home country
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Typography mb="1.5rem" display="inline-block" variant="body2">*The threshold will be reduced by 20% should the funds be committed to a low population density area,
                            defined as less than 100 inhabitants per km² or with a GDP per capita below 75% of the national average.</Typography> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Greenvsection4;
