export const TableRows=[
    {
        option: 'Invest in Real Estate in Portugal',
        value: '€500,000 (minimum value)'
    },
    {
        option: 'Invest in Real Estate in a low-density area in Portugal ',
        value: '€400,000 (minimum value)'
    },
    {
        option: 'Invest in Real Estate in Portugal; provided that the Property is at least 30-years-old and that it will be Rehabilitated',
        value: '€350,000 (minimum value)'
    },
    {
        option: 'Invest in Real Estate in a low-density area in Portugal; provided that the Property is at least 30-years-old and that it will be Rehabilitated',
        value: '€350,000 (minimum value)'
    },
    {
        option: 'Capital Transfer',
        value: '€1.5 million'
    },
    {
        option: 'Create a company that employs Portuguese citizens with full-time jobs',
        value: '10 employees with full time jobs'
    },
    {
        option: 'Invest in a Qualified Fund in Portugal',
        value: '€500,000 (minimum value)'
    },
    {
        option: 'Invest in National Heritage, Arts, and Culture in Portugal',
        value: '€250,000 (minimum value)'
    },
    {
        option: 'Invest in R&D in Portugal',
        value: '€500,000 (minimum value)'
    },
    {
        option: 'Investment into an existing Portugal-registered business',
        value: '€500,000 (minimum value)'
    },


]