import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/green visa/Green Visa - Benefits.jpg";
import TickBox from "../../Images/TickBox.svg";

function Greenvsection3({ bgColor, refProp }) {
  return (
    <>
      <Box width="100%" backgroundColor={bgColor} ref={refProp}>
        <Box
          className="section3 wrapper"
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            padding: { xs: "1rem 0", md: "2rem 0" },
          }}
          margin="auto"
        >
          <Box
            width="100%"
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            mt="2rem"
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                paddingRight: { xs: "0", md: "4rem" },
              }}
            >
              <Typography mb=".5rem" variant="h4" ml="0px important">
                Benefits of the Green Visa
              </Typography>
              {/* <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Typography> */}
              <Box sx={{ minHeight: { xs: ".8rem", md: "1rem" } }}></Box>
              <Box display="flex" alignItems="cemter" mb=".5rem">
                <img src={TickBox} style={{ width: "1.2rem" }}></img>
                <Typography variant="body2" paddingLeft="1rem">
                  Visa free travel in the Schengen Area for the entire duration
                  of your visa period.
                </Typography>
              </Box>
              <Box display="flex" alignItems="cemter" mb=".5rem">
                <img src={TickBox} style={{ width: "1.2rem" }}></img>
                <Typography variant="body2" paddingLeft="1rem">
                  Visa free travel to{" "}
                  <span style={{ fontWeight: "bold" }}>188 countries</span> (as
                  of 2021)
                </Typography>
              </Box>
              <Box display="flex" alignItems="cemter" mb=".5rem">
                <img src={TickBox} style={{ width: "1.2rem" }}></img>
                <Typography variant="body2" paddingLeft="1rem">
                  The right to live, study and work in Portugal.
                </Typography>
              </Box>
              <Box display="flex" alignItems="cemter" mb=".5rem">
                <img src={TickBox} style={{ width: "1.2rem" }}></img>
                <Typography variant="body2" paddingLeft="1rem">
                  Liberal taxation system - Non Habitual Resident program
                </Typography>
              </Box>
              <Box display="flex" alignItems="cemter" mb=".5rem">
                <img src={TickBox} style={{ width: "1.2rem" }}></img>
                <Typography variant="body2" paddingLeft="1rem">
                  Low visitation requirement in Portugal - 7 days every year.
                </Typography>
              </Box>
              <Box display="flex" alignItems="cemter" mb=".5rem">
                <img src={TickBox} style={{ width: "1.2rem" }}></img>
                <Typography variant="body2" paddingLeft="1rem">
                  Great standard of living and high security.
                </Typography>
              </Box>
              <Box display="flex" alignItems="cemter" mb=".5rem">
                <img src={TickBox} style={{ width: "1.2rem" }}></img>
                <Typography variant="body2" paddingLeft="1rem">
                  Access to local cuisines, wines and a rich culture.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: { xs: "2rem auto 0", md: "0" },
              }}
            >
              <img
                src={rect35}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "320px",
                  objectFit: "cover",
                  borderRadius: "1.3rem",
                  boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
                }}
                alt="portugal green visa benefits"
              ></img>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Greenvsection3;
