import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import arrowsvg from '../../Images/Icon feather-arrow-right-circle-blue.svg'
import logoImg from '../../Images/Visa Discovery Logo desktop.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import '../../Styling/desktop/vdtquestions.css'
import Footer from '../Homepage/Footer';
import IRRresults from './IRRresults';
import LineChart from './LineChart';
import IRRTable from './IRRTable';
import Numericinput from './Numericinput'
import Button from './Button';
import useVCFundCalculator from './utils/useVCFundCalculator'
import useRealEstateFundCalculator from './utils/useRealEstateFundCalculator'
import LanguageSelector from '../Common Components/LanguageSelector'
import IRRtooltip from './IRRtooltip'
function IRRToolMain() {
    const history = useHistory();
    const [initailVCFund, setinitialVCFund] = useState(500000);
    const [numofApplicant, setnumofApplicant] = useState(1);
    const [numofFamilymembers, setnumofFamilymembers] = useState(1);
    const [Exityears, setExityears] = useState(10);
    const [VCFundCAGRpercent, setVCFundCAGRpercent] = useState(7);
    const [VCFundIRR, setVCFundIRR] = useState(null);
    const [VCfundnetGrowthArr, setVCfundnetGrowthArr] = useState([]);
    const [initailRealEstateFund, setinitailRealEstateFund] = useState(500000);
    const [REfundIRR, setREfundIRR] = useState(null);
    const [REfundnetGrowthArr, setREfundnetGrowthArr] = useState([]);
    const [REGrowthRate, setREGrowthRate] = useState(3);
    const [RERentalYield, setRERentalYield] = useState(3.5);

    useVCFundCalculator(initailVCFund, numofApplicant, numofFamilymembers, Exityears, VCFundCAGRpercent, setVCFundIRR, setVCfundnetGrowthArr)
    useRealEstateFundCalculator(initailRealEstateFund, numofApplicant, numofFamilymembers, Exityears, REGrowthRate, RERentalYield, setREfundIRR, setREfundnetGrowthArr)
    useEffect(()=>{
        document.title="IRR Comparison Tool | Return on investment in Real Estate Vs. VC Fund | Visa Discovery"
      },[])
    return (
        <>
            <Box style={{ display: "flex", width: "100vw", maxWidth: "100%" }} sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'initial' } }}>
                <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem', md: '3rem 4rem' } }}>
                    <Box width="100%" height="3.5rem" display="flex" alignItems="center" justifyContent="space-between">
                        <img onClick={() => history.push('/')} src={logoImg} alt="visas for portugal" width="auto" height="100%" style={{ maxWidth: "80%", cursor: 'pointer' }}></img>
                        <Box style={{ cursor: 'pointer' }} display="flex " width="30%" height="100%" alignItems="center" justifyContent="flex-end">
                            <LanguageSelector />
                        </Box>
                    </Box>
                    <Box width="100%" marginTop="2.5rem" marginBottom="2rem">
                        <Typography gutterBottom variant="h5">Real Estate Vs. VC Fund - IRR Analysis</Typography>
                    </Box>
                    <Typography mb='10px' style={{ lineHeight: '1' }} display='block' variant="overline">INVESTMENT VALUE</Typography>
                    <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='1rem'>
                        <Box>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <Typography gutterBottom variant="overline" style={{ textTransform: 'initial' }}>VC Fund</Typography>
                                <IRRtooltip titletxt="Amount to invest in a VC Fund"/>
                            </Box>
                            <Numericinput numVal={initailVCFund} setNumval={setinitialVCFund} min={500000} />
                        </Box>
                        <Box>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <Typography gutterBottom variant="overline" style={{ textTransform: 'initial' }}>Real Estate</Typography>
                                <IRRtooltip titletxt="Amount to invest in Real Estate"/>
                            </Box> 
                                <Numericinput numVal={initailRealEstateFund} setNumval={setinitailRealEstateFund} min={500000} />
                        </Box>
                    </Box>
                    <Typography mt='2rem' mb='16px' style={{ lineHeight: '1' }} display='block' variant="overline">APPLICATION INFORMATION</Typography>
                    <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='1rem'>
                        <Button BtnName='Number of Applicants' value={numofApplicant} setValue={setnumofApplicant} min={1} tooltiptxt="Total primary applicants for the Golden Visa"/>
                        <Button BtnName='Number of Family Members' value={numofFamilymembers} setValue={setnumofFamilymembers} tooltiptxt="Total dependents & family members for the application"/>
                        <Button BtnName='Exit Year' value={Exityears} setValue={setExityears} min={7} tooltiptxt="The year in which you'd like to compare an exit from the investments"/>
                    </Box>
                    <Typography mt='2rem' mb='16px' style={{ lineHeight: '1' }} display='block' variant="overline">INVESTMENT INFORMATION - REAL ESTATE</Typography>
                    <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='1rem'>
                        <Button BtnName='Real Estate Growth Rate' step={0.5} value={REGrowthRate} setValue={setREGrowthRate} tooltiptxt="The rate at which you assume Real Estate will grow year on year"/>
                        <Button BtnName='Rental Yield' step={0.5} value={RERentalYield} setValue={setRERentalYield} tooltiptxt="Amount of rent possible from the investment (as a percentage of the total value)"/>
                    </Box>
                    <Typography mt='2rem' mb='16px' style={{ lineHeight: '1' }} display='block' variant="overline">INVESTMENT INFORMATION - VC FUND</Typography>
                    <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='1rem'>
                        <Button BtnName='VC Fund CAGR' step={0.5} value={VCFundCAGRpercent} setValue={setVCFundCAGRpercent} tooltiptxt="Total growth of the VC Fund - compounded annually"/>
                    </Box>
                </Box>
                <Box className="cost-side-box" sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem' }, height: { xs: 'auto', } }} backgroundColor="#F0F0FF" boxShadow="inset 4px 3px 6px #00000029" style={{ position: 'sticky', top: '0px' }}>
                    <Box>
                        <Typography variant='h5'>Results</Typography>
                    </Box>
                    <Box mt="1rem">
                        <IRRresults VCFundIRR={VCFundIRR} REfundIRR={REfundIRR} />
                        <Box width='100%' backgroundColor='white' opacity='0.8' borderRadius='12px' mt='1rem' sx={{ padding: { xs: '1rem', sm: '1.5rem' } }}>
                            <Typography variant='body1' gutterBottom style={{ fontWeight: 'normal', fontSize: '1.5rem', color: 'black' }}>Net Growth - VC Fund Vs. Real Estate</Typography>
                            <LineChart Exityears={Exityears} VCfundnetGrowthArr={VCfundnetGrowthArr} REfundnetGrowthArr={REfundnetGrowthArr} />
                        </Box>
                        <Box width='100%' backgroundColor='white' opacity='0.8' borderRadius='12px' mt='1rem' sx={{ padding: { xs: '1rem', sm: '1.5rem' } }}>
                            <Typography variant='body1' gutterBottom style={{ fontWeight: 'normal', fontSize: '1.5rem', color: 'black' }}>Net Growth - VC Fund Vs. Real Estate</Typography>
                            <IRRTable VCfundnetGrowthArr={VCfundnetGrowthArr} REfundnetGrowthArr={REfundnetGrowthArr} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default IRRToolMain
