export const SEF_COST_PER_APPLICANT = 537.2;
export const SEF_COST_PER_DEPENDENT = 83.1;
export const ISSUE_NEW_PERMIT_COSTEACH = 5274.4;
export const RENEWAL_RESIDENCE_PERMIT_APPLICANT = 5274.4; //Issue of the new residence permit Card
export const RENEWAL_RESIDENCE_PERMIT_DEPENDENT = 2668.2; //Renewal residence permit Card
export const VCFUND_PERFORMANCE_FEE_PERCENT = 15; 
export const VCFUND_TOTAL_INITIAL_FEE_PERCENT = 5; 
export const VCFUND_TOTAL_OUTGOING_FEE_PERCENT = 1.5; 
export const REAL_ESTATE_FUND_TOTAL_OUTGOING_FEE_PERCENT = 2.18; //Renewal residence permit Card
export const REAL_ESTATE_IMT_AND_STAMP_DUTY_PERCENT = 6.408; //
export const REAL_ESTATE_REGISTRY_AND_OTHER_COST = 1350; //350 + 650 + 350 
export const REAL_ESTATE_AGENT_FEE_PERCENT = 5; //350 + 650 + 350 
export const REAL_ESTATE_TOTAL_INITIAL_FEE_PERCENT = 7.678;
export const REAL_ESTATE_CAPITAL_GAIN_TAX_PERCENT = 28;
