import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/green visa/Green Visa - Investment.jpg";
import "../../Styling/desktop/goldenvisa.css";

function InvestmentOptions({ bgColor, refProp }) {
  return (
    <>
      <Box width="100%" backgroundColor={bgColor} ref={refProp}>
        <Box
          className="section3 wrapper"
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            padding: { xs: "1rem 0", md: "2rem 0" },
          }}
          margin="auto"
        >
          <Box
            width="100%"
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            mt="2rem"
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: { xs: "0 auto 2rem", md: "0" },
              }}
            >
              <img
                src={rect35}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "350px",
                  objectFit: "cover",
                  borderRadius: "1.3rem",
                  boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
                }}
                alt="green visa investment"
              ></img>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                paddingLeft: { xs: "0", md: "4rem" },
              }}
            >
              <Typography mb=".5rem" variant="h4" ml="0px important">
                Investment Options
              </Typography>
              <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                The Green Visa is a new investment category for Portugal Golden
                Visa. Green Visa requires a minimum investment of{" "}
                <span style={{ fontWeight: "bold" }}>€500,000</span> in
                environmental projects. Such projects can range from
                non-intensive organic agriculture and ecotourism schemes, to
                renewable energies and other environmental initiatives.
              </Typography>
              <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                Specific areas of investment include:
              </Typography>
              <Box paddingLeft="1rem">
                <Typography
                  className="greenv-invest-options"
                  variant="body2"
                  mb="1rem"
                >
                  Eco-tourism
                </Typography>
                <Typography
                  className="greenv-invest-options"
                  variant="body2"
                  mb="1rem"
                >
                  Organic farming
                </Typography>
                <Typography
                  className="greenv-invest-options"
                  variant="body2"
                  mb="1rem"
                >
                  Renewable energy
                </Typography>
                <Typography
                  className="greenv-invest-options"
                  variant="body2"
                  mb="1rem"
                >
                  Carbon neutral projects
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default InvestmentOptions;
