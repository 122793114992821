import React from 'react';
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Skeleton from '@mui/material/Skeleton';

function IRRresults({VCFundIRR, REfundIRR}) {
    return <>
        <Box sx={{ padding: { xs: '1rem', sm: '1.5rem' } }} width='100%' backgroundColor='white' opacity='0.8' borderRadius='12px' >
            <Typography variant='h6' style={{ fontWeight: "normal" }} mb='1rem'>IRR</Typography>
            <Box width='100%' display='flex' sx={{ gap: { xs: '1rem', sm: '1.5rem' } }}>
                {
                    VCFundIRR? <Box sx={{ flexGrow: '1', height: { xs: '5rem', md: '6rem' }, padding: { xs: '10px', sm: '1rem' } }} backgroundColor='white' border='1px solid #898989' borderRadius='12px'>
                    <Typography variant='body1' gutterBottom style={{ fontWeight: 'normal' }}>VC Fund</Typography>
                    <Box style={{ float: 'right' }}>
                        <Typography variant='body1' style={{ fontWeight: '700', fontSize: '1.5rem', color:VCFundIRR>REfundIRR?'#2F2FA2':'black' }}>{VCFundIRR}%</Typography>
                    </Box>
                </Box>: <Skeleton variant="rectangular" sx={{flexGrow: '1', height: { xs: '5rem', md: '6rem' }, border: '1px solid #898989', borderRadius:'12px'}} />
                }
               
                <Box sx={{ flexGrow: '1', height: { xs: '5rem', md: '6rem' }, padding: { xs: '10px', sm: '1rem' } }} backgroundColor='white' border='1px solid #898989' borderRadius='12px'>
                    <Typography variant='body1' gutterBottom style={{ fontWeight: 'normal' }}>Real Estate</Typography>
                    <Box style={{ float: 'right' }}>
                        <Typography variant='body1' style={{ fontWeight: '700', fontSize: '1.5rem', color: VCFundIRR>REfundIRR?'black':'#2F2FA2' }}>{REfundIRR}%</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>;
}

export default IRRresults;
