import React from 'react'
import '../../Styling/desktop/goldenvisa.css'
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import arrowwhitesvg from '../../Images/Icon feather-arrow-right-circle-blue.svg'
function Requestconsultation({openSetterFn}) {
    return (
        <Box  onClick={()=>openSetterFn(true)} className="req-consultation-sticky-div" 
        sx={{cursor: 'pointer', display: {xs: 'inline-flex', md: 'inline-flex'}, width: {xs: '10rem', sm: '18rem'},
         left:{xs: 'calc(100vw - 10rem - 1.5rem)', sm: 'calc(100vw - 18rem - 1.5rem)',  md: '70%',lg: '76%'}, top:{xs: '75vh', sm: '85vh'},
          alignItems:{xs: 'flex-start', sm: 'center'}}}>
            <Typography variant="h6" fontWeight="700" color="#2F2FA2 !important">REQUEST CONSULTATION</Typography>
            <img src={arrowwhitesvg} alt="arrow svg" style={{width: 'auto', height: 'auto'}}></img>
        </Box>
    )
}

export default Requestconsultation
