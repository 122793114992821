import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import "../Styling/desktop/contactus.css";
import "../Styling/desktop/commoncomp.css";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import UpdatesCheckbox from "./Common Components/UpdatesCheckbox";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Select from "@mui/material/Select";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [fullName, setfullName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [successAlert, setsuccessAlert] = useState(false);
  const [errorAlert, seterrorAlert] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const [receiveUpdatecheckbox, setreceiveUpdatecheckbox] = useState(false);
  const [subject, setSubject] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handlesubmit called");
    setbtndisabled(true);
    const SHEET_URL =
      "https://script.google.com/macros/s/AKfycbz4mq9w2vgW6ROUFHQ834_BbS4MqNfZWQ8sxy9fqbvhGogtDltX2Kl9Y45Zv2SHRoSoiA/exec";
    let today = new Date();
    let date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dataObj = {
      sheetName: "ContactUs",
      ID: uuidv4(),
      Name: fullName,
      Email: Email,
      Message: Message,
      PageURL: window.location.href,
      PageTitle: document.title,
      ServiceType: "Contact",
      Date: date,
      Time: time,
      receiveUpdate: receiveUpdatecheckbox,
      subject: subject,
    };
    let formData = new FormData();
    for (let key in dataObj) {
      formData.append(key, dataObj[key]);
    }
    let mail_promise = await axios.post(
      "https://backend.visadiscovery.com/send_mail",
      dataObj
    );
    let googleSheet_promise = await axios.post(SHEET_URL, formData);

    try {
      let responseArr = await Promise.all([mail_promise, googleSheet_promise]);
      // console.log(responseArr);
      if (
        responseArr[0].data.mail_status === "success" &&
        responseArr[1].data.result === "success"
      ) {
        setTimeout(() => setsuccessAlert(false), 3000);
        setsuccessAlert(true);
        setbtndisabled(false);
      } else {
        throw "Something went wrong";
      }
    } catch (err) {
      console.log(err);
      setTimeout(() => seterrorAlert(false), 3000);
      seterrorAlert(true);
      setbtndisabled(false);
    }

    setfullName("");
    setEmail("");
    setMessage("");
    setreceiveUpdatecheckbox(false);
    setSubject("");
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Portugal Visa for Indians | Visa Discovery</title>
        <meta
          name="description"
          content="Contact Visa Discovery for all the information and guidance on Portugal visa for Indians and citizens from other Asian countries. Fill out the form to get in touch with us."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Contact Us",
                item: "https://visadiscovery.com/contact-us",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" maxWidth="100%" paddingTop="1rem" className="fade">
        <Header />
        <Box
          className="contact-us-cover-container"
          mt="-1rem"
          width="100%"
          height="45vh"
          textAlign="center"
        >
          <Typography variant="h4" color="white" paddingTop="30vh ">
            Contact Us
          </Typography>
        </Box>
        <Box backgroundColor="#F0F0FF" width="100vw" maxWidth="100%">
          <Box
            className="wrapper"
            padding="2.5rem 0"
            sx={{
              width: { xs: "calc(100vw - 3rem)", md: "calc(100vw - 12rem)" },
            }}
            margin="auto"
          >
            <Box
              display="flex"
              sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "35%" },
                  flexDirection: { xs: "column", md: "column" },
                  alignItems: { xs: "center", md: "start" },
                  mt: { xs: "4rem", md: "0" },
                }}
                display="flex"
              >
                <Typography mb="1rem" variant="h4">
                  Contact Us
                </Typography>
                <Box>
                  <Typography
                    className="contactoptions phone"
                    variant="body2"
                    mb="1rem"
                  >
                    Call +91 99999 88888
                  </Typography>
                  <Typography
                    className="contactoptions email"
                    variant="body2"
                    mb="1rem"
                  >
                    info@visadiscovery.com
                  </Typography>
                  <Typography
                    className="contactoptions calender"
                    variant="body2"
                    mb="1rem"
                  >
                    Book an Appointment
                  </Typography>
                  <Typography
                    className="contactoptions whatsapp"
                    variant="body2"
                    mb="1rem"
                  >
                    Whatsapp Us
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: { xs: "100%", md: "65%" } }}>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Box
                    width="100%"
                    display="flex"
                    sx={{
                      flexDirection: { xs: "column", md: "row" },
                      gap: { xs: "0", md: "1rem" },
                    }}
                  >
                    <Box width="100%">
                      <Typography gutterBottom variant="body2" fontSize="1rem">
                        Full Name
                      </Typography>
                      <input
                        required
                        value={fullName}
                        onChange={(e) => setfullName(e.target.value)}
                        className="inquire-form-input"
                        type="text"
                        id="full-name"
                        name="full-name"
                        placeholder="John Doe"
                      />
                    </Box>
                    <Box width="100%">
                      <Typography gutterBottom variant="body2" fontSize="1rem">
                        Email
                      </Typography>
                      <input
                        required
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="inquire-form-input"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="john@email.com"
                      />
                    </Box>
                  </Box>
                  <Box mb="10px" mt="0px">
                    <Typography gutterBottom variant="body2" fontSize="1rem">
                      Subject
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "0.5rem",
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          { display: "none" },
                      }}
                    >
                      <Select
                        // labelId="subject-dropdown"
                        id="subject-select"
                        value={subject}
                        MenuProps={{
                          disableScrollLock: true,
                          placeholder: "Choose a Subject",
                        }}
                        label="Subject"
                        onChange={(e) => setSubject(e.target.value)}
                        sx={{ fontWeight: "400", borderRadius: "0.5rem" }}
                      >
                        <MenuItem
                          value={"Golden Visa"}
                          sx={{ fontWeight: "400" }}
                        >
                          Golden Visa
                        </MenuItem>
                        <MenuItem
                          value={"Work Visa"}
                          sx={{ fontWeight: "400" }}
                        >
                          Work Visa
                        </MenuItem>
                        <MenuItem
                          value={"Tech Visa"}
                          sx={{ fontWeight: "400" }}
                        >
                          Tech Visa
                        </MenuItem>
                        <MenuItem
                          value={"Startup Visa"}
                          sx={{ fontWeight: "400" }}
                        >
                          Startup Visa
                        </MenuItem>
                        <MenuItem
                          value={"Green Visa"}
                          sx={{ fontWeight: "400" }}
                        >
                          Green Visa
                        </MenuItem>
                        <MenuItem value={"Others"} sx={{ fontWeight: "400" }}>
                          Others
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box width="100%">
                    <Typography gutterBottom variant="body2" fontSize="1rem">
                      Message
                    </Typography>
                    <textarea
                      required
                      value={Message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="inquire-form-msg"
                      id="msg"
                      name="Message"
                      placeholder="Your message"
                    ></textarea>
                  </Box>
                  <UpdatesCheckbox
                    receiveUpdatecheckbox={receiveUpdatecheckbox}
                    setreceiveUpdatecheckbox={setreceiveUpdatecheckbox}
                  />
                  <Button
                    disabled={btndisabled}
                    type="submit"
                    style={{
                      width: "100%",
                      height: "3rem",
                      borderRadius: ".5rem",
                      backgroundColor: "#2F2FA2",
                      color: "white",
                      fontSize: "1rem",
                    }}
                  >
                    {btndisabled ? "Please Wait..." : "Submit"}
                  </Button>
                  <Alert
                    sx={{
                      display: successAlert ? "flex" : "none",
                      margin: "0 auto",
                      width: "fit-content",
                    }}
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                  >
                    Successfully Submitted!
                  </Alert>
                  <Alert
                    sx={{
                      display: errorAlert ? "flex" : "none",
                      margin: "0 auto",
                      width: "fit-content",
                    }}
                    severity="error"
                  >
                    Error Submiting! Try Again
                  </Alert>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default ContactUs;
