import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Header2 from "../Common Components/Header2";
import Wheretoapply from "../Common Components/Wheretoapply";
import Header from "../Homepage/Header";
import Footer from "../Homepage/Footer";
import Gvsection1 from "./Gvsection1";
import Gvsection2 from "./Gvsection2";
import Gvsection3 from "./Gvsection3";
import Gvsection4 from "./Gvsection4";
import Gvsection5 from "./Gvsection5";
import Gvsection6 from "./Gvsection6";
import InvestmentOptions from "./InvestmentOptions";
// import Additionalservices from '../Common Components/Additionalservices'
import Requestconsultation from "./Requestconsultation";
import Dialog from "@mui/material/Dialog";
import PopupForm from "../Common Components/PopupForm";
import { Helmet } from "react-helmet";

function Goldenvisa() {
  const [open, setOpen] = React.useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  useEffect(() => {
    document.title = "Golden Visa to Portugal | Visa Discovery";
  }, []);
  //making an array of objs with heading name and its ref and passing this to Header2
  const headingArr = [
    {
      headingName: "Details & Eligibility",
      headingRef: ref1,
    },
    {
      headingName: "Investment Options",
      headingRef: ref2,
    },
    {
      headingName: "Benefits",
      headingRef: ref3,
    },
    {
      headingName: "Requirements",
      headingRef: ref4,
    },
    {
      headingName: "Process and Fees",
      headingRef: ref5,
    },
    {
      headingName: "Where to apply",
      headingRef: ref6,
    },
    {
      headingName: "FAQ",
      headingRef: ref7,
    },
    // {
    //     headingName: 'Additional Services',
    //     headingRef: ref8
    // },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Residency Visas",
                item: "https://visadiscovery.com/portugal-residency-visa",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Golden Visa",
                item: "https://visadiscovery.com/golden-visa-to-portugal",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" maxWidth="100%">
        <Header notfixed="header-nonfixed" />
        <Gvsection1 openSetterFn={setOpen} />
        <Header2 headerObjArr={headingArr} />
        <Box className="sticky-request-container">
          <Requestconsultation openSetterFn={setOpen} />
          <Gvsection2 refProp={ref1} />
          <InvestmentOptions bgColor="#F5F5F5" refProp={ref2} />
          <Gvsection3 bgColor="" refProp={ref3} />
          <Gvsection4 bgColor="#F5F5F5" refProp={ref4} />
          <Gvsection5 bgColor="" refProp={ref5} />
          <Wheretoapply refProp={ref6} />
          <Gvsection6 bgColor="" refProp={ref7} />
          {/* <Additionalservices bottomMargin="not required" refProp={ref8} /> */}
        </Box>
        <Footer />
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              opacity: "0.4 !important",
              backgroundColor: "#2F2FA2",
            },
            zIndex: "99002",
            "& .MuiDialog-paper": {
              maxWidth: "none",
              borderRadius: { xs: "1rem", md: "1.5rem" },
              maxHeight: { xs: "none", md: "75vh" },
              width: { xs: "-webkit-fill-available", md: "auto" },
            },
          }}
        >
          <PopupForm title="Golden Visa" openSetterFn={setOpen}></PopupForm>
        </Dialog>
      </Box>
    </>
  );
}

export default Goldenvisa;
