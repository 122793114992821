import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function Wvsection6({refProp}) {
    return (
        <>
            <Box ref={refProp} width="100%" backgroundColor="#F5F5F5">
                <Box className="section3 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                    <Box width="100%" mt="2rem" >
                        <Typography mb="1rem" variant="h4" ml="0px important">FAQs</Typography>
                        <Box sx={{
                            height: { xs: 'auto'}, width: { xs: '100%'},
                            mb: { xs: "3rem", lg: "1rem" }
                        }} className='faq-column'>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">What Is the Duration of a Work Visa?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    With your work visa, you will receive a residence permit which allows you to live and work
                                    in Portugal for upto two years. After this period, you can renew this permit at the SEF
                                    before it expires, as long as you still have a job offer from your employer.
                                    Then, you can continue to live and work in Portugal for the duration that the residence
                                    permit is issued. Five years after arriving in Portugal, you can apply for permanent
                                    residency.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Does a Work Visa Lead to Permanent Residency in Portugal?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    Yes, after you have lived in Portugal for five years using a temporary resident permit, then
                                    you can apply for a permanent resident permit. Once you become a permanent resident,
                                    you will be open to the job market and no longer need to apply for a work permit.
                                    Permanent resident permits also have a longer duration, are more easily renewed, and you
                                    will enjoy similar benefits to a Portuguese citizen.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Does a Work Visa Lead to Portuguese Citizenship?</Typography>
                                <Typography variant="body2" mb="1rem">
                                    Yes, if you have lived in Portugal as a permanent or temporary resident for at least six
                                    years, you can apply for the Portuguese citizenship. You must be proficient in Portuguese
                                    and have links to the Portuguese community.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Can I Work in Portugal if I Have a Family Visa?</Typography>
                                <Typography variant="body2" mb="1rem">
                                Yes.<br/>
If you are joining a family member who lives in Portugal, you are allowed to work and study
in Portugal. You might have to seek additional permits for work, for which you should
contact the SEF
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Can I Work in Portugal as an International Student?
                                </Typography>
                                <Typography variant="body2" mb="1rem">
                                    Yes. <br />
                                    International students are allowed to work in Portugal for a specific number of hours
                                    depending on your nationality.
                                    Non-EU/EEA/Swiss students can only work for up to 20 hours/week (part-time) during the
                                    semester.
                                    Between semesters, on breaks or holidays, students are allowed to work full time.
                                </Typography>
                            </Box>
                        </Box>


                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Wvsection6
