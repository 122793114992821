import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function GreenvFAQ({refProp, bgColor}) {
    return (
        <>
            <Box width="100%" backgroundColor={bgColor} ref={refProp}>
                <Box className="section3 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                    <Box width="100%" mt="2rem" >
                        <Typography mb="1rem" variant="h4" ml="0px important">FAQs</Typography>
                        <Box sx={{
                            height: { xs: 'auto', lg: 'auto' }, width: { xs: '100%'},
                            mb: { xs: "3rem", lg: "1rem" },
                        }} className="faq-column">
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Who is eligible for a Portuguese Green Visa?</Typography>
                                <Typography variant="body2" mb="1rem">
                                To be eligible for a Green Visa in Portugal you must be a non-EU citizen and must complete one of the investments listed in our “Requirements” section.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Is Portugal Green visa worth it?</Typography>
                                <Typography variant="body2" mb="1rem">
                                Portugal's Green Visa Program offers many benefits, even after considering the significant investment required. For foreign investors looking for access to the EU and a powerful passport, the fees and costs will definitely be worth it.
                                </Typography>
                            </Box>
                            {/* <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">What are the fees for the Golden Visa?</Typography>
                                <Typography variant="body2">
                                    Government fees per family member for application of the Portugal golden visa are about
                                    €5,350.
                                    Renewal fees every two years apply at 50% of those rates.
                                    In addition there is a processing fee on application and renewal of € 533.90 for the main
                                    applicant plus € 83.30 per family member
                                </Typography>
                            </Box> */}
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">How long does the application take?</Typography>
                                <Typography variant="body2" mb="1rem">
                                The Portugal Green Visa processing times usually takes anywhere from three to eight months for approval and issuance of your residence card.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography gutterBottom variant="body2" fontWeight="700 !important">Does a child born in Portugal get citizenship?</Typography>
                                <Typography variant="body2">
                                A recent new law states that children born to foreign parents, at least one of whom has lived in Portugal for a minimum of one year will be eligible for Portuguese citizenship at birth.
                                </Typography>
                            </Box>
                        </Box>


                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default GreenvFAQ
