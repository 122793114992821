import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import "../Styling/desktop/knowyourrights.css";
import Divider from "@mui/material/Divider";
import externallinkicon from "../Images/External Link - Blue.svg";
import { Helmet } from "react-helmet";

function Knowyourrights() {
  const overviewRef = useRef();
  const dataRef = useRef();
  const whatisVISRef = useRef();
  const advVISRef = useRef();
  const VISpracticeRef = useRef();
  const scrollToView = (ref) => {
    console.log("scroller called", ref.current);
    // ref.current.scrollIntoView();
    window.scrollTo(0, ref.current.offsetTop - 100);
  };
  return (
    <>
      <Helmet>
        <title>
          Know Your Rights - VIS - Portugal Visa Types | Visa Discovery
        </title>
        <meta
          name="description"
          content="VIS (Visa Information System) is a data exchange system between the Schengen Member States on visas. Learn more about your rights in data sharing while applying for various Portugal visa types."
        />
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Visa Discovery",
                  item: "https://visadiscovery.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Know Your Rights",
                  item: "https://visadiscovery.com/know-your-rights",
                },
              ],
            })}
          </script>
        </Helmet>
      </Helmet>
      <Box className="page" width="100vw" maxWidth="100%" paddingTop="1rem">
        <Header />
        <Box
          className="knowrights-cover-container"
          mt="-1rem"
          width="100%"
          height="45vh"
          textAlign="center"
        >
          <Typography variant="h4" color="white" paddingTop="30vh ">
            Know Your Rights
          </Typography>
        </Box>
        <Box
          width="100vw"
          height="4.5rem"
          backgroundColor="#FBFBFB"
          backdropFilter="blur(20px)"
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          maxWidth="100%"
          sx={{ padding: { xs: "1rem", md: "1rem 6rem" } }}
        >
          <Typography
            variant="body1"
            fontWeight="400"
            color="#444444"
            fontStyle="Poppins"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToView(overviewRef)}
          >
            Overview
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography
            variant="body1"
            fontWeight="400"
            color="#444444"
            fontStyle="Poppins"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToView(dataRef)}
          >
            Data Protection
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography
            variant="body1"
            fontWeight="400"
            color="#444444"
            fontStyle="Poppins"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToView(whatisVISRef)}
          >
            What Is VIS?
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography
            variant="body1"
            fontWeight="400"
            color="#444444"
            fontStyle="Poppins"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToView(advVISRef)}
          >
            Advantages Of VIS
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography
            variant="body1"
            fontWeight="400"
            color="#444444"
            fontStyle="Poppins"
            style={{ cursor: "pointer" }}
            onClick={() => scrollToView(VISpracticeRef)}
          >
            VIS in Practise
          </Typography>
        </Box>
        <Box
          sx={{ width: { xs: "calc(100vw - 3rem)" }, mt: "2rem", mb: "2rem" }}
          margin="auto"
        >
          <Box
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "calc(100% - 17rem)" },
                paddingRight: { xs: "0", md: "3rem" },
              }}
            >
              <Typography
                mb=".5rem"
                variant="h4"
                ml="0px important"
                ref={overviewRef}
              >
                Overview
              </Typography>
              <Typography variant="body2" style={{ textAlign: "justify" }}>
                VIS (Visa Information System) is a data exchange system between
                the Schengen Member States on visas. These countries share a
                freedom of movement common area with no internal border control.
                A common visa policy is applied in the area, requiring
                information sharing on visa applicants. The specific objectives
                of the VIS are to facilitate visa procedures, contribute to
                increasing security in the process, and facilitate visa control
                at the Schengen area external borders. Biometric data of the
                visa applicant will be collected (10 fingerprints scan and
                digital photograph) in a simple and discrete procedure that will
                last a couple of minutes. Biometric data, jointly with data
                gathered in the visa form, will be registered in a central
                database. The data will be kept in the VIS for 5 years. Any
                person has the right to be informed of their VIS data. Any
                person may request for inaccurate data on the VIS to be
                corrected and for data collected illegally to be removed from
                the system, through submission of the respective form at the
                consular post of the area of residency or through the email
                address vistos@mne.pt. The National Commission on Data
                Protection (Comissão Nacional de Protecção de Dados (CNDP) - Av.
                D. Carlos I nº. 134 – 3º, 1200-651 Lisbon, Tel: +351 213928400 -
                Fax: +351 213976832, e-mail geral@cnpd.pt , who is the national
                authority in this regard, will log complaints concerning data
                protection of personal data. For further information refer to
                the links from the NCDP on access to Schengen and on your VIS
                rights. For further information on the VIS, refer to the
                available information of the European Commission.
              </Typography>
              <Typography
                mt="1rem"
                mb=".5rem"
                variant="h4"
                ml="0px important"
                ref={dataRef}
              >
                Data Protection
              </Typography>
              <Typography variant="body2" style={{ textAlign: "justify" }}>
                Access to VIS data is limited to authorised staff working for
                visa, border, immigration and asylum authorities for the
                performance of their tasks. Data is kept in the VIS for 5 years.
                Any person has the right to be informed about his/her data in
                the VIS. Any person may request that inaccurate data about him-
                or herself be corrected, and that unlawfully recorded data be
                deleted.
              </Typography>
              <Typography
                mt="1rem"
                mb=".5rem"
                variant="h4"
                ml="0px important"
                ref={whatisVISRef}
              >
                What is VIS?
              </Typography>
              <Typography variant="body2" style={{ textAlign: "justify" }}>
                The VIS is a system for the exchange of visa data among Schengen
                Member States. These States share a common area of free movement
                without internal border controls. They have a common visa policy
                which includes exchanging information on visa applicants. The
                specific objectives of the VIS are to facilitate visa
                procedures, to enhance the security of the visa application
                process and to facilitate visa checks at the external borders of
                the Schengen Area.
              </Typography>
              <Typography
                mt="1rem"
                mb=".5rem"
                variant="h4"
                ml="0px important"
                ref={advVISRef}
              >
                Advantages of VIS
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "justify" }}
                mb=".5rem"
              >
                The VIS is a system for the exchange of visa data among Schengen
                Member States. These States share a common area of free movement
                without internal border controls. They have a common visa policy
                which includes exchanging information on visa applicants. The
                specific objectives of the VIS are to facilitate visa
                procedures, to enhance the security of the visa application
                process and to facilitate visa checks at the external borders of
                the Schengen Area.
              </Typography>
              <Typography variant="h7" fontWeight="700">
                Facilitating Visa Procedures
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "justify" }}
                mt=".5rem"
                mb=".5rem"
              >
                The VIS allows Schengen Member States to determine a visa
                applicant’s reliability more easily. The VIS helps Schengen
                Member States to verify whether an applicant has used previous
                visas lawfully. These checks facilitate the visa issuance
                process, particularly for frequent travellers.
              </Typography>
              <Typography variant="h7" fontWeight="700">
                Protecting Your Identity
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "justify" }}
                mt=".5rem"
                mb=".5rem"
              >
                Thanks to the use of biometric data, a visa holder can easily be
                identified as the person who applied for the visa. Biometric
                technology facilitates the detection of travellers using another
                person’s travel documents and protects travellers from identity
                theft.
              </Typography>
              <Typography variant="h7" fontWeight="700">
                Streamlining of Border Checks
              </Typography>
              <Typography
                variant="body2"
                style={{ textAlign: "justify" }}
                mt=".5rem"
              >
                When arriving at an external border of the Schengen Area, the
                visa holder’s biometric data is checked to confirm his/her
                identity. This leads to more accurate processing and greater
                security.
              </Typography>
              <Typography
                mt="1rem"
                mb=".5rem"
                variant="h4"
                ml="0px important"
                ref={VISpracticeRef}
              >
                VIS in Practise
              </Typography>
              <Typography variant="body2" style={{ textAlign: "justify" }}>
                The visa applicant’s biometric data (10-digit finger scans and
                digital photograph) is collected. It is a simple and discreet
                procedure that only takes a few minutes. Biometric data, along
                with the data provided in the visa application form, is recorded
                in a secure central database. 10-digit finger scans are neither
                required from children under the age of 12 nor from people who
                cannot physically provide finger scans. Frequent travellers to
                the Schengen Area do not have to give new finger scans every
                time they apply for a new visa. Once finger scans are stored in
                the VIS, they can be re-used for further visa applications over
                a 5-year period. The visa holder’s finger scans may be compared
                against those in the database at the Schengen Area’s external
                borders. A mismatch does not mean that entry will be
                automatically refused - it will just lead to further checks on
                the traveller’s identity.
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "17rem" },
                mt: { xs: "1rem", md: "0" },
              }}
            >
              <Typography color="#2F2FA2 !important" variant="h5">
                Important Links
              </Typography>
              <Box display="flex" alignItems="center" mt="1rem">
                <Typography
                  variant="h7"
                  fontWeight="700"
                  maxWidth="80%"
                  mr="1rem"
                >
                  National Commission on Data Protection
                </Typography>
                <img src={externallinkicon} alt="arrow"></img>
              </Box>
              <Typography variant="body2" gutterBottom>
                Something about this link
              </Typography>
              <Box display="flex" alignItems="center" mt="1rem">
                <Typography
                  variant="h7"
                  fontWeight="700"
                  maxWidth="80%"
                  mr="1rem"
                >
                  VIS Rights
                </Typography>
                <img src={externallinkicon} alt="arrow"></img>
              </Box>
              <Typography variant="body2" gutterBottom>
                Something about this link
              </Typography>
              <Box display="flex" alignItems="center" mt="1rem">
                <Typography
                  variant="h7"
                  fontWeight="700"
                  maxWidth="80%"
                  mr="1rem"
                >
                  Download Information Flyer (English)
                </Typography>
                <img src={externallinkicon} alt="arrow"></img>
              </Box>
              <Typography variant="body2" gutterBottom>
                Something about this link
              </Typography>
              <Typography color="#2F2FA2 !important" variant="h5" mt="1rem">
                Forms
              </Typography>
              <Box display="flex" alignItems="center" mt="1rem">
                <Typography
                  variant="h7"
                  fontWeight="700"
                  maxWidth="85%"
                  mr="1rem"
                >
                  Information request/complaint on VIS data form
                </Typography>
                <img src={externallinkicon} alt="arrow"></img>
              </Box>
              <Box display="flex" alignItems="center" mt="1rem">
                <Typography
                  variant="h7"
                  fontWeight="700"
                  maxWidth="82%"
                  mr="1rem"
                >
                  VIS data elimination request form
                </Typography>
                <img src={externallinkicon} alt="arrow"></img>
              </Box>
              <Box display="flex" alignItems="center" mt="1rem">
                <Typography
                  variant="h7"
                  fontWeight="700"
                  maxWidth="85%"
                  mr="1rem"
                >
                  VIS data correction request form
                </Typography>
                <img src={externallinkicon} alt="arrow"></img>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default Knowyourrights;
