import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import greyarrow from '../../Images/Icon feather-arrow-right-circle-grey.svg';
import Collapse from '@mui/material/Collapse';
import { v4 as uuidv4 } from 'uuid';
function Header2({ headerObjArr }) {
    const [mobilemenuOpen, setmobilemenuOpen] = useState(false)

    const scrollIntoView = (ref) => {
        if (ref && ref.current) {
            if (window.matchMedia('(min-width: 900px)').matches) {
                const offset = (Number(ref.current.offsetTop) - 50)
                window.scrollTo(0, offset)
            } else {
                // ref.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: "start"})
                const offset = (Number(ref.current.offsetTop - 350))
                window.scrollTo(0, offset)
                setmobilemenuOpen(false);
            }
        }
    }

    return (
        <Box
            width="100vw"
            sx={{ padding: { xs: '1rem 1.5rem', md: '1rem 2rem' }, height: { xs: 'auto', md: '4.5rem' } }}
            backgroundColor="#FBFBFB" backdropFilter="blur(20px)"
            style={{ position: 'sticky', top: '0px', zIndex: '99000' }}
            maxWidth="100%"
            display="flex"
            alignItems="center"
        >
            <Box
                alignItems="center"
                justifyContent="space-evenly"
                sx={{ display: { xs: 'none', md: 'flex' } }}
                width="100%"
                height="100%"
            >
                {
                    headerObjArr?.map((obj, index) => {
                        return (
                            <React.Fragment key={uuidv4().toString()}>
                                {
                                    index !== headerObjArr.length - 1 ? <>
                                        <Typography  variant="body1" fontWeight="400" color="#444444" style={{ cursor: 'pointer' }} fontStyle="Poppins" onClick={() => scrollIntoView(obj.headingRef)}>{obj.headingName}</Typography>
                                        <Divider  orientation="vertical" variant="middle" flexItem />
                                    </> : <>
                                        <Typography variant="body1" fontWeight="400" color="#444444" style={{ cursor: 'pointer' }} fontStyle="Poppins" onClick={() => scrollIntoView(obj.headingRef)}>{obj.headingName}</Typography>
                                    </>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }} width="100%">
                <Box display="flex" alignItems="center" justifyContent="space-around" width="min-content" padding="0 2rem" margin="0 auto" onClick={() => setmobilemenuOpen(prevState => !prevState)}>
                    <Typography variant="h4" fontWeight="400" mr="1rem" color="#444444" fontStyle="Poppins" whiteSpace="nowrap">Navigate To</Typography>
                    <img src={greyarrow} alt="down arrow" style={{ width: '1.5rem', transform: 'rotate(90deg)' }}></img>
                </Box>
                <Collapse in={mobilemenuOpen}>
                    <Box width="100%" display="flex" mt="1rem" alignItems="center" justifyContent="center" flexDirection="column">
                        {
                            headerObjArr?.map((obj, index) => {
                                return (
                                    <React.Fragment key={uuidv4().toString()}>
                                        {
                                            index !== headerObjArr.length - 1 ? <>
                                                <Typography variant="body1" fontWeight="400" color="#444444" style={{ cursor: 'pointer' }} fontStyle="Poppins" onClick={() => scrollIntoView(obj.headingRef)}>{obj.headingName}</Typography>
                                                <Divider orientation="horizontal" variant="middle" flexItem sx={{margin: 'revert', marginLeft: '65px', marginRight: '65px'}}/>
                                            </> : <>
                                                <Typography variant="body1" fontWeight="400" color="#444444" style={{ cursor: 'pointer' }} fontStyle="Poppins" onClick={() => scrollIntoView(obj.headingRef)}>{obj.headingName}</Typography>
                                            </>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        {/* <Typography variant="body1" fontWeight="400" color="#444444" fontStyle="Poppins" onClick={()=>{scrollIntoView(detailsref)}}>Details & Eligibility</Typography>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{margin: 'revert', marginLeft: '65px', marginRight: '65px'}}/>
                        <Typography variant="body1" fontWeight="400" color="#444444" fontStyle="Poppins" onClick={()=>scrollIntoView(benefitsref)}>Benefits</Typography>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{margin: 'revert', marginLeft: '65px', marginRight: '65px'}}/>
                        <Typography variant="body1" fontWeight="400" color="#444444" fontStyle="Poppins" onClick={()=>scrollIntoView(reqref)}>Requirements</Typography>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{margin: 'revert', marginLeft: '65px', marginRight: '65px'}}/>
                        <Typography variant="body1" fontWeight="400" color="#444444" fontStyle="Poppins" onClick={()=>scrollIntoView(wheretoref)}>Where To Apply?</Typography>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{margin: 'revert', marginLeft: '65px', marginRight: '65px'}}/>
                        <Typography variant="body1" fontWeight="400" color="#444444" fontStyle="Poppins" onClick={()=>scrollIntoView(faqref)}>FAQs</Typography>
                        <Divider orientation="horizontal" variant="middle" flexItem sx={{margin: 'revert', marginLeft: '65px', marginRight: '65px'}}/>
                        <Typography variant="body1" fontWeight="400" color="#444444" fontStyle="Poppins" onClick={()=>scrollIntoView(knownprobref)}>Known Problems</Typography> */}
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}

export default Header2
