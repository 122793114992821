import React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import arrowsvggrey from "../Images/Icon feather-arrow-right-circle.svg";

function BlogCard({link, file, altText='destination portugal', title, content, height='100%'}) {
    return (
        <Card
            style={{
                borderRadius: "1.3rem",
                boxShadow: "4px 4px 20px #00000029",
                minWidth: "260px",
                flex: "1",
            }}
            sx={{ mr: { xs: "0rem", md: "0" }, height: {height} }}
        >
            <CardActionArea sx={{ height: "100%" }}>
                <Link to={link} style={{ textDecoration: "none" }}>
                    <CardMedia
                        component="img"
                        height="150px"
                        image={file}
                        alt={altText}
                    />
                    <CardContent className="blog_card_content">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb="1rem"
                        >
                            <Box>
                                <Typography
                                    variant="overline"
                                    fontWeight="400"
                                    style={{ lineHeight: "1.5", display: "inline-block" }}
                                >
                                    News & Updates
                                </Typography>
                                <Typography
                                    mt="1rem"
                                    style={{ lineHeight: "1", color: "#111111" }}
                                    variant="h6"
                                >
                                   {title}
                                </Typography>
                            </Box>
                            <img
                                src={arrowsvggrey}
                                alt="arrow"
                                width="18px"
                                style={{ marginLeft: "8px", alignSelf: "flex-start" }}
                            ></img>
                        </Box>
                        <Box margin="0rem 0">
                            <Typography pb="8px" variant="subtitle1" className="blog_text">
                                {content}
                            </Typography>
                        </Box>
                    </CardContent>
                </Link>
            </CardActionArea>
        </Card>
    )
}

export default BlogCard