import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function LanguageSelector() {
  const [open, setOpen]=React.useState(false);
  const [value, setValue]=React.useState('English');

  React.useEffect(()=>{
    const cookieValue = document?.cookie?.split('; ')?.find(row => row.startsWith('googtrans'))?.split('=')[1];

    if(cookieValue){
        if(cookieValue==='/en/en'){
            console.log('English Selected')
        }else{
            console.log('Hindi Selected')
            setValue('Hindi')
        }
    }
  },[])

  const handleChange=(e)=>{
      console.log(e.target.value);

      setValue(e.target.value)
      let frame=document.querySelector('.goog-te-menu-frame')
      console.log(frame)
      let lang=frame.contentWindow.document.querySelector('.goog-te-menu2-item span.text')
      console.log(lang)
      try{
          lang.click();
          lang.click();
      }catch(err){
          console.log('error is', err)
      }
    //   window.location.reload();
  }
  return (
    <Box sx={{ width: {xs: '60px', md: '80px'}, backgroundColor: '#F0F0FF', padding: '3px 9px', borderRadius: '8px'}} >
      <FormControl fullWidth>
        <NativeSelect
          value={value}
          onChange={handleChange}
          onClick={()=>setOpen(prevValue=>!prevValue)}
          inputProps={{
            name: 'language',
            id: 'google-translate-selector',
          }}
          IconComponent={ExpandMoreIcon}
          variant='filled'
          sx={{textOverflow: 'hidden', maxWidth: {xs: '28px', md: 'none'}, '&:before':{borderBottom: 'none'}, '&:hover:not(.Mui-disabled):before':{borderBottom: 'none'},
          '&:after':{border: 'none !important'},
        '& select':{fontSize: '16px', fontWeight: '400',fontFamily: 'Source Sans Pro', paddingRight:'1px !important', paddingLeft: '1px'},
      '& svg':{right: {xs: '-21px', md: '-8px'}, transition: 'all 0.2s ease-in', transform: open?'rotate(180deg)':'rotate(0deg)'}}}
        >
          <option style={{backgroundColor: '#F0F0FF', border: 'none !important'}} value={'English'}>English</option>
          <option style={{backgroundColor: '#F0F0FF', border: 'none !important'}} value={'Hindi'}>Hindi</option>
        </NativeSelect>
      </FormControl>
    </Box>
  );
}
