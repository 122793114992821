import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function BasicTable({VCfundnetGrowthArr, REfundnetGrowthArr}) {
  let rows=[];

  function makeRows(VCfundnetGrowthArr, REfundnetGrowthArr){
    for(let i=0;i<VCfundnetGrowthArr.length;i++){
      let rowObj={}
      rowObj.VCfundGrowth=VCfundnetGrowthArr[i];
      rowObj.REfundGrowth=REfundnetGrowthArr[i];
      rowObj.difference=Math.abs(VCfundnetGrowthArr[i]-REfundnetGrowthArr[i])
      rows.push(rowObj);
    }
  }

  if(VCfundnetGrowthArr.length>0&&REfundnetGrowthArr.length>0){
    makeRows(VCfundnetGrowthArr, REfundnetGrowthArr)
  }
  const tableBody=(
    rows.map((row, index) => (
      <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
           Year {index + 1}
        </TableCell>
        <TableCell align="center" sx={{color: row.REfundGrowth>row.VCfundGrowth?'#2F2FA2':'black'}}>{row.REfundGrowth}%</TableCell>
        <TableCell align="center" sx={{color: row.REfundGrowth>row.VCfundGrowth?'black':'#2F2FA2'}}>{row.VCfundGrowth}%</TableCell>
        <TableCell align="center" sx={{color: '#2F2FA2'}}>{row.difference}%</TableCell>
      </TableRow>

  )
  ))

  return (
    <TableContainer >
      <Table aria-label="simple table" sx={{'& .MuiTableCell-root': {borderBottom: 'none'}}} >
        <TableHead>
          <TableRow > 
            <TableCell></TableCell>
            <TableCell align="center">Net Growth <br></br> <span style={{fontWeight:'bold'}}>Real Estate</span> </TableCell>
            <TableCell align="center">Net Growth <br></br> <span style={{fontWeight:'bold'}}>VC Fund</span></TableCell>
            <TableCell align="center">Net Growth <br></br> <span style={{fontWeight:'bold'}}>Difference</span></TableCell>
          </TableRow>
        </TableHead>
        
          {rows.length>0?<>{tableBody}</>:<></>}
        
      </Table>
    </TableContainer>
  );
}
