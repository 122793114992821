import React from 'react'
import Box from '@mui/material/Box';
import Header from './Homepage/Header';
import Footer from './Homepage/Footer';
import { Typography } from '@mui/material';

function TermsOfService() {
    React.useEffect(() => {
        document.title = "Terms & Condition | Visa Discovery"
    }, [])
    return (
        <Box width="100vw" maxWidth="100%" paddingTop="1rem">
            <Header />
            <Box sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' } }} margin="auto">
                <Box width="100%" sx={{ mt: { xs: 'calc(12vh + 1rem)', md: "calc(12vh + 2rem)" }, mb: { xs: '2rem', md: '5rem' } }}>
                    <Typography gutterBottom variant="h4">TERMS AND CONDITIONS</Typography>


                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1    TERMS OF WEBSITE USE  </span>
                        Thank you for visiting our website <a href="/">visadiscovery.com</a> which is owned and operated by VisaDiscovery.com. By continuing to browse through and use this website, you are bound by and agree to these terms and conditions of use. If you disagree with any part of these stated terms and conditions or our privacy policy, please do not use this website or any services offered on this website.<br/>
                        The term ‘VisaDiscovery.com’ and/or ‘us’ and/or ‘we’ refers to the private limited company that is the owner of this website having its Company identification no. (CIN) as , given by the registrar of companies of Maharashta. The term ‘you’ refers to the user and /or viewer of this website.<br/>
                        This document is an electronic record in terms of Information Technology Act, 2000, and rules made there under and published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2008. This electronic record is generated by a computer system and does not require any physical or digital signatures.<br/>
                        VisaDiscovery.com <a href="/">visadiscovery.com</a> currently sells chocolate, confectionery, bakery and other related gourmet food products. This Agreement shall continue to be in full force and effect for so long as you are using the site. By clicking on the “Proceed to Payment” button, you are agreeing to use the Services in a manner consistent with and abide by the terms and conditions of this Agreement, our Privacy Policy, and with all applicable laws and regulations.<br/>
                        By continuing to use this site, you represent that (1) all of the information you provide is accurate, current and complete, (2) you will be solely responsible for all transactions that take place using your account, and (3) you will be solely responsible for maintaining the secrecy and security of your account and password. We reserve the right to refuse service, terminate your account, or cancel your orders in our sole discretion, including, without limitation, if we believe that your conduct violates applicable law or is harmful to our interests.
                        The Terms of Use herein are subject to change from time to time by <a href="/">visadiscovery.com</a> without notice and we strongly recommended that you periodically review the Terms of Use as available on this document. The content of the pages of this website is for your general information and use only.<br/>
                        This website uses cookies to monitor browsing preferences.<br/>
                        Unauthorized use of this website may give rise to a claim for damages and / or be a criminal offence.
                    </Typography>

                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.1    INFORMATION USE AND ACCURACY DISCLAIMER</span>
                        Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.<br/>
                        From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).<br/>
                        Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. If any inaccuracies or errors are brought to our attention, we will take action to correct these as soon as possible
                        Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.<br/>
                        Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.
                    </Typography>

                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.2 RESTRICTIONS OF USE</span>
                        You are expressly and emphatically restricted from all of the following:<br/>
                        - Publishing any Website material in any media;<br/>
                        - Selling, sublicensing and/or otherwise commercializing any Website material;<br/>
                        - Publicly performing and/or showing any Website material;<br/>
                        - Using this Website in any way that is, or may be, damaging to this Website;<br/>
                        - Using this Website in any way that impacts user access to this Website;<br/>
                        - Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;<br/>
                        - Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;<br/>
                        - Using this Website to engage in any advertising or marketing;<br/>
                        - Certain areas of this Website are restricted from access by you and VisaDiscovery.com and / or VisaDiscovery.com may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion.
                    </Typography>

                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.2    TRADEMARK AND INTELLECTUAL PROPERTY RIGHTS</span>
                        This website contains material which is owned by or licensed to us. This material which includes, but is not limited to, the names, design, text, logos, layout, look, appearance, softwares, graphics and furthermore all related events, classes, products & service names, design marks, slogans are the trademarks or service marks of VisaDiscovery.com (<a href="/">visadiscovery.com</a>). These IPRs may not be used in connection with any product, event, class or service that is not offered by us in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits us. All other trademarks not owned by us that appear on this site are the property of their respective owners, our affiliates, partners or suppliers.<br/>
                        You agree not to and it is strictly prohibited to modify or copy, distribute, transmit, display, reproduce, publish, license, create derivative works from, transfer, or sell any information obtained from the website for any personal, commercial or public purposes, without our prior written permission.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.3 VIOLATIONS OR ALLEGED VIOLATIONS IN THE TERMS OF SERVICE</span>
                        In the case of any violation, or alleged violation of the Terms of Service, VisaDiscovery.com reserves all right to terminate all your interactions with the site, including removal of any of your information on its servers, with or without any notification to you and refusing any future access to you to the site. In order to provide a fair response to any allegation of such violation, you agree that VisaDiscovery.com or its employees or representatives might access your accounts and your User Content if needed. VisaDiscovery.com is not required to disclose such internal investigation unless required by law.<br/>
                        VisaDiscovery.com retains the right to monitor the website for prohibited conduct or content, but does not in any way assume the responsibility to do so. Should it choose to do so, it would be in its sole discretion, and it would not be required to make such monitoring public. If you are aware of such violation of the terms of service, please reach out to us through the Contact Us details provided on the website.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.4 REFUSAL OF ORDERS</span>
                        VisaDiscovery.com reserves the right to refuse or cancel any order, at any time, without any reason. Potentially, this could be caused due, but is not limited to, lack of inventory, error in inventory management systems, force majeure, shipping issues, quality control, violation of prohibited content, error in pricing or information on website, fraud or payment issues. Should your order be refused or cancelled, we will inform you as soon as possible, and if payment has already been accepted for it, it will be credited back to you, or an alternative to purchase something else of similar value will be given
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.5 QUANTITY LIMITATIONS</span>
                        VisaDiscovery.com reserves all rights to limit the quantity that can be purchased per order or per person. In case of such limitations being placed, we will inform you as soon as possible. If you’re interested in ordering in bulk, please contact us through the “Contact Us” link on the website, and we will be able to process your order and provide you with a custom rate depending on the type and bulk of order
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.6 LIABILITY DISCLAIMER</span>
                        VisaDiscovery.com disclaims all liability for loss, expense or damage, whether direct, indirect, consequential or foreseeable suffered by the user or any third party arising out of, or caused in connection to the use or access of the site, the information or materials contained in the site or the services provided by the site. Neither VisaDiscovery.com, nor any third party will be liable for any data loss, theft, cyber theft, decline of transaction or any such event. Under no circumstances will we be liable for any loss or damage caused by the use or misuse of our products, or your reliance on information in any content on our websites.<br/>
                        <br/>
                        YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY ARISING FROM OR RELATING IN ANY WAY TO ANY PRODUCT SHALL BE A CREDIT OR REFUND, PROVIDED AT OUR DISCRETION.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.7 INDEMNIFICATION</span>
                        You agree to defend, indemnify and hold harmless VisaDiscovery.com, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these terms or your use of our website, including, but not limited to, any use of our website's content, services and products other than as expressly authorized in these terms or your use of any information obtained from our website.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.8 WAIVER AND SEVERABILITY</span>
                        No waiver by VisaDiscovery.com. and/or VisaDiscovery.com of any term(s) or condition(s) set forth in these terms will be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of VisaDiscovery.com. and / or VisaDiscovery.com to assert a right or provision under these terms will not constitute a waiver of such right or provision.<br/>
                        If any provision of these terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision will be eliminated or limited to the minimum extent such that the remaining provisions of these terms will continue in full force and effect.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.9 ASSIGNMENT</span>
                        VisaDiscovery.com shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.10 ENTIRE AGREEMENT</span>
                        These terms and our privacy policy constitute the sole and entire agreement between you and VisaDiscovery.com. and / or VisaDiscovery.com with respect to our website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to our website.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.11 APPLICABLE LAW</span>
                        These terms of service and the use of the website shall be construed in accordance and governed by the in all respects by the laws of the State of Maharashtra and the Government of India. You hereby irrevocably consent that exclusive jurisdiction to any legal disputes arising through these Terms of Service and through the use or access of the site shall lie with the courts in Mumbai, in the State of Maharashtra, in India.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'justify', marginBottom: '16px' }}>
                        <span style={{ fontWeight: '700', marginBottom: '4px', display: 'block' }}>1.12    CONTACT US</span>
                        If you have any questions about these Terms and Conditions, please contact us at contact@visadiscovery.com<br/>
                        Thank you for visiting our website. We hope to see you in person at one of our locations soon.
                    </Typography>
                </Box>
            </Box>
            <Footer />
        </Box>

    )
}

export default TermsOfService