import React, { useState } from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import Alert from '@mui/material/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import UpdatesCheckbox from './UpdatesCheckbox';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../../Styling/desktop/commoncomp.css';

function PopupForm({ openSetterFn, title }) {

    const [fullName, setfullName] = useState("");
    const [Email, setEmail] = useState("");
    const [Num, setNum] = useState("");
    const [successAlert, setsuccessAlert] = useState(false)
    const [errorAlert, seterrorAlert] = useState(false);
    const [btndisabled, setbtndisabled] = useState(false);
    const [receiveUpdatecheckbox, setreceiveUpdatecheckbox] = useState(false)

    const handleSubmit = async (e) => {
        console.log("handlesubmit called")
        e.preventDefault();
        const SHEET_URL = "https://script.google.com/macros/s/AKfycbxf7MsRtrxouH9EpbLBsY8GPnHfUu3Z536hwhkgPrj-KS12-t93pB0zDZKk16oQYYqQSg/exec"

        setbtndisabled(true);
        let today = new Date();
        let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

        let dataObj = {
            sheetName: 'VisasLeads',
            ID: uuidv4(),
            Name: fullName,
            Email: Email,
            Num: Num,
            PageURL: window.location.href,
            PageTitle: document.title,
            VisaType: title,
            Date: date,
            Time: time,
            receiveUpdate: receiveUpdatecheckbox,
        }
        let formData = new FormData();
        for (let key in dataObj) {
            formData.append(key, dataObj[key]);
        }
       
        let mail_promise = await axios.post('http://127.0.0.1:8080/send_mail', dataObj);
        let googleSheet_promise = await axios.post(SHEET_URL, formData);

        try {
            let responseArr = await Promise.all([mail_promise, googleSheet_promise]);
            if (responseArr[0].data.mail_status === "success" && responseArr[1].data.result === "success") {
                setTimeout(() => setsuccessAlert(false), 3000)
                setsuccessAlert(true);
                setbtndisabled(false);
            } else {
                throw 'Something went wrong'
            }
        } catch (err) {
            console.log(err);
            setTimeout(() => seterrorAlert(false), 3000)
            seterrorAlert(true);
            setbtndisabled(false)
        }

        setfullName("");
        setEmail("");
        setNum("");
        setreceiveUpdatecheckbox(false)
    }

    return (
        <DialogContent sx={{ width: { xs: '100%', md: '50vw' }, padding: '0px', backgroundColor: '#F0F0FF' }}>
            <Box sx={{ display: { xs: 'block' }, width: "100%", maxHeight: '100%' }}>
                <Box sx={{ width: { xs: '100%' }, display: 'flex', flexDirection: 'column', padding: { xs: '2rem 1rem', md: '2rem 3.5rem' }, position: 'relative' }}>
                    <Typography mb="1rem" variant="h4">Request a Call Back</Typography>
                    <CancelIcon onClick={() => openSetterFn(false)} sx={{ fill: 'white', backgroundColor: 'black', borderRadius: '50%', top: '1rem', right: '1rem', position: 'absolute', cursor: 'pointer' }} />
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Box width="100%">
                            <Typography gutterBottom variant="body2" fontSize="1rem">Full Name</Typography>
                            <input required value={fullName} onChange={(e) => setfullName(e.target.value)} className="inquire-form-input" type="text" id="full-name" name="full-name" placeholder='John Doe' />
                        </Box>
                        <Box width="100%">
                            <Typography gutterBottom variant="body2" fontSize="1rem">Email</Typography>
                            <input required value={Email} onChange={(e) => setEmail(e.target.value)} className="inquire-form-input" type="email" id="email" name="email" placeholder='john@email.com' />
                        </Box>
                        <Box width="100%">
                            <Typography gutterBottom variant="body2" fontSize="1rem">Number</Typography>
                            {/* <input type='text' required value={Num} onChange={(e) => { console.log(e.target.value); setNum(e.target.value) }} className="inquire-form-input" id="num" name="number" placeholder='12345 67890'
                                title='Enter 10 digit Mobile Number' pattern='[0-9]{10}'
                            ></input> */}
                            <PhoneInput
                                country={'in'}
                                value={Num}
                                onChange={phone => setNum(phone)}
                                placeholder="12345-67890"
                                inputProps={{
                                    name: 'number',
                                    required: true,
                                    id: 'num',
                                    title: 'Enter 10 digit Mobile Number with Country code',
                                    placeholder: '12345-67890'
                                }}
                                countryCodeEditable={false}
                            />
                        </Box>
                        <UpdatesCheckbox receiveUpdatecheckbox={receiveUpdatecheckbox} setreceiveUpdatecheckbox={setreceiveUpdatecheckbox} />
                        <DialogActions>
                            <Button disabled={btndisabled} type="submit" style={{ width: "100%", height: "3rem", borderRadius: '.5rem', backgroundColor: "#2F2FA2", color: 'white', fontSize: "1rem" }}>{btndisabled ? "Please Wait..." : "Submit"}</Button>
                        </DialogActions>
                        {/* <Typography textAlign="center" color="#2F2FA2">Form Submitted Successfully</Typography> */}
                        <Alert sx={{ display: successAlert ? 'flex' : 'none', margin: '0 auto', width: 'fit-content' }} icon={<CheckIcon fontSize="inherit" />} severity="success">Successfully Submitted!</Alert>
                        <Alert sx={{ display: errorAlert ? 'flex' : 'none', margin: '0 auto', width: 'fit-content' }} severity="error">Error Submiting! Try Again</Alert>
                    </form>
                </Box>
            </Box>
        </DialogContent>
    )
}

export default PopupForm