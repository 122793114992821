import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import arrowsvg from '../../Images/Icon feather-arrow-right-circle-blue.svg'
import logoImg from '../../Images/Visa Discovery Logo desktop.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import '../../Styling/desktop/vdtquestions.css'
import Footer from '../Homepage/Footer';
import CurrencyRadioBtn from './CurrencyRadioBtn';
import CostTableData from './CostTableData';
import CostQuestion3 from './CostQuestion3';
import LanguageSelector from '../Common Components/LanguageSelector'
function CostTool() {
    const history = useHistory();
    const [noQuestion2, setnoQuestion2] = useState(1); //becoz some questions dont have Q2 directly 3rd question, 1 means q2 is present and 2 means q2 not present
    const [currValue, setcurrValue] = useState('EUR')
    const [spouse, setspouse] = useState('0')
    const [dependentObj, setdependentObj] = useState({
        less11year: 0,
        less17year: 0,
        less24year: 0,
        less64year: 0,
        more65year: 0
    })
    const [numOfDependents, setnumOfDependents] = useState(0);
    const currencyMultiplier = currValue === "EUR" ? 1 : 84.22 //for INR and EUR options
    const [questionNum, setquestionNum] = useState(1);
    const [ansQ1, setansQ1] = useState("")
    const [ansQ2, setansQ2] = useState("")
    const [optionsforQ2, setoptionsforQ2] = useState(null);
    const [allQanswered, setallQanswered] = useState(false);

    useEffect(()=>{
        document.title="Golden Visa Cost Calculator | Find cost of applying for Golden Visa | Visa Discovery"
    },[])

    useEffect(() => {
        let sumofobj = 0;
        for (let key in dependentObj) {
            sumofobj += Number(dependentObj[key]);
        }
        setnumOfDependents(sumofobj + Number(spouse));
    }, [dependentObj, spouse])

    const optionsforQ1 = [
        "Real Estate Investment (500k)",
        "Real Estate Investment (350k)",
        "Start a Company in Portugal",
        "Capital Investment (1.5M)",
        "Invest in a Qualified Fund (500k)",
        "R&D Investment (350k)",
        "Art, Heritage & Cultural Investment (250k)"
    ];
    const question1block = (
        <Box>
            <Typography gutterBottom variant="h6" color="#2F2FA2" >Type of Investment</Typography>
            <Box className="vdt-options" width="100%" height="auto" flexDirection="column" sx={{ display: 'flex', flexWrap:'nowrap' }} style={{ gap: "1rem", overflowY: "auto", padding: "10px" }}>
                {
                   
                    optionsforQ1.map((option, index) => (
                        <Box
                            key={index}
                            boxShadow="4px 4px 20px #00000029" borderRadius="8px" sx={{ maxWidth: { xs: "100%", md: "none" }, width: { xs: 'auto', md: 'auto' } }}
                            display="flex" alignItems="center" justifyContent="center" style={{ cursor: "pointer", padding: "1rem" }}
                            backgroundColor={option === ansQ1 ? "#2F2FA2" : "white"}
                            textAlign="center"
                            onClick={() => handleansclick(option)}
                        >
                            <Typography variant="body1" fontWeight="normal" color={option === ansQ1 ? "white" : "black"}>{option}</Typography>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )

    const handleansclick = (option) => {
        if (questionNum === 1) {
            if (option === "Real Estate Investment (500k)") {
                setoptionsforQ2(
                    ["Building or urban fraction for commercial purposes, including land for construction",
                        "Building or urban fraction for housing purposes",
                        "Rustic Building"]
                )
                setansQ2("");
            }
            else if (option === "Real Estate Investment (350k)") {
                setoptionsforQ2(
                    ["Building or urban fraction for commercial purposes, including land for construction",
                        "Building or urban fraction for housing purposes",
                        "Rustic Building"]
                )
                setansQ2("");
            }
            else {
                setquestionNum(3);
                setansQ1(option);
                setnoQuestion2(2);
                return;
            }

            setquestionNum(2);
            setansQ1(option);

        }
        else if (questionNum === 2) {
            setquestionNum(3);
            setansQ2(option)
        }
    }
    const handleNextclick = () => {
        if (questionNum === 2) {
            setquestionNum(3);
        } else {
            console.log({ numOfDependents })
            setallQanswered(true);
        }
    }

    const handleChangeSelection=()=>{
        setspouse('0');
        setdependentObj({
            less11year: 0,
            less17year: 0,
            less24year: 0,
            less64year: 0,
            more65year: 0
        });
        setansQ2("");
        setquestionNum(1);
        setTimeout(()=>setansQ1(""),0)  //to make this setState run after the others have run
        setallQanswered(false);
    }

    return (
        <>
            <Box style={{ display: "flex", width: "100vw", maxWidth: "100%" }} sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'initial' } }}>
                <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem', md: '3rem 4rem' } }}>
                    <Box width="100%" height="3.5rem" display="flex" alignItems="center" justifyContent="space-between">
                        <img onClick={() => history.push('/')} src={logoImg} alt="visas for portugal" width="auto" height="100%" style={{ maxWidth: "80%", cursor: 'pointer' }}></img>
                        <Box  style={{ cursor: 'pointer' }} display="flex " width="30%" height="100%" alignItems="center" justifyContent="flex-end">
                            <LanguageSelector/>
                        </Box>
                    </Box>
                    <Box width="100%" marginTop="2.5rem" marginBottom="2rem">
                        <Typography gutterBottom variant="h5">Golden Visa Costs Tool</Typography>
                    </Box>
                    <Box display={allQanswered ? 'none' : 'flex'} alignItems="center" justifyContent="space-between">
                        <Typography gutterBottom variant="overline">Question {questionNum}</Typography>
                        <Typography gutterBottom variant="overline" paddingRight="2rem" style={{ textTransform: "none", color: "#898989" }}>{questionNum} of 3 </Typography>
                    </Box>
                    <Box display={allQanswered ? 'flex' : 'none'} alignItems="center" justifyContent="space-between">
                        <Typography variant="overline">YOUR SELECTION</Typography>
                        <Box display="inline-flex" gap=".5rem" alignItems="center" style={{ cursor: 'pointer' }} onClick={handleChangeSelection}>
                            <ArrowBackIcon style={{ fill: "#898989", fontSize: "1rem" }} />
                            <Typography variant="caption" style={{ textTransform: "none", color: "#898989" }}>Change Selection </Typography>
                        </Box>
                    </Box>
                    <Box display={allQanswered ? 'none' : 'block'}>
                        {
                            questionNum === 1 ? question1block : questionNum === 2 ? <>
                                <Box>
                                    <Typography gutterBottom variant="h6" color="#2F2FA2" >Type of Investment</Typography>
                                    <Box className="vdt-options" width="100%" height="auto" sx={{ display:'flex' }} flexDirection="column" style={{ gap: "1rem", overflowY: "auto", padding: "10px" }}>
                                        {
                                            optionsforQ2?.map((option, index) => (
                                                <Box
                                                    key={index}
                                                    boxShadow="4px 4px 20px #00000029" borderRadius="8px" sx={{ maxWidth: { xs: "100%", md: "none" }, width: { xs: 'auto', md: '100%' } }}
                                                    display="flex" alignItems="center" justifyContent="center" style={{ cursor: "pointer", padding: "1rem" }}
                                                    backgroundColor={option === ansQ2 ? "#2F2FA2" : "white"}
                                                    textAlign="center"
                                                    onClick={() => handleansclick(option)}
                                                >
                                                    <Typography variant="body1" fontWeight="normal" color={option === ansQ2 ? "white" : "black"}>{option}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </> : <Box>
                                <Typography gutterBottom variant="h6" color="#2F2FA2" >Number of Dependents</Typography>
                                <CostQuestion3 spouse={spouse} setspouse={setspouse} dependentObj={dependentObj} setdependentObj={setdependentObj} />
                                {/* <h1>Number of dependents are {numOfDependents}</h1> */}
                            </Box>
                        }
                    </Box>
                    <Box display={allQanswered ? 'flex' : 'none'} flexDirection="row" flexWrap="wrap" gap="1rem" width="100%">
                        {
                            [ansQ1, ansQ2, numOfDependents].map((ele, index) => (
                                <Box
                                    key={index}
                                    border="0.5px solid #898989" borderRadius="8px" padding="1rem" sx={{ flexGrow: "1" }}
                                    display={ele? 'flex':'none'} alignItems="center" justifyContent="center"
                                    textAlign="center"
                                >
                                    <Typography variant="body1" fontWeight="normal">{ele===numOfDependents? ele + " Dependents" : ele}</Typography>
                                </Box>
                            ))
                        }

                    </Box>
                    <Box mt="2rem" width="100%" height="2rem" alignItems="center" justifyContent="space-between" padding="0 2rem" display={questionNum === 1 || allQanswered ? 'none' : "flex"}>
                        <Box width="5rem" height="100%" display="flex" alignItems="center" justifyContent="center" border="0.08px solid #898989" borderRadius="8px" style={{ cursor: 'pointer' }}
                            onClick={() => { if (questionNum === 3) { setquestionNum(questionNum - noQuestion2); return; }; setquestionNum(questionNum - 1); }}>
                            <ArrowBackIcon style={{ fill: "#898989", fontSize: "1rem" }} />
                            <Typography variant="body2" padding="0 4px">Back</Typography>
                        </Box>
                        <Box width="5rem" height="100%" display={(ansQ2 || noQuestion2 === 2) ? "flex" : "none"} alignItems="center" justifyContent="center" border="0.08px solid #898989" borderRadius="8px" style={{ cursor: 'pointer' }}
                            onClick={handleNextclick}
                        >
                            <Typography variant="body2" padding="0 4px">{questionNum === 3 ? "Submit" : "Next"}</Typography>
                            <ArrowForwardIcon style={{ fill: "#898989", fontSize: "1rem" }} />
                        </Box>
                    </Box>
                </Box>
                <Box className="cost-side-box" sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '2rem 1.5rem' }, height: { xs: 'auto', } }} backgroundColor="#F0F0FF" boxShadow="inset 4px 3px 6px #00000029" style={{ position: 'sticky', top: '0px' }}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant='h5'>Costs</Typography>
                        <CurrencyRadioBtn currValue={currValue} setcurrValue={setcurrValue} />
                    </Box>
                    <Box mt="1rem">
                        <CostTableData ansQ1={ansQ1} currencyMultiplier={currencyMultiplier} ansQ2={ansQ2} numOfDependents={numOfDependents} />
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default CostTool
