import React from 'react'
import Box from '@mui/material/Box';
import Header from './Homepage/Header';
import Footer from './Homepage/Footer';
import { Typography } from '@mui/material';

function PrivacyPolicy() {
    React.useEffect(() => {
        document.title = "Privacy Policy | Visa Discovery"
    }, [])
    return (
        <Box width="100vw" maxWidth="100%" paddingTop="1rem">
            <Header />
            <Box sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' } }} margin="auto">
                <Box width="100%" sx={{ mt: { xs: 'calc(12vh + 1rem)', md: "calc(12vh + 2rem)" }, mb: { xs: '2rem', md: '5rem' } }}>
                    <Typography gutterBottom variant="h4">Privacy Policy</Typography>

                    <Typography variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    VisaDiscovery.com ("us", "we", or "our") operates <a href="https://visadiscovery.com">visadiscovery.com</a> (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.
                    </Typography>
                    
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.1    INFORMATION AND COMMUNICATIONS </span>
                    While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name (‘Personal Information’). <br/>
                    By submitting information on <a href="https://visadiscovery.com">visadiscovery.com</a>, you agree to opt in for use of your ‘personal information’ to provide advertising promotion and customer support services through SMS, Email, Alerts, Notifications, IVR, Auto Dialer, Tele-Calling , etc and may be used to customize measure and improve our content and services based on your communication preference. We require this information to provide you with a safe, smooth, efficient and customized service and experience.
                    All the images used on the website are for representation purpose only. We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.<br/>
                    Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites.<br/>
                    Further, you are also at liberty to unsubscribe the above mentioned communication by writing and email to  or by clicking unsubscribe in the said communication received by you.
                    </Typography>
                    
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.2    PRICING INFORMATION</span>
                    While we strives to provide accurate pricing information, pricing or typographical errors may occur. In the event that a product and/or service is mis–priced we may, at our discretion, either contact you for instructions or cancel your order and notify you of such cancellation.
                    We strive to provide you with the best prices possible on <a href="https://visadiscovery.com">visadiscovery.com</a> . However, sometimes a price online may not match the price in a store. In our effort to be the lowest price provider in your particular geographic region, store pricing will sometimes differ from online prices. Prices and availability are subject to change without notice.
                    </Typography>
                   
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.3    DEBIT/CREDIT CARD DETAILS</span>
                    You agree, understand and confirm the submission of details provided to the approved payment gateway by you for availing of products and/or services on <a href="https://visadiscovery.com">visadiscovery.com</a> . You will be liable to provide correct and accurate information and you shall not use the credit/debit card which is not lawfully owned by you, i.e. in a credit/debit card transaction, you must use your own credit card. Further, the said information will not be utilized and shared by us with any of the third parties unless required for fraud verifications or by law, regulation or court order. We will not be liable for any credit/debit card fraud. The liability for use of a card fraudulently will be on you and the onus to ‘prove otherwise’ shall be exclusively on you.
                    </Typography>
                    
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    Further, we shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any transaction, on account of you/cardholder having exceeded the preset limit mutually agreed us with our acquiring bank from time to time.
                    Fraudulent /Declined Transactions:- We reserve the right to recover the cost of goods, delivery charges, collection charges, pilferage charges and lawyers fees from persons using the site fraudulently. We reserve the right to initiate legal proceedings against such persons for fraudulent use of the site and any other unlawful acts or acts or omissions in breach of these terms and conditions.
                    </Typography>
                   
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.4    LOG DATA AND COOKIES</span>
                    Like many site operators, we collect information that your browser sends whenever you visit our Site("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.
                    </Typography>
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.5    DATA SECURITY</span>
                    The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, can be guaranteed as secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                    </Typography>
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.6    CHANGES TO PRIVACY POLICY</span>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site. You are advised to review this Privacy Policy periodically for any changes.
                    </Typography>
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.7 GRIEVANCE OFFICER</span>
                    In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                    Paulo Pereira
                    Email: contact@visadiscovery.com
                    </Typography>
                    <Typography  variant="body2" style={{ textAlign: 'justify' , marginBottom:'16px'}}>
                    <span style={{fontWeight:'700', marginBottom:'4px', display:'block'}}>2.8    CONTACT US</span>
                    If you have any questions about this Privacy Policy, please contact us on contact@visadiscovery.com
                    </Typography>
                </Box>
            </Box>
            <Footer />
        </Box>

    )
}

export default PrivacyPolicy