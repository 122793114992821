import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import blog1img from "../Images/best-place-for-remote-work.jpg";
import Grid from "@mui/material/Grid";
import Bookconsultation from "./Common Components/Bookconsultation";
import blog2img from "../Images/best-place-to-retire.jpg";
import externallinkicon from "../Images/External Link - Blue.svg";
import articleicon from "../Images/Article Icon - Grey.svg";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import blog3img from "../Images/work-in-portugal.jpg";
import blog4img from "../Images/digital-nomad-visa-portugal.jpg";
import blog5img from "../Images/portugal-digital-nomad-visa-available.jpg";
import BlogCard from "./BlogCard";
import Dialog from "@mui/material/Dialog";
import PopupForm from "./Common Components/PopupForm.js";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";

function Article5() {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    document.title =
      "Apply for Portugal Digital Nomad Visa Now - Visa Discovery";
  }, []);
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id":
                "https://visadiscovery.com/portugal-s-much-anticipated-digital-nomad-visa-is-now-available",
            },
            headline: "Apply for Portugal Digital Nomad Visa Now",
            description:
              "Digital nomads or remote workers can now apply for Portugal digital nomad visa, which will allow them to live and work lawfully in Portugal, starting on October 30th, 2022. Learn more.",
            image:
              "https://visadiscovery.com/static/media/portugal-digital-nomad-visa-available.225300b5.jpg",
            author: {
              "@type": "Organization",
              name: "Visa Discovery",
              url: "https://visadiscovery.com",
            },
            publisher: {
              "@type": "Organization",
              name: "",
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            datePublished: "",
          })}
        </script>
      </Helmet>
      <Box width="100vw" maxWidth="100%" paddingTop="1rem">
        <Header />
        <Box
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          }}
          margin="auto"
        >
          <Box
            display="flex"
            width="100%"
            sx={{
              mt: { xs: "calc(12vh + 1rem)", md: "calc(12vh + 2rem)" },
              flexDirection: { xs: "column", md: "row" },
              mb: { xs: "2rem", md: "5rem" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "calc(100% - 14rem)" },
                paddingRight: { xs: "0", md: "3rem" },
              }}
            >
              <Typography gutterBottom variant="overline">
                News & Updates
              </Typography>
              <Typography gutterBottom variant="h4">
                Much-awaited Portugal's Digital Nomad Visa is now available!
              </Typography>
              <img
                src={blog5img}
                className="blog-img"
                alt="apply for portugal digital nomad visa"
              ></img>
              <Typography
                gutterBottom
                variant="body2"
                sx={{ paddingTop: { xs: ".5rem", md: "3rem" } }}
                style={{ textAlign: "justify" }}
              >
                Remote workers can now apply for Portugal's Digital Nomad Visa,
                which will allow them to live and work lawfully in Portugal,
                starting on October 30th, 2022. Candidates must make at least
                €2,800 per month, or four times Portugal's minimum wage, to be
                eligible for the program.
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                Digital nomads, often known as remote workers, can now apply for
                either a one-year temporary stay visa or a five-year residency
                permit. Applications may be processed either at SEF or any
                Portuguese Consulate (Servico de Estrangeiros e Fronteiras).
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                In order to be eligible for the new visa, remote employees must
                present evidence of self-employment or a contract of employment,
                documents proving their income for the past 90 days, and proof
                of their tax residency.
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                This new Portugal's Digital Nomad Visa is a replacement for the
                current "D7" visa, a well-liked residency permit primarily
                intended for retirees and others with "passive income." The
                minimum annual income criterion for the D7 visa is substantially
                lower, at €7,200, and comes from "passive" income sources like
                rent or investments.
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                Requirements for obtaining a digital nomad visa:
                <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <li>Documents demonstrating tax residency</li>
                  <li> Proof of work </li>
                  <li>
                    Documents demonstrating the Proof of income for the previous
                    90 days{" "}
                  </li>
                  <li> Documents demonstrating tax residency</li>
                </ul>
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ textAlign: "justify" }}
              >
                Application forms are available at{" "}
                <a
                  href="https://www.sef.pt/pt/Pages/homepage.aspx"
                  target="_blank"
                >
                  SEF
                </a>{" "}
                or any Portuguese Consulate worldwide.
              </Typography>
              <Box sx={{ margin: "0 auto", width: "min-content", mt: "1rem" }}>
                <Button
                  type="submit"
                  style={{
                    width: "230px",
                    height: "3rem",
                    borderRadius: ".5rem",
                    backgroundColor: "#2F2FA2",
                    color: "white",
                    fontSize: "1rem",
                  }}
                  onClick={() => setOpen(true)}
                >
                  {"Request Consultation"}
                </Button>
              </Box>
              <Box
                mt="2rem"
                mb="1rem"
                paddingRight="1rem"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">Related Articles</Typography>
                {/* <Box style={{ float: 'right', cursor: 'pointer' }} sx={{
                                    width: '75px'
                                }} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" color="#2F2FA2 !important">View All</Typography>
                                    <img src={arrowsvgblue} alt="arrow" style={{ width: "1rem" }}></img>
                                </Box> */}
              </Box>
              <Grid
                container
                spacing={2}
                sx={{ overflow: "auto", flexWrap: "nowrap" }}
              >
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/agreement-for-recruitment-of-indian-worker-signed-between-india-portugal"
                    file={blog3img}
                    altText="recruitment of indian workers"
                    title="An agreement on the recruitment of Indian workers is signed
                                  between Portugal and India"
                    content={`India has been signing agreements with a number of countries
                                  lately, and now it seems they have their eyes set on Portugal. The
                                  two nations signed an accord that will allow Indian citizens to
                                  work in Portugal as well - opportunities for everyone!`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/portugal-ranks-4th-for-best-place-to-retire"
                    file={blog2img}
                    altText="best country for retirement"
                    title="Portugal Ranks 4th best country in the world for retirement."
                    content={`Portugal ranks fourth on this list of the ten best countries for
                                    retired folk. It's not hard to see why; with its warm climate,
                                    beautiful scenery and rich culture it would be easy enough pack up
                                    your bags once retirement hits and head off into south-west Europe
                                    where you'll find yourself living happily ever after!`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/portugal-ranks-1st-for-remote-work"
                    file={blog1img}
                    altText="best country for remote work"
                    title=" Portugal Ranks 1st as the best country in the world for remote work."
                    content={`Travel search engine Momondo has created a “travel work index” to
                                            help digital nomads find the best countries for productive working
                                            conditions and opportunities on vacation. Portugal ranks 1st out
                                            of ten in this category, with its climate (low crime rate)
                                            combined with affordable cost-of living factors making it an
                                            attractive destination abroad even if you're not looking
                                            specifically beyond scenery!`}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BlogCard
                    link="/digital-nomad-visa-for-remote-workers"
                    file={blog4img}
                    altText="portugal digital nomad visa"
                    title="Portugal introduces a Digital Nomad Visa for remote workers."
                    content={`Are you a digital nomad looking to escape to warmer shores before winter locks down? Then, here is your chance! Portugal has announced a work visa for remote employees, which might allow digital nomads to reside and work there for up to 12 months.`}
                    height="100%"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "14rem" },
                mt: { xs: "2rem", md: "0rem" },
              }}
            >
              <Bookconsultation />
              <Box mt="2rem">
                <Typography color="#2F2FA2 !important" variant="h5">
                  Portugal
                </Typography>
                <Box mt="1rem" width="16rem">
                  <Box className="side-section1" width="100%">
                    <Link
                      to={{
                        pathname:
                          "https://www.visitportugal.com/en/sobre-portugal/biportugal",
                      }}
                      target="_blank"
                    >
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h7"
                          fontWeight="700"
                          mr="1rem"
                          sx={{ color: "#111111" }}
                        >
                          About Portugal
                        </Typography>
                        <img src={externallinkicon} alt="arrow"></img>
                      </Box>
                    </Link>
                    <Typography variant="body2" gutterBottom>
                      Everything you need to know about Portugal.
                    </Typography>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body2"
                        color="#898989 !important"
                        whiteSpace="nowrap"
                        mr="8px"
                      >
                        2 min read
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Box display="flex" ml="8px">
                        <Typography variant="body2" color="#898989 !important">
                          Article
                        </Typography>
                        <img
                          src={articleicon}
                          alt="arrow"
                          style={{ marginLeft: ".5rem", width: "1rem" }}
                        ></img>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="side-section2" width="100%" mt="1rem">
                    <Link
                      to={{ pathname: "https://www.visitportugal.com/en" }}
                      target="_blank"
                      style={{ color: "#111111" }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="h7" fontWeight="700" mr="1rem">
                          Visit Portugal
                        </Typography>
                        <img src={externallinkicon} alt="arrow"></img>
                      </Box>
                    </Link>
                    <Typography variant="body2" gutterBottom>
                      Comprehensive information on everything required to get
                      around in Portugal
                    </Typography>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body2"
                        color="#898989 !important"
                        whiteSpace="nowrap"
                        mr="8px"
                      >
                        2 min read
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Box display="flex" ml="8px">
                        <Typography variant="body2" color="#898989 !important">
                          Article
                        </Typography>
                        <img
                          src={articleicon}
                          alt="arrow"
                          style={{ marginLeft: ".5rem", width: "1rem" }}
                        ></img>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="side-section3" width="100%" mt="1rem">
                    <Link
                      to={{
                        pathname:
                          "https://atlanticbridge.com.br/wp-content/uploads/2019/09/Complete-Guide-to-Real-Estate-Investment-in-Portugal-DS.pdf",
                      }}
                      target="_blank"
                      style={{ color: "#111111" }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="h7" fontWeight="700" mr="1rem">
                          Invest in Portugal
                        </Typography>
                        <img src={externallinkicon} alt="arrow"></img>
                      </Box>
                    </Link>

                    <Typography variant="body2" gutterBottom>
                      A guide to investing in Real Estate in Portugal
                    </Typography>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body2"
                        color="#898989 !important"
                        whiteSpace="nowrap"
                        mr="8px"
                      >
                        2 min read
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Box display="flex" ml="8px">
                        <Typography variant="body2" color="#898989 !important">
                          Article
                        </Typography>
                        <img
                          src={articleicon}
                          alt="arrow"
                          style={{ marginLeft: ".5rem", width: "1rem" }}
                        ></img>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiBackdrop-root": {
            opacity: "0.4 !important",
            backgroundColor: "#2F2FA2",
          },
          zIndex: "99002",
          "& .MuiDialog-paper": {
            maxWidth: "none",
            borderRadius: { xs: "1rem", md: "1.5rem" },
            maxHeight: { xs: "none", md: "75vh" },
            width: { xs: "-webkit-fill-available", md: "auto" },
          },
        }}
      >
        <PopupForm
          title="Portugal's Digital Nomad Visa Blog"
          openSetterFn={setOpen}
        ></PopupForm>
      </Dialog>
    </>
  );
}

export default Article5;
