import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import '../../Styling/desktop/homepage.css';
import '../../Styling/mobile/homepagemobile.css'
import '../../Styling/desktop/commoncomp.css';
import uptodatepic from '../../Images/VD Icons/Up to date information.svg'
import endsupportpic from '../../Images/VD Icons/End to End Support.svg';
import localexptpic from '../../Images/VD Icons/Local Expertise.svg';
import investmentAdvisorypic from '../../Images/VD Icons/Investment Advisory.svg';

function Homesection3() {
    const card1 = (
        <Card className="cardhoverstyle" sx={{ width: { xs: '150px', md: '150px' }, mt: { xs: '2rem', md: '0' } }} style={{ height: "150px", borderRadius: "1rem", backgroundColor: "white" }}>
            <CardContent width="100%" style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box className="icon-box-home-about">
                    <img src={uptodatepic} alt="up-to-date information - portugal visas" ></img>
                </Box>
                <Typography variant="body2" style={{ marginTop: 'auto' }}>Up-to-date Information</Typography>
            </CardContent>
        </Card>
    )
    const card2 = (
        <Card className="cardhoverstyle" sx={{ width: { xs: '150px', md: '150px' }, mt: { xs: '2rem', md: '0' } }} style={{ height: "150px", borderRadius: "1rem", backgroundColor: "white" }}>
            <CardContent width="100%" style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box className="icon-box-home-about">
                    <img src={endsupportpic} alt="portugal visa online support" ></img>
                </Box>
                <Typography variant="body2" style={{ marginTop: 'auto' }}>End-to-End Support</Typography>
            </CardContent>
        </Card>
    )
    const card3 = (
        <Card className="cardhoverstyle" sx={{ width: { xs: '150px', md: '150px' }, mt: { xs: '2rem', md: '0' } }} style={{ height: "150px", borderRadius: "1rem", backgroundColor: "white" }}>
            <CardContent width="100%" style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box className="icon-box-home-about">
                    <img src={localexptpic} alt="portugal visa process" ></img>
                </Box>
                <Typography variant="body2" style={{ marginTop: 'auto' }}>Local Expertise</Typography>
            </CardContent>
        </Card>
    )
    const card4 = (
        <Card className="cardhoverstyle" sx={{ width: { xs: '150px', md: '150px' }, mt: { xs: '2rem', md: '0' } }} style={{ height: "150px", borderRadius: "1rem", backgroundColor: "white" }}>
            <CardContent width="100%" style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box className="icon-box-home-about">
                    <img src={investmentAdvisorypic} alt="portugal visa by investment" ></img>
                </Box>
                <Typography variant="body2" style={{ marginTop: 'auto' }}>Investment Advisory</Typography>
            </CardContent>
        </Card>
    )
    return (
        <Box className="home-section3" width="100vw" maxWidth="100%">
            <Box
                sx={{
                    height: { xs: 'auto', md: 'auto' },
                    padding: { xs: '', md: '3rem 1.5rem' }, maxWidth: { xs: '100%', md: 'min(100vw, 1200px)' },
                    margin: '0 auto'
                }}
                 display="flex" flexDirection="column" justifyContent="space-around">
                <Box sx={{ width: { xs: '75%', md: '50%' } }} margin="auto" marginTop="3rem" height="25%" display="flex" textAlign="center" flexDirection="column" justifyContent="space-evenly">
                    <Typography variant="h4" color="var(--tblue) " >About Visa Discovery
                    </Typography>
                    <Typography sx={{ mt: { xs: '2rem', md: '1rem' } }} variant="body2">We started Visa Discovery as a means to simplify the process of
                        understanding and applying for any type of visa for Portugal. Being Portuguese nationals
                        with deep personal and business ties in India, we have seen a lot of problems and scams in
                        the Visa process in India.
                    </Typography>
                    <Typography sx={{ mt: { xs: '1rem', md: '0' } }} variant="body2">We hope to help you simplify your travel to Portugal.</Typography>
                </Box>
                <Box sx={{ paddingTop: { xs: '1rem', md: '4rem' }, height: { xs: 'auto', md: '40%' }, }} width="100%" display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-around">
                    {card1}
                    {card2}
                    {card3}
                    {card4}
                </Box>
                <Box width="100%" sx={{ height: { xs: 'auto', md: '35%' }, padding: { xs: '4rem 1.5rem 1rem 1.5rem', md: '3rem 0 0 0' } }}>
                    <Typography variant="overline">PARTNERS</Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                        <Box className="home-section3-logo" />
                        <Box className="home-section3-logo" />
                        <Box className="home-section3-logo" />
                        <Box className="home-section3-logo" />
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

export default Homesection3
