import React from 'react'
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Button from '@mui/material/Button';
import '../../Styling/desktop/homepage.css'
import '../../Styling/mobile/homepagemobile.css'
import logoimg from '../../Images/Visa Discovery Logo desktop.png'
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router-dom';

function Footer() {
    const history = useHistory();
    return (
        <footer className="footer-container">
            <Grid container spacing={1} columnSpacing={{ xs: 1 }} sx={{ ml: { xs: '0rem' } }}>
                <Grid item sx={{ display: { xs: "block", md: "none" }, mb: "1rem" }} xs={12}>
                    <Typography variant="body2" color="white" fontSize="1rem" fontWeight="bold">Subscribe to our Newsletter</Typography>
                    <form>
                        <input type="text" required style={{ width: "95%", height: "2.5rem", borderRadius: '.5rem', marginBottom: '.5rem' }}></input>
                        <Button type="submit" style={{ width: "95%", height: "2.5rem", borderRadius: '.5rem', backgroundColor: "#2F2FA2", color: 'white' }}>Subscribe</Button>
                    </form>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Typography gutterBottom variant="body2" color="white" fontSize="1rem" fontWeight="bold">Information</Typography>
                    {/* <Typography style={{cursor: 'pointer'}} gutterBottom variant="body2" color="white">Who needs Visa?</Typography> */}
                    <Link to={"/know-your-rights"}>
                        <Typography  gutterBottom variant="body2" color="white">Know Your Rights</Typography>
                    </Link>
                    <Link to={'/knowledge-center'}>
                        <Typography  gutterBottom variant="body2" color="white">Knowledge Centre</Typography>
                    </Link>
                    <Link to={'/news'}>
                        <Typography  gutterBottom variant="body2" color="white">News</Typography>
                    </Link>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Typography gutterBottom variant="body2" color="white" fontSize="1rem" fontWeight="bold">Visas</Typography>
                    <Link to={'/visa-discovery-tool'}>
                        <Typography gutterBottom variant="body2" color="white">Visa Discovery Tool</Typography>
                    </Link>
                    <Link to={'/'}>
                        <Typography  gutterBottom variant="body2" color="white">Type of Visas</Typography>
                    </Link>
                    <Link to={'/portugal-short-stay-visa'}>
                        <Typography gutterBottom variant="body2" color="white">Short Stay Visas</Typography>
                    </Link>
                    <Link to={'/portugal-temporary-stay-visa'}>
                        <Typography gutterBottom variant="body2" color="white">Temporary Stay Visas</Typography>
                    </Link>
                    <Link to={'/portugal-residency-visa'}>
                        <Typography gutterBottom variant="body2" color="white">Residency Visas</Typography>
                    </Link>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Typography gutterBottom variant="body2" color="white" fontSize="1rem" fontWeight="bold">Company</Typography>
                    <Link to={'/about-us'}>
                        <Typography  gutterBottom variant="body2" color="white">About Us</Typography>
                    </Link>
                    <Link to={"/contact-us"}>
                        <Typography  gutterBottom variant="body2" color="white">Contact Us</Typography>
                    </Link>
                    <Link to={'/privacy-policy'}>
                        <Typography  gutterBottom variant="body2" color="white">Privacy Policy</Typography>
                    </Link>
                    <Link to={'/terms-and-condition'}>
                        <Typography  gutterBottom variant="body2" color="white">Terms of Service</Typography>
                    </Link>
                    {/* <Typography  gutterBottom variant="body2" color="white">Book a Consultation</Typography> */}
                </Grid>
                <Grid item xs={6} md={2}>
                    <Typography gutterBottom variant="body2" color="white" fontSize="1rem" fontWeight="bold">Popular Visas</Typography>
                    <Link to={'/golden-visa-to-portugal'}>
                        <Typography  gutterBottom variant="body2" color="white">Golden Visa</Typography>
                    </Link>
                    <Link to={'/work-visa-in-portugal'}>
                        <Typography  gutterBottom variant="body2" color="white" >Work Visa</Typography>
                    </Link>
                    <Link to={'/portugal-tech-visa'}>
                        <Typography  gutterBottom variant="body2" color="white" >Tech Visa</Typography>
                    </Link>
                    <Link to={'/portugal-startup-visa'}>
                        <Typography  gutterBottom variant="body2" color="white">Startup Visa</Typography>
                    </Link>
                    <Link to={'/portugal-green-visa'}>
                        <Typography  gutterBottom variant="body2" color="white" >Green Visa</Typography>
                    </Link>
                </Grid>
                <Grid item sx={{ display: { xs: 'none', md: 'block' } }} xs={3}>
                    <Typography variant="body2" color="white" fontSize="1rem" fontWeight="bold">Subscribe to our Newsletter</Typography>
                    <form>
                        <input type="text" required style={{ width: "95%", height: "2.5rem", borderRadius: '.5rem', marginBottom: '.5rem' }}></input>
                        <Button type="submit" style={{ width: "95%", height: "2.5rem", borderRadius: '.5rem', backgroundColor: "#2F2FA2", color: 'white' }}>Subscribe</Button>
                    </form>
                </Grid>
            </Grid>
            <Box sx={{
                margin: 'auto',
                mt: { xs: '4rem', md: '3rem' },
            }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly">
                <Box sx={{
                    width: { xs: '80%', md: '30%' },
                    height: "auto"
                }} onClick={() => history.push('/')}>
                    <img src={logoimg} width="100%" height="100%" alt="visas for portugal"></img>
                </Box>
                <Box onClick={() => history.push('/')}>
                    <Typography variant="body2" style={{ color: 'white' }}>© Visa Discovery - {new Date().getFullYear()}</Typography>
                </Box>
            </Box>
        </footer>
    )
}

export default Footer
