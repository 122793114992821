import React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
function UpdatesCheckbox({receiveUpdatecheckbox, setreceiveUpdatecheckbox}) {
    return (
        <Box mb="10px">
            <FormControlLabel sx={{
                '& .MuiTypography-root': {
                    fontFamily: 'Source Sans Pro', fontWeight: '400',
                    fontSize: '1rem', letterSpacing: '0px', color: '#111111', lineHeight: '1.43'
                }
            }} control={<Checkbox checked={receiveUpdatecheckbox}
                onChange={(e) => setreceiveUpdatecheckbox(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }} />}
                label="I agree to receive a few emails about updates, offers and some relevant news about the market."
            />
        </Box>
    )
}

export default UpdatesCheckbox