import React, {useEffect} from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Header from './Homepage/Header'
import Footer from './Homepage/Footer'
import '../Styling/desktop/legalpage.css'
function Legalpage(){
    useEffect(()=>{
        document.title="Legal Page | Visa Discovery"
      },[])
    return (
        <Box width="100vw" maxWidth="100%" paddingTop="1rem">
            <Header />
            <Box className="legal-cover-container" mt="-1rem" width="100%" height="45vh" textAlign="center">
                <Typography variant="h4" color="white" paddingTop="30vh ">Terms & Conditions</Typography>
            </Box>
            <Box sx={{ width: { xs: 'calc(100vw - 3rem)', md: 'calc(100vw - 12rem)' }, mt: '2rem', mb: '2rem' }} margin="auto">
                <Typography mb="1rem" variant="h4" ml="0px important">Policy Title</Typography>
                <Typography variant="h7" fontWeight="700" mb=".5rem">Topic 1</Typography>
                <Typography variant="body2" style={{ textAlign: 'justify' }}>
                    VIS (Visa Information System) is a data exchange system between the Schengen Member States on visas. These countries share a freedom of movement common area with no internal border control. A common visa policy is applied in the area, requiring information sharing on visa applicants. The specific objectives of the VIS are to facilitate visa procedures, contribute to increasing security in the process, and facilitate visa control at the Schengen area external borders. Biometric data of the visa applicant will be collected (10 fingerprints scan and digital photograph) in a simple and discrete procedure that will last a couple of minutes. Biometric data, jointly with data gathered in the visa form, will be registered in a central database. The data will be kept in the VIS for 5 years. Any person has the right to be informed of their VIS data. Any person may request for inaccurate data on the VIS to be corrected and for data collected illegally to be removed from the system, through submission of the respective form at the consular post of the area of residency or through the email address vistos@mne.pt. The National Commission on Data Protection (Comissão Nacional de Protecção de Dados (CNDP) - Av. D. Carlos I nº. 134 – 3º, 1200-651 Lisbon, Tel: +351 213928400 - Fax: +351 213976832, e-mail geral@cnpd.pt , who is the national authority in this regard, will log complaints concerning data protection of personal data. For further information refer to the links from the NCDP on access to Schengen and on your VIS rights. For further information on the VIS, refer to the available information of the European Commission.
                </Typography>
                <Typography variant="h7" fontWeight="700" mb=".5rem" mt="1rem" display="inline-block">Topic 2</Typography>
                <Typography variant="body2" style={{ textAlign: 'justify' }}>
                    VIS (Visa Information System) is a data exchange system between the Schengen Member States on visas. These countries share a freedom of movement common area with no internal border control. A common visa policy is applied in the area, requiring information sharing on visa applicants. The specific objectives of the VIS are to facilitate visa procedures, contribute to increasing security in the process, and facilitate visa control at the Schengen area external borders. Biometric data of the visa applicant will be collected (10 fingerprints scan and digital photograph) in a simple and discrete procedure that will last a couple of minutes. Biometric data, jointly with data gathered in the visa form, will be registered in a central database. The data will be kept in the VIS for 5 years. Any person has the right to be informed of their VIS data. Any person may request for inaccurate data on the VIS to be corrected and for data collected illegally to be removed from the system, through submission of the respective form at the consular post of the area of residency or through the email address vistos@mne.pt. The National Commission on Data Protection (Comissão Nacional de Protecção de Dados (CNDP) - Av. D. Carlos I nº. 134 – 3º, 1200-651 Lisbon, Tel: +351 213928400 - Fax: +351 213976832, e-mail geral@cnpd.pt , who is the national authority in this regard, will log complaints concerning data protection of personal data. For further information refer to the links from the NCDP on access to Schengen and on your VIS rights. For further information on the VIS, refer to the available information of the European Commission.
                </Typography>
            </Box>
            <Footer/>
        </Box>
    )
}

export default Legalpage