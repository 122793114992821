import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Bookconsultation from "./Common Components/Bookconsultation";
import Wheretoapply from "./Common Components/Wheretoapply";
import Header from "./Homepage/Header";
import Homesection5mid from "./Homepage/Homesection5mid";
import Footer from "./Homepage/Footer";
// import Additionalservices from './Common Components/Additionalservices';
import Shortstaycard from "../Components/Short stay visa page/Shortstaycard";
import { useHistory } from "react-router-dom";
import "../Styling/desktop/visastyle.css";
import culturalpic from "../Images/residency visa/residency cultural visa.jpg";
import qualifiedteachingpic from "../Images/residency visa/residency stay highly qualified teaching.jpg";
import internpic from "../Images/residency visa/residency stay intern.jpg";
import indeworkpic from "../Images/residency visa/residency visa D2 - Independent Work Purposes.jpg";
import enterpic from "../Images/residency visa/residency visa entrepreneurship.jpg";
import nomadpic from "../Images/residency visa/residency visa nomad.jpg";
import religiouspic from "../Images/residency visa/residency visa religious purpose.jpg";
import researchpic from "../Images/residency visa/residency visa research.jpg";
import studentexpic from "../Images/residency visa/temp stay E6 - Student Exchange.jpg";
import volunteerpic from "../Images/residency visa/temp stay volunteer.jpg";
import highqactivitypic from "../Images/residency visa/temp visa E4 - Highly Qualified Activity Visa.jpg";
import D7pic from "../Images/D7 - Senior Citizens.jpg";
import D4pic from "../Images/D4 - Research_Study.jpg";
import D6pic from "../Images/D6 - Family Reunification.jpg";
import goldenpic from "../Images/Golden Visa.jpg";
import techpic from "../Images/Tech Visa.jpg";
import workpic from "../Images/Work Visa.jpg";
import startuppic from "../Images/Start Up Visa.jpg";
import subordinatepic from "../Images/Subordinate Professional Activity.jpg";
import { Helmet } from "react-helmet";

function Residencyvisa() {
  const history = useHistory();
  useEffect(() => {
    document.title = "Portugal Residency Visa| Visa Discovery";
  }, []);
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Residency Visas",
                item: "https://visadiscovery.com/portugal-residency-visa",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" padding="1rem 0 0 0" maxWidth="100%">
        <Header />
        <Box
          className="visas-container"
          width="100%"
          mt="calc(12vh + 1rem)"
          display="flex"
          sx={{
            padding: { xs: "0rem 1.5rem 3rem" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "80%" },
              padding: { xs: "0rem 0 3rem", md: "0rem 2rem 0 0" },
            }}
            height="100%"
          >
            <Typography gutterBottom variant="h1" fontSize="4.5rem">
              Residency Visas
            </Typography>
            <Typography gutterBottom variant="body2">
              If you are looking to relocate to Portugal, these visas are for
              you. Through this, you get a Portuguese National Visa, which
              allows you to stay in Portugal for more than 1 year.
            </Typography>
            <Typography gutterBottom variant="body2">
              These visas also put you on track to receive a Portuguese
              Citizenship.
            </Typography>

            <Typography
              gutterBottom
              variant="h4"
              color="#2F2FA2"
              fontWeight="bold"
              mt="1rem"
            >
              Type of Residency Visas
            </Typography>
            <Box>
              <Grid container spacing={2} rowSpacing={6}>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    path="/golden-visa-to-portugal"
                    altText="golden visa to portugal"
                    pic={goldenpic}
                    tagName="Visa D9"
                    title="Golden Visa"
                    day="Residency"
                    cost="View Fee Calculator"
                    description="D9 - Portuguese Golden Visa"
                    applicationtime="90 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    path="/portugal-tech-visa"
                    pic={techpic}
                    title="Tech Visa"
                    altText="portugal tech visa"
                    tagName="Visa D2"
                    day="Residency"
                    cost="EUR 90"
                    description="D2 Independent Professional Activity/ Entrepreneur Immigrants/ Start-up Visa"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    path="/work-visa-in-portugal"
                    pic={workpic}
                    title="Work Visa"
                    altText="work visa in portugal"
                    tagName="Visa D2"
                    day="Residency"
                    cost="EUR 90"
                    description="D2 Independent Professional Activity/ Entrepreneur Immigrants/ Start-up Visa"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    path="/portugal-startup-visa"
                    altText="portugal startup visa"
                    pic={startuppic}
                    tagName="Visa D2"
                    title="Startup Visa"
                    day="Residency"
                    cost="EUR 90"
                    description="D2 Independent Professional Activity/ Entrepreneur Immigrants/ Start-up Visa"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    path="/portugal-green-visa"
                    altText="portugal green visa"
                    pic={goldenpic}
                    tagName="Visa D9"
                    title="Green Visa"
                    day="Residency"
                    cost="View Fee Calculator"
                    description="D9 - Portuguese Golden Visa"
                    applicationtime="90 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={subordinatepic}
                    title="Subordinate Professional Activity"
                    altText="residency visa portugal"
                    tagName="Visa D1"
                    day="Residency"
                    cost="EUR 90"
                    description="D1 - Subordinated Professional Activity"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={nomadpic}
                    title="Nomad Visa"
                    altText="portugal nomad visa"
                    tagName="Visa D2"
                    day="Residency"
                    cost="EUR 90"
                    description="D2 Independent Professional Activity/ Entrepreneur Immigrants/ Start-up Visa"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={D6pic}
                    title="Family Reunification"
                    altText="portugal residency visa"
                    tagName="Visa D6"
                    day="Residency"
                    cost="EUR 90"
                    description="D6 - Family Reunification"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={D7pic}
                    tagName="Visa D7"
                    title="Retirement Purposes"
                    altText="portugal residency visa after retirement"
                    day="Residency"
                    cost="EUR 90"
                    description="D7 - Senior Citizens/ Religious Immigrants/ People Living From Rents And Investments"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={religiouspic}
                    tagName="Visa D7"
                    title="Religious Purposes"
                    altText="portugal residency visa"
                    day="Residency"
                    cost="EUR 90"
                    description="D7 - Senior Citizens/ Religious Immigrants/ People Living From Rents And Investments"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={D7pic}
                    tagName="Visa D7"
                    title="People living out of individual revenue"
                    altText="portugal residence visa for indian"
                    day="Residency"
                    cost="EUR 90"
                    description="D7 - Senior Citizens/ Religious Immigrants/ People Living From Rents And Investments"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={indeworkpic}
                    title="Independent Work Purposes"
                    altText="d2 visa portugal"
                    tagName="Visa D2"
                    day="Residency"
                    cost="EUR 90"
                    description="D2 Independent Professional Activity/ Entrepreneur Immigrants/ Start-up Visa"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={enterpic}
                    title="Entrepreneurship"
                    altText="portugal d2 visa"
                    tagName="Visa D2"
                    day="Residency"
                    cost="EUR 90"
                    description="D2 Independent Professional Activity/ Entrepreneur Immigrants/ Start-up Visa"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={researchpic}
                    title="Research"
                    altText="portugal research visa"
                    tagName="Visa D4"
                    day="Residency"
                    cost="EUR 90"
                    description="D4 - Research/ Study/ Internship"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={D4pic}
                    title="Study"
                    altText="study visa portugal"
                    tagName="Visa D4"
                    day="Residency"
                    cost="EUR 90"
                    description="D4 - Research/ Study/ Internship"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={internpic}
                    title="Internship"
                    altText="portugal study visa"
                    tagName="Visa D4"
                    day="Residency"
                    cost="EUR 90"
                    description="D4 - Research/ Study/ Internship"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={studentexpic}
                    title="High School Student Exchange"
                    altText="student visa portugal"
                    tagName="Visa D4"
                    day="Residency"
                    cost="EUR 90"
                    description="D4 - Research/ Study/ Internship"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={volunteerpic}
                    title="Volunteer Work Purposes"
                    altText="volunteer work visa"
                    tagName="Visa D4"
                    day="Residency"
                    cost="EUR 90"
                    description="D4 - Research/ Study/ Internship"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={highqactivitypic}
                    title="Highly Qualified Activity"
                    altText="portugal d3 visa"
                    tagName="Visa D3"
                    day="Residency"
                    cost="EUR 90"
                    description="D3 University Teaching Position/ Highly Qualified Activity/ Cultural Activity"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={qualifiedteachingpic}
                    title="Higher Education Teaching"
                    altText="portugal visa for higher education"
                    tagName="Visa D3"
                    day="Residency"
                    cost="EUR 90"
                    description="D3 University Teaching Position/ Highly Qualified Activity/ Cultural Activity"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={culturalpic}
                    title="Cultural Visa"
                    altText="cultural visa portugal"
                    tagName="Visa D3"
                    day="Residency"
                    cost="EUR 90"
                    description="D3 University Teaching Position/ Highly Qualified Activity/ Cultural Activity"
                    applicationtime="60 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    title="Highly qualified subordinate activity"
                    altText="d3 visa portugal"
                    tagName="Visa D3"
                    day="Residency"
                    cost="EUR 90"
                    description="D3 University Teaching Position/ Highly Qualified Activity/ Cultural Activity"
                    applicationtime="60 days"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "20%" },
              mb: { xs: "1rem", md: "1rem" },
            }}
          >
            <Bookconsultation fixed="fixed" title="Residency Stay Visa Page" />
          </Box>
        </Box>
        <Wheretoapply />
        {/* <Additionalservices /> */}
        <Homesection5mid />
        <Footer />
      </Box>
    </>
  );
}

export default Residencyvisa;
