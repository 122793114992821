import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Bookconsultation from "./Common Components/Bookconsultation";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import "./../Styling/desktop/visastyle.css";
import BlogCard from "./BlogCard";
import blog1img from "../Images/best-place-for-remote-work.jpg";
import blog2img from "../Images/best-place-to-retire.jpg";
import blog3img from "../Images/work-in-portugal.jpg";
import blog4img from "../Images/digital-nomad-visa-portugal.jpg";
import blog5img from "../Images/portugal-digital-nomad-visa-available.jpg";
import { Helmet } from "react-helmet";

function News() {
  useEffect(() => {
    document.title = "News | Visa Discovery";
  }, []);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "News",
                item: "https://visadiscovery.com/news",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" padding="1rem 0 0 0" maxWidth="100%">
        <Header />
        <Box
          className="visas-container"
          width="100%"
          mt="calc(12vh + 1rem)"
          display="flex"
          sx={{
            padding: { xs: "0rem 1.5rem 3rem" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "80%" },
              padding: { xs: "0rem 0 3rem", md: "0rem 2rem 0 0" },
            }}
            height="100%"
          >
            <Typography gutterBottom variant="h1" fontSize="4.5rem">
              News
            </Typography>
            <Typography gutterBottom variant="body2">
              The latest information on Portugal visas, eligibility and
              requirements for Portugal visas, how to apply for Portugal visa,
              and more.
            </Typography>
            <Box sx={{ minHeight: "16px" }}></Box>
            <Box>
              <Grid container spacing={2} rowSpacing={6}>
                <Grid item sm={6} md={6} lg={4}>
                  <BlogCard
                    link="/portugal-s-much-anticipated-digital-nomad-visa-is-now-available"
                    file={blog5img}
                    altText="apply for portugal digital nomad visa"
                    title="Much-awaited Portugal's Digital Nomad Visa is now available!"
                    content={`Remote workers can now apply for Portugal's Digital Nomad Visa,
                                        which will allow them to live and work lawfully in Portugal,
                                        starting on October 30th, 2022. Candidates must make at least
                                        €2,800 per month, or four times Portugal's minimum wage, to be
                                        eligible for the program.`}
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <BlogCard
                    link="/portugal-ranks-4th-for-best-place-to-retire"
                    file={blog2img}
                    altText="best country for retirement"
                    title="Portugal Ranks 4th best country in the world for retirement."
                    content={`Portugal ranks fourth on this list of the ten best countries for
                      retired folk. It's not hard to see why; with its warm climate,
                      beautiful scenery and rich culture it would be easy enough pack up
                      your bags once retirement hits and head off into south-west Europe
                      where you'll find yourself living happily ever after!`}
                    height="100%"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <BlogCard
                    link="/digital-nomad-visa-for-remote-workers"
                    file={blog4img}
                    altText="portugal digital nomad visa"
                    title="Portugal introduces a Digital Nomad Visa for remote workers."
                    content={`Are you a digital nomad looking to escape to warmer shores before winter locks down? Then, here is your chance! Portugal has announced a work visa for remote employees, which might allow digital nomads to reside and work there for up to 12 months.`}
                    height="100%"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <BlogCard
                    link="/agreement-for-recruitment-of-indian-worker-signed-between-india-portugal"
                    file={blog3img}
                    altText="recruitment of indian workers"
                    title="An agreement on the recruitment of Indian workers is signed
                    between Portugal and India"
                    content={`India has been signing agreements with a number of countries
                    lately, and now it seems they have their eyes set on Portugal. The
                    two nations signed an accord that will allow Indian citizens to
                    work in Portugal as well - opportunities for everyone!`}
                    height="100%"
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={4}>
                  <BlogCard
                    link="/portugal-ranks-1st-for-remote-work"
                    file={blog1img}
                    altText="best country for remote work"
                    title=" Portugal Ranks 1st as the best country in the world for remote
                    work."
                    content={`Travel search engine Momondo has created a “travel work index” to
                    help digital nomads find the best countries for productive working
                    conditions and opportunities on vacation. Portugal ranks 1st out
                    of ten in this category, with its climate (low crime rate)
                    combined with affordable cost-of living factors making it an
                    attractive destination abroad even if you're not looking
                    specifically beyond scenery!`}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "20%" },
              mb: { xs: "1rem", md: "1rem" },
            }}
          >
            <Bookconsultation fixed="fixed" title="Short Stay Visa Page" />
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default News;
