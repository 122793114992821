import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import rect35 from '../../Images/golden visa requirements.jpg'
import '../../Styling/desktop/goldenvisa.css'
import tick from '../../Images/Tick.svg'
function Wvsection4({ bgColor, refProp }) {
    return (
        <Box ref={refProp} width="100vw" maxWidth="100%" backgroundColor={bgColor}>
            <Box className="section4 wrapper" sx={{ width: { xs: 'calc(100vw - 3rem)', lg: 'calc(100vw - 12rem)' }, padding: { xs: '1rem 0', md: '2rem 0' } }} margin="auto">
                <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }} mt="2rem">
                    <Box sx={{ width: { xs: '100%', md: '50%' }, margin: { xs: '1rem auto 2rem', md: '0' } }}>
                        <img src={rect35} alt="work visa in portugal" style={{ width: '100%', height: '100%', maxHeight: '730px',objectFit: 'cover', borderRadius: '1.3rem', boxShadow: 'rgb(0 0 0 / 16%) 4px 4px 8px' }}></img>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, paddingRight: { xs: '0', md: '4rem' } }} >
                        <Typography mb=".5rem" variant="h4" ml="0px important">Requirements</Typography>
                        <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                        <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
                            Along with your Portuguese work visa application form, you will need to provide the following:
                        </Typography>
                        <Box sx={{ minHeight: { xs: ".5rem", md: '1rem' } }}></Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Employment contract between you and your employer</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Passport and previous visa copies</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Passport-size pictures (following Schengen visa picture guidelines.)</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Flight reservation details (good to have)</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Medical insurance policy covering expenses up to €30,000</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Proof of accommodation in Portugal</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Proof of sufficient funds to sustain yourself.</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">Your criminal records from your country of residence. If you have lived in another country for more than one year, you have to get criminal records from there as well.</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">A document giving permission to the SEF to check your Portuguese criminal records.</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">If you are already in Portugal: Proof of legal entry into Portuguese territory, such as a visa.</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" margin=".5rem 0">
                            <img src={tick} style={{ width: '.8rem', }}></img>
                            <Typography ml="1rem" variant="body2">If you are applying from a country other than that of your origin: Proof of legal stay, such as a residence permit or a visa.</Typography>
                        </Box>
                        <Typography mt="1.5rem" variant="body2"> Your Portuguese work visa is valid for the length of time you’re working in Portugal, or long
                            enough to lodge your application for a Portuguese residence visa. Applications can take
                            from two weeks to around two to three months in total.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Wvsection4
