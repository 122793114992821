import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import {useHistory} from 'react-router-dom';
import { Typography } from '@mui/material';
import flagimage from '../../Images/pedro-santos-dVtLtQ9RSGc-unsplash.svg'
import extlinkblackicon from '../../Images/ExternalLinkBlack.png';
import globeblueicon from '../../Images/Globe.png'
import locationblackicon from '../../Images/Location.png'
import ArrowBlack from '../../Images/Icon feather-arrow-right-circle.svg'
function Servicecard({title, caption, location, website, path="/"}) {
    const history=useHistory();
    return (
        <Card sx={{height: {xs: 'auto', md: 'auto'}, mb:'2rem'}} style={{ borderRadius: "1.3rem", boxShadow: "4px 4px 20px #00000029", width: '100%', display: "flex" }} onClick={()=>history.push(path)}>
        <CardActionArea width="100%" height="100%" sx={{display:"flex !important"}}>
            <Box height="100%" sx={{width: {xs: '35%', md: '40%'}}} >
                <img src={flagimage} alt="destination portugal" style={{objectFit:"cover"}} height="100%" width="100%"></img>
            </Box>
            <CardContent sx={{width: {xs: '65%', md: '60%'}, padding: {xs: '1rem', md: '1.5rem'}, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box sx={{display:{xs: 'none', md: 'block'}}}>
                        <Typography variant="h6" fontWeight="700">{title}</Typography>
                    </Box>
                    <Box sx={{display:{xs: 'block', md: 'none'}}}>
                        <Typography variant="h7" fontWeight="700">{title}</Typography>
                    </Box>
                    <Box display={website?'block':'none'}>
                        <img src={extlinkblackicon} alt="arrow" width="65%" height="70%"></img>
                    </Box>
                    <Box display={website?'none':'inline-block'} sx={{alignSelf:'start'}}>
                        <img src={ArrowBlack} alt="arrow" width="18px" style={{marginLeft: '8px'}}></img>
                    </Box>
                </Box>
                <Typography variant="body2" padding="1rem 0">{caption}</Typography>
                <Box margin="0rem 0">
                    <Box display={location?"flex":"none"} alignItems="center" sx={{mb:{xs: '.8rem', md: '1rem'}}} >
                        <img src={locationblackicon}></img> 
                        <Typography variant="subtitle1" ml="1.5rem">{location}</Typography>
                    </Box>
                    <Box display={website? "flex":"none"} alignItems="center">
                        <img src={globeblueicon}></img> 
                        <Typography variant="subtitle1" ml="1.3rem">{website}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>
    )
}

export default Servicecard
