import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import arrowsvg from '../../Images/Icon feather-arrow-right-circle.svg'
import eurosvg from '../../Images/euro-symbol.svg'
import timesvg from '../../Images/Time.svg'
import sunsvg from '../../Images/Icon feather-sun.svg'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import rect46img from '../../Images/Rectangle 46.png'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import Alert from '@mui/material/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import UpdatesCheckbox from '../Common Components/UpdatesCheckbox';
import '../../Styling/desktop/commoncomp.css';

function Shortstaycard({ title, description, day, cost, pic, path, tagName, applicationtime = "4-7 working days", altText="destination portugal" }) {
    // console.log({path})
    const [open, setOpen] = React.useState(false);
    const [fullName, setfullName] = useState("");
    const [Email, setEmail] = useState("");
    const [Num, setNum] = useState("");
    const [successAlert, setsuccessAlert] = useState(false)
    const [errorAlert, seterrorAlert] = useState(false);
    const [btndisabled, setbtndisabled] = useState(false);
    const [receiveUpdatecheckbox, setreceiveUpdatecheckbox] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const SHEET_URL = "https://script.google.com/macros/s/AKfycbz4mq9w2vgW6ROUFHQ834_BbS4MqNfZWQ8sxy9fqbvhGogtDltX2Kl9Y45Zv2SHRoSoiA/exec";

        console.log("handlesubmit called")
        setbtndisabled(true);
        let today = new Date();
        let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let formData = new FormData();
        let dataObj = {
            sheetName: 'VisasLeads',
            ID: uuidv4(),
            Name: fullName,
            Email: Email,
            Number: Num,
            PageURL: window.location.href,
            PageTitle: document.title,
            VisaType: title,
            Date: date,
            Time: time,
            receiveUpdate: receiveUpdatecheckbox,
        }
        for (let key in dataObj) {
            formData.append(key, dataObj[key]);
        }
        let mail_promise = await axios.post('http://127.0.0.1:8080/send_mail', dataObj);
        let googleSheet_promise = await axios.post(SHEET_URL, formData);

        try {
            let responseArr = await Promise.all([mail_promise, googleSheet_promise]);
            // console.log(responseArr);
            if (responseArr[0].data.mail_status === "success" && responseArr[1].data.result === "success") {
                setTimeout(() => setsuccessAlert(false), 3000)
                setsuccessAlert(true);
                setbtndisabled(false);
            } else {
                throw 'Something went wrong'
            }
        } catch (err) {
            console.log(err);
            setTimeout(() => seterrorAlert(false), 3000)
            seterrorAlert(true);
            setbtndisabled(false)
        }
        setfullName("");
        setEmail("");
        setNum("");
        setreceiveUpdatecheckbox(false)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCardClick = () => {
        //controls the opening of modal if no pathname is given
        if (!path) setOpen(true)
    };

    return (
        <>
            <Card className="shortstaycard" onClick={handleCardClick} style={{ borderRadius: "1.3rem", boxShadow: "4px 4px 20px #00000029", minWidth: '220px' }}
                sx={{ mr: { xs: '0', md: '0' }, height: { xs: 'auto', sm: '100%' }, position: 'relative' }}>
                <a href={path ? path : "javascript:void(0)"} style={{ textDecoration: 'none' }}>
                    <CardMedia
                        component="img"
                        height="50%"
                        sx={{ minHeight: { xs: 'auto', md: '13rem' }, maxHeight: { xs: 'none', md: '13rem' } }}
                        image={pic || rect46img}
                        alt={altText}
                    />
                    <CardContent sx={{ padding: '1.25rem' }}>
                        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                            <Typography variant="h5" fontWeight="400" style={{ textTransform: 'capitalize' }}>{title}</Typography>
                            {/* <Box sx={{minWidth:{xs: '1rem', md: '1.5rem'}, display: 'inline-block', marginLeft: '8px'}}>
                        </Box> */}
                            <img src={arrowsvg} alt="arrow" width="18px" style={{ marginLeft: '8px' }}></img>
                        </Box>
                        <Box margin="1rem 0">
                            <Typography variant="subtitle1">{description}</Typography>
                        </Box>
                        {
                            tagName && <Box padding="5px 14px" borderRadius="12px" textAlign="center" backgroundColor="#2F2FA2" style={{ position: 'absolute', top: '1rem', left: '1rem' }}>
                                <Typography style={{ color: 'white', fontWeight: 'normal' }}>{tagName}</Typography>
                            </Box>
                        }

                        <Box >
                            <Box display="flex" alignItems="center" mb="1rem">
                                <img src={sunsvg} alt=''></img>
                                <Typography ml="1rem" variant="subtitle1">{day}</Typography>
                                <Typography ml="8px" variant="caption" style={{ alignSelf: 'flex-end', color: '#898989' }}>Validity</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb="1rem">
                                <img src={eurosvg} alt=''></img>
                                <Typography ml="1rem" variant="subtitle1">{cost}</Typography>
                                <Typography ml="8px" variant="caption" style={{ alignSelf: 'flex-end', color: '#898989' }}>Application Fee</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb="1rem">
                                <img src={timesvg} width="18px" alt=''></img>
                                <Typography ml="1rem" variant="subtitle1">{applicationtime}</Typography>
                                <Typography ml="8px" variant="caption" style={{ alignSelf: 'flex-end', color: '#898989' }}>Application Time</Typography>
                            </Box>
                        </Box>

                    </CardContent>
                </a>
            </Card>
            <div>
                <Dialog open={open} onClose={handleClose} sx={{ '& .MuiBackdrop-root': { opacity: '0.4 !important', backgroundColor: '#2F2FA2' }, zIndex: '9902', '& .MuiDialog-paper': { maxWidth: 'none', borderRadius: { xs: '1rem', md: '1.5rem' }, maxHeight: { xs: 'none', md: '75vh' }, width: { xs: '-webkit-fill-available', md: 'auto' } } }}>
                    <DialogContent sx={{ width: { xs: '100%', md: '50vw' }, padding: '0px', backgroundColor: '#F0F0FF' }}>
                        <Box sx={{ display: { xs: 'block', md: 'flex' }, width: "100%", maxHeight: '100%' }}>
                            <Box sx={{ display: { xs: 'none', md: 'block' } }} maxWidth="35%">
                                <Card style={{ maxWidth: '100%' }} sx={{ height: { xs: 'auto', md: '100%' } }}>
                                    <CardMedia
                                        component="img"
                                        height="30%"
                                        sx={{ maxHeight: '14rem' }}
                                        image={pic || rect46img}
                                        alt="destination portugal"
                                    />
                                    <CardContent sx={{ padding: { xs: '1rem', md: '1rem 1rem 1rem 1.2rem' } }}>
                                        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                                            <Typography variant="h6" fontWeight="500" style={{ lineHeight: '1' }}>{title}</Typography>
                                            {/* <img src={arrowsvg} alt="arrow" width="18px" style={{marginLeft: '8px'}}></img> */}
                                        </Box>
                                        {
                                            tagName && <Box padding="5px 24px" mt=".5rem" borderRadius="15px" textAlign="center" backgroundColor="#2F2FA2" width="max-content">
                                                <Typography style={{ color: 'white', fontWeight: 'normal' }}>{tagName}</Typography>
                                            </Box>
                                        }

                                        <Box margin="1rem 0">
                                            <Typography variant="subtitle1">{description}</Typography>
                                        </Box>

                                        <Box>
                                            <Box display="flex" alignItems="center" mb="1rem">
                                                <img src={sunsvg} alt=''></img>
                                                <Typography ml="1rem" variant="subtitle1">{day}</Typography>
                                                <Typography ml="8px" variant="caption" style={{ alignSelf: 'flex-end', color: '#898989' }}>Validity</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" mb="1rem">
                                                <img src={eurosvg} alt=''></img>
                                                <Typography ml="1rem" variant="subtitle1">{cost}</Typography>
                                                <Typography ml="8px" variant="caption" style={{ alignSelf: 'flex-end', color: '#898989' }}>Application Fee</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" mb="1rem">
                                                <img src={timesvg} width="18px" alt=''></img>
                                                <Typography ml="1rem" variant="subtitle1">{applicationtime}</Typography>
                                                <Typography ml="8px" variant="caption" style={{ alignSelf: 'flex-end', color: '#898989' }}>Application Time</Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: '65%' }, display: 'flex', flexDirection: 'column', padding: { xs: '2rem 1rem', md: '2rem 3.5rem' }, position: 'relative' }}>
                                <Typography mb="1rem" variant="h4">Request a Call Back</Typography>
                                <CancelIcon onClick={handleClose} sx={{ fill: 'white', backgroundColor: 'black', borderRadius: '50%', top: '1rem', right: '1rem', position: 'absolute', cursor: 'pointer' }} />
                                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                    <Box width="100%">
                                        <Typography gutterBottom variant="body2" fontSize="1rem">Full Name</Typography>
                                        <input required value={fullName} onChange={(e) => setfullName(e.target.value)} className="inquire-form-input" type="text" id="full-name" name="full-name" placeholder='John Doe' />
                                    </Box>
                                    <Box width="100%">
                                        <Typography gutterBottom variant="body2" fontSize="1rem">Email</Typography>
                                        <input required value={Email} onChange={(e) => setEmail(e.target.value)} className="inquire-form-input" type="email" id="email" name="email" placeholder='john@email.com' />
                                    </Box>
                                    <Box width="100%">
                                        <Typography gutterBottom variant="body2" fontSize="1rem">Number</Typography>
                                        {/* <input type='text' required value={Num} onChange={(e) => { console.log(e.target.value); setNum(e.target.value) }} className="inquire-form-input" id="num" name="number" placeholder='12345 67890' title='Enter 10 digit Mobile Number' pattern='[0-9]{10}'></input> */}
                                        <PhoneInput
                                            country={'in'}
                                            value={Num}
                                            onChange={phone =>setNum(phone)}
                                            placeholder="12345-67890"
                                            inputProps={{
                                                name: 'number',
                                                required: true,
                                                id:'num',
                                                title:'Enter 10 digit Mobile Number with Country code',
                                                placeholder:'12345-67890'
                                              }}
                                              countryCodeEditable={false}
                                        />
                                    </Box>
                                    <UpdatesCheckbox receiveUpdatecheckbox={receiveUpdatecheckbox} setreceiveUpdatecheckbox={setreceiveUpdatecheckbox} />
                                    <DialogActions>
                                        <Button disabled={btndisabled} type="submit" style={{ width: "100%", height: "3rem", borderRadius: '.5rem', backgroundColor: "#2F2FA2", color: 'white', fontSize: "1rem" }}>{btndisabled ? "Please Wait..." : "Submit"}</Button>
                                    </DialogActions>
                                    {/* <Typography textAlign="center" color="#2F2FA2">Form Submitted Successfully</Typography> */}
                                    <Alert sx={{ display: successAlert ? 'flex' : 'none', margin: '0 auto', width: 'fit-content' }} icon={<CheckIcon fontSize="inherit" />} severity="success">Successfully Submitted!</Alert>
                                    <Alert sx={{ display: errorAlert ? 'flex' : 'none', margin: '0 auto', width: 'fit-content' }} severity="error">Error Submiting! Try Again</Alert>
                                </form>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        </>


    )
}

export default Shortstaycard
