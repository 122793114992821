import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/golden visa process time fees.jpg";

function Svsection5({ bgColor, refProp }) {
  return (
    <>
      <Box ref={refProp} width="100%" backgroundColor={bgColor}>
        <Box
          className="section5 wrapper"
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            padding: { xs: "1rem 0", md: "2rem 0" },
          }}
          margin="auto"
        >
          <Box
            width="100%"
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            mt="2rem"
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                paddingRight: { xs: "0", md: "4rem" },
              }}
            >
              <Typography mb=".5rem" variant="h4" ml="0px important">
                Processes, Timelines and Fees
              </Typography>
              <Typography gutterBottom variant="h5" color="#2F2FA2">
                Process
              </Typography>
              {/* <Typography gutterBottom variant="body2">
                                Once you receive a job offer, the Portuguese Work Visa is divided into three parts:
                            </Typography> */}
              <Box sx={{ minHeight: { xs: ".8rem", md: "1rem" } }}></Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  1
                </Box>
                <Typography
                  variant="h7"
                  fontWeight="400"
                  style={{ wordBreak: "break-word" }}
                >
                  Register on the Startup Visa online platform form here -
                  <a
                    href="https://webapps.iapmei.pt/StartupVisa/VisaEmp/Account/Login.aspx "
                    target="_blank"
                  >
                    https://webapps.iapmei.pt/StartupVisa/VisaEmp/Account/Login.aspx
                  </a>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  2
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Fill the online form on the Startup Visa online platform - in
                  Portuguese or English
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  3
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Your application is sent to the incubator of your choice and
                  they process it.{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  4
                </Box>
                <Typography variant="h7" fontWeight="400">
                  If the incubator accepts your application, you get a
                  notification and the details on the steps on applying for the
                  visa.{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  5
                </Box>
                <Typography variant="h7" fontWeight="400">
                  The incubator issues a Term Sheet for your startup.{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  6
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Visa form and documentation.
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  7
                </Box>
                <Typography variant="h7" fontWeight="400">
                  You will then have to transfer the incubation fee into secured
                  escrow account
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  8
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Submit application to the relevant Portuguese authority (with
                  interview at the Consulate, approx. 15 working days){" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  9
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Within approx. 90 days, landing in Portugal for the meeting
                  with local authorities and issuance of the Residence Permit
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  10
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Meeting with the incubator and government-supported R&D
                  facilities for the incorporation and strategic planning{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  11
                </Box>
                <Typography variant="h7" fontWeight="400">
                  The applicant is required to visit Portugal again at every
                  Residence Permit renewal subsequently{" "}
                </Typography>
              </Box>
              <Typography
                mb=".5rem"
                variant="h5"
                ml="0px important"
                color="#2F2FA2"
              >
                Timelines
              </Typography>
              <Typography gutterBottom variant="body2">
                Once you receive a Work Permit from your employer, the Work Visa
                application is completed in 2 stages and the timelines are
                dependant on that -
              </Typography>
              <Box sx={{ minHeight: { xs: ".8rem", md: "1rem" } }}></Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  1
                </Box>
                <Typography variant="h7">
                  SEF Process - upto 60 days{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="2rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  2
                </Box>
                <Typography variant="h7">
                  Portuguese Embassy - upto 3 months
                </Typography>
              </Box>

              <Typography
                mb="1rem"
                variant="h5"
                ml="0px important"
                color="#2F2FA2"
              >
                Fees
              </Typography>
              <Typography mb=".5rem" variant="body2" paddingLeft="0.1rem">
                Cost of the entry visa issued by the Portuguese Embassy – €90*
              </Typography>
              <Typography mb=".5rem" variant="body2" paddingLeft="0.1rem">
                To submit the application for a residence permit for work at the
                SEF – €83
              </Typography>
              <Typography mb="1rem" variant="body2" paddingLeft="0.1rem">
                To receive the residence permit for work from the SEF – €72
              </Typography>
              <Typography variant="caption" paddingLeft="0.1rem">
                *may be subject to change from country to country
              </Typography>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: { xs: "2rem auto 1rem", md: "0" },
              }}
            >
              <img
                src={rect35}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "1.3rem",
                  boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
                }}
                alt="portugal startup visa process"
              ></img>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Svsection5;
