import * as IRRConstants from '../IRRconstants';

const{
    REAL_ESTATE_FUND_TOTAL_OUTGOING_FEE_PERCENT,
    REAL_ESTATE_IMT_AND_STAMP_DUTY_PERCENT,
    REAL_ESTATE_REGISTRY_AND_OTHER_COST,
    REAL_ESTATE_AGENT_FEE_PERCENT,
    REAL_ESTATE_TOTAL_INITIAL_FEE_PERCENT,
    REAL_ESTATE_CAPITAL_GAIN_TAX_PERCENT ,
    SEF_COST_PER_APPLICANT,
    SEF_COST_PER_DEPENDENT,
    ISSUE_NEW_PERMIT_COSTEACH,
    RENEWAL_RESIDENCE_PERMIT_APPLICANT,
    RENEWAL_RESIDENCE_PERMIT_DEPENDENT
}=IRRConstants


export function RealEstatebase(
    initailRealEstateFund,
    numofApplicant,
    numofFamilymembers,
    ) {

    let initialCost = (numofApplicant * SEF_COST_PER_APPLICANT) + (numofFamilymembers * SEF_COST_PER_DEPENDENT)
        + ((numofApplicant + numofFamilymembers) * RENEWAL_RESIDENCE_PERMIT_APPLICANT) +
        (initailRealEstateFund * (REAL_ESTATE_TOTAL_INITIAL_FEE_PERCENT / 100))

    initialCost = Math.round(initialCost);
    return initialCost;
}

export function REFinalFundValue(Exityears, initailRealEstateFund, REGrowthRate){
    //Final Fund Value = ((REGrowthRate / 100) + 1) raised to exit year * Initial Fund Amount
    let finalAmt= Math.pow(((REGrowthRate/100) + 1), Exityears ) * initailRealEstateFund
    return Math.round(finalAmt);
}

export function CapitalGainsCalculator(finalAmt, initailRealEstateFund){
    return Math.round(finalAmt - (initailRealEstateFund* (REAL_ESTATE_IMT_AND_STAMP_DUTY_PERCENT/100))-(REAL_ESTATE_REGISTRY_AND_OTHER_COST)-(finalAmt*(REAL_ESTATE_AGENT_FEE_PERCENT/100))-initailRealEstateFund)
}

//Gives array of costs till exit year
//considering odd or even year and final year calculations
export function RECostsArray(
    Exityears, 
    initailRealEstateFund,  
    numofApplicant,
    numofFamilymembers,
    capitalGains
    ){
        let renewalFee=(numofApplicant*RENEWAL_RESIDENCE_PERMIT_APPLICANT) + (numofFamilymembers*RENEWAL_RESIDENCE_PERMIT_DEPENDENT);
        let basicCost= Math.round((initailRealEstateFund*REAL_ESTATE_FUND_TOTAL_OUTGOING_FEE_PERCENT*0.01))
        let evenyearCost= basicCost + renewalFee;
        
        let FinalyearCostifEven= Math.round(basicCost + renewalFee + (capitalGains*REAL_ESTATE_CAPITAL_GAIN_TAX_PERCENT* 0.01 ));
        let FinalyearCostifOdd= Math.round(basicCost + (capitalGains*REAL_ESTATE_CAPITAL_GAIN_TAX_PERCENT* 0.01 ));
        let tempArr=Array.from(new Array(Exityears)).fill(0); //creating array of length==exit years and filling it with zeros
        let costArr=tempArr.map((ele, index)=>{
            if(index===tempArr.length-1){
                if((index+1)%2===0){
                    //Final Year is EVEN Year
                    return Math.round(-FinalyearCostifEven)
                }else{
                    //Final Year is ODD Year
                    return  Math.round(-FinalyearCostifOdd)
                }
            }else{
                if((index+1)%2===0){
                    //renewal year
                    return  Math.round(-evenyearCost)
                }else{
                    return Math.round(-basicCost)
                }
            }
        })

        return costArr;
}

export function RECashflowArray(costArr, finalAmt, initialCost, initailRealEstateFund, RERentalYield){

    let yearlyIncome=initailRealEstateFund * RERentalYield* 0.01;
    let allCostsArr=costArr.map(ele => ele + yearlyIncome);
    allCostsArr.unshift(-(initialCost + initailRealEstateFund))
    let exitYearBalance= Math.round(finalAmt + allCostsArr[allCostsArr.length-1]);
    let VCCashflowArray=[...allCostsArr.slice(0,-1), exitYearBalance]
    return VCCashflowArray;
}

export function NetGrowthRE(costArr, initialCost, initailRealEstateFund, Exityears, RERentalYield, REGrowthRate){
    let grArr=new Array(Exityears).fill(0);
    grArr[0]=initailRealEstateFund*REGrowthRate*0.01;
    for(let i=1;i<grArr.length;i++){
        let preSum=0;
        for(let j=0;j<i;j++){
            preSum+=grArr[j];
        }
        grArr[i]=Math.round((initailRealEstateFund+preSum)*REGrowthRate*0.01);
    }
    let yearlyIncome=Math.round(initailRealEstateFund*RERentalYield*0.01);

    let incomeArr=[grArr[0]+yearlyIncome];
    let AccumulatedCostsArr=[-(initialCost)];
    let netGrowthRateArr=[];

    for(let i=0;i<(Exityears + 1);i++){
        if(i!==0){
            AccumulatedCostsArr[i]= AccumulatedCostsArr[i-1] + costArr[i-1]
            incomeArr[i]=grArr[i]+yearlyIncome+incomeArr[i-1];
            netGrowthRateArr[i-1]=100 + Math.round(((incomeArr[i-1]-Math.abs(AccumulatedCostsArr[i]))*100)/initailRealEstateFund)
        }
    }
    return netGrowthRateArr;
}   