import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/golden visa process time fees.jpg";

function Tvsection5({ bgColor, refProp }) {
  return (
    <>
      <Box ref={refProp} width="100%" backgroundColor={bgColor}>
        <Box
          className="section5 wrapper"
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            padding: { xs: "1rem 0", md: "2rem 0" },
          }}
          margin="auto"
        >
          <Box
            width="100%"
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            mt="2rem"
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                paddingRight: { xs: "0", md: "4rem" },
              }}
            >
              <Typography mb=".5rem" variant="h4" ml="0px important">
                Processes, Timelines and Fees
              </Typography>
              <Typography gutterBottom variant="h5" color="#2F2FA2">
                Process
              </Typography>
              <Typography gutterBottom variant="body2">
                Once you receive a job offer, the Portuguese Tech Visa is
                divided into three parts:
              </Typography>
              <Box sx={{ minHeight: { xs: ".8rem", md: "1rem" } }}></Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  1
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Obtain a Terms of Requirements sheet with your future
                  certified company employer.{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb=".5rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  2
                </Box>
                <Typography variant="h7" fontWeight="400">
                  Submit all required documents with the Terms of Requirements
                  to the Portuguese Embassy near you.{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "6rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  3
                </Box>
                <Typography variant="h7" fontWeight="400">
                  IAPMEI holds the responsibility of the company’s certification
                  process, which also involves consular services (that issue
                  visas) and the Aliens and Borders Service (that issues
                  residence permits). The certification of the company is valid
                  for two years and can be renewed for equal periods.{" "}
                </Typography>
              </Box>

              <Typography
                mb=".5rem"
                variant="h5"
                ml="0px important"
                color="#2F2FA2"
              >
                Timelines
              </Typography>
              <Typography gutterBottom variant="body2">
                Once you receive a Work Permit from your employer, the Tech Visa
                application is completed in 2 stages and the timelines are
                dependant on that -
              </Typography>
              <Box sx={{ minHeight: { xs: ".8rem", md: "1rem" } }}></Box>
              <Box display="flex" alignItems="center" mb="1rem">
                <Box
                  className="visa-section5-box"
                  sx={{ width: { xs: "1.5rem", md: "2rem" } }}
                  textAlign="center"
                  borderRadius=".5rem"
                  color="white"
                  marginRight="1rem"
                >
                  1
                </Box>
                <Typography variant="h7">
                  SEF Process - upto 60 days{" "}
                </Typography>
              </Box>

              <Typography
                mb="1rem"
                variant="h5"
                ml="0px important"
                color="#2F2FA2"
              >
                Fees
              </Typography>
              <Typography mb=".5rem" variant="body2" paddingLeft="0.1rem">
                Cost of the entry visa issued by the Portuguese Embassy – €90*
              </Typography>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                margin: { xs: "2rem auto 1rem", md: "0" },
              }}
            >
              <img
                src={rect35}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "1.3rem",
                  boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
                  maxHeight: "620px",
                }}
                alt="apply portugal tech visa"
              ></img>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Tvsection5;
