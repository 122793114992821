import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import rect35 from "../../Images/golden visa requirements.jpg";
import "../../Styling/desktop/goldenvisa.css";
import tick from "../../Images/Tick.svg";
function Svsection4({ bgColor, refProp }) {
  return (
    <Box ref={refProp} width="100vw" maxWidth="100%" backgroundColor={bgColor}>
      <Box
        className="section4 wrapper"
        sx={{
          width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          padding: { xs: "1rem 0", md: "2rem 0" },
        }}
        margin="auto"
      >
        <Box
          width="100%"
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          mt="2rem"
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              margin: { xs: "1rem auto 2rem", md: "0" },
            }}
          >
            <img
              src={rect35}
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "630px",
                objectFit: "cover",
                borderRadius: "1.3rem",
                boxShadow: "rgb(0 0 0 / 16%) 4px 4px 8px",
              }}
              alt="portugal startup visa requirements"
            ></img>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              paddingLeft: { xs: "0", md: "4rem" },
            }}
          >
            <Typography mb=".5rem" variant="h4" ml="0px important">
              Requirements
            </Typography>
            <Box sx={{ minHeight: { xs: ".5rem", md: "1rem" } }}></Box>
            <Typography gutterBottom variant="body2" paddingLeft="0.1rem">
              Along with your visa application form, you will need to provide
              the following:
            </Typography>
            <Box sx={{ minHeight: { xs: ".5rem", md: "1rem" } }}></Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proof of intention to develop your startup in Portugal (you
                don’t need to have started your business yet)
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proof of launching a startup in your country - if you are
                looking to set it up in Portugal
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proof that your project/startup is focused on tech
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                You have not had any kind of regular residence in the EU
                Schengen area in the recent past
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Your criminal records from your country of residence. If you
                have lived in another country for more than one year, you have
                to get criminal records from there as well.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Over 18 years old
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proof of sufficient funds to sustain yourself for 12 months -
                EUR 5150/person
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proof of potential to create new jobs
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Proof of potential to generate a turnover of 325,000€/ year
                and/or assets value over 325,000€/ year, within 5 years after
                the start of the contract with your approved incubator
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" margin=".5rem 0">
              <img src={tick} style={{ width: ".8rem" }}></img>
              <Typography ml="1rem" variant="body2">
                Individual capacity to establish a registered company, when
                required, during the program
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Svsection4;
