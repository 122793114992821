import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import "../Styling/desktop/aboutus.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import rect69 from "../Images/Rectangle 69.svg";
import rect70 from "../Images/Rectangle 70.svg";
import rect71 from "../Images/Rectangle 71.svg";
import rect47 from "../Images/Rectangle 47.svg";
import "../Styling/desktop/commoncomp.css";
import uptodatepic from "../Images/VD Icons/Up to date information.svg";
import endsupportpic from "../Images/VD Icons/End to End Support.svg";
import localexptpic from "../Images/VD Icons/Local Expertise.svg";
import investmentAdvisorypic from "../Images/VD Icons/Investment Advisory.svg";
import member1 from "../Images/about-us/about-team-member-1.jpg";
import member2 from "../Images/about-us/about-team-member-2.jpg";
import { Helmet } from "react-helmet";

function AboutUs() {
  const card1 = (
    <Card
      className="cardhoverstyle"
      style={{ borderRadius: "1rem", backgroundColor: "white" }}
      sx={{
        minHeight: "150px",
        display: "flex",
        padding: "1rem",
        paddingBottom: "1.5rem",
      }}
    >
      <Box className="icon-box-home-about">
        <img
          src={uptodatepic}
          alt="up-to-date information - portugal visas"
        ></img>
      </Box>
      <Typography width="100%" mt="auto" variant="body2">
        Up-to-date Information
      </Typography>
    </Card>
  );
  const card2 = (
    <Card
      className="cardhoverstyle"
      style={{ borderRadius: "1rem", backgroundColor: "white" }}
      sx={{
        minHeight: "150px",
        display: "flex",
        padding: "1rem",
        paddingBottom: "1.5rem",
      }}
    >
      <Box className="icon-box-home-about">
        <img src={endsupportpic} alt="portugal visa online support"></img>
      </Box>
      <Typography width="100%" mt="auto" variant="body2">
        End-to-End Support
      </Typography>
    </Card>
  );
  const card3 = (
    <Card
      className="cardhoverstyle"
      style={{ borderRadius: "1rem", backgroundColor: "white" }}
      sx={{
        minHeight: "150px",
        display: "flex",
        padding: "1rem",
        paddingBottom: "1.5rem",
      }}
    >
      <Box className="icon-box-home-about">
        <img src={localexptpic} alt="portugal visa process"></img>
      </Box>
      <Typography width="100%" mt="auto" variant="body2">
        Local Expertise
      </Typography>
    </Card>
  );
  const card4 = (
    <Card
      className="cardhoverstyle"
      style={{ borderRadius: "1rem", backgroundColor: "white" }}
      sx={{
        minHeight: "150px",
        display: "flex",
        padding: "1rem",
        paddingBottom: "1.5rem",
      }}
    >
      <Box className="icon-box-home-about">
        <img
          src={investmentAdvisorypic}
          alt="portugal visa by investment"
        ></img>
      </Box>
      <Typography width="100%" mt="auto" variant="body2">
        Investment Advisory
      </Typography>
    </Card>
  );
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Helmet>
        <title>About Visa Discovery | Work Visa for Portugal</title>
        <meta
          name="description"
          content="Visa Discovery simplifies working and investing in Portugal. We inform and simplify the process of work visa for Portugal and other work visas to Europe. Learn more."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "About Us",
                item: "https://visadiscovery.com/about-us",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" maxWidth="100%" paddingTop="1rem" className="fade">
        <Header />
        <Box
          className="about-us-cover-container"
          mt="-1rem"
          width="100%"
          height="45vh"
          textAlign="center"
        >
          <Typography variant="h4" color="white" paddingTop="30vh ">
            About Us
          </Typography>
        </Box>
        <Box backgroundColor="#F0F0FF" width="100vw" maxWidth="100%">
          <Box
            className="wrapper"
            padding="2.5rem 0"
            sx={{
              width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            }}
            margin="auto"
          >
            <Box
              display="flex"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "0rem", md: "50px" },
              }}
              alignItems="center"
            >
              <Box sx={{ width: { xs: "100%", md: "50%" } }} textAlign="center">
                <Typography variant="h3" fontWeight="700" color="black">
                  To inform and simplify your Visa Process so you can focus on
                  the best part of the processes-
                  <span style={{ color: "#2F2FA2" }}> coming to Portugal.</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  mt: { xs: "1rem", md: "0rem" },
                }}
                textAlign="center"
              >
                <Typography
                  variant="body1"
                  fontWeight="400"
                  mb="1rem"
                  textAlign="justify"
                >
                  The common history of India and Portugal dates back to 1498,
                  when Vasco da Gama and his crew first docked in Calicut. Fast
                  forward 500 years, and this partnership still presents immense
                  opportunities to be discovered. Visa Discovery is a
                  pet-project of Paulo Pereira, business advisor based in
                  Portugal, and João Martins, a professional Wealth Manager with
                  close ties to businesses in the UK and India. Paulo and João
                  hold India, its people, food, traditions, and culture close to
                  their hearth, and shared a desired to start an enterprise with
                  local Indian talent – thus, they teamed up with Mana Vora,
                  Bhavesh and Gaurav to start Visa Discovery, a one-stop Visa
                  marketplace for Indian businesses, investors, owners, and
                  highly skilled workers.
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="400"
                  textAlign="justify"
                >
                  We simplify investing and working in Portugal by providing the
                  following services: <br />
                  <ol>
                    <li>
                      Explain the different Visa options and help you understand
                      what the best option is for you/your business;
                    </li>
                    <li>
                      Investment Research and consulting on Golden Visa
                      compliant investment funds;
                    </li>
                    <li>
                      Business consulting and business finance consulting for
                      Indian businesses and entrepreneurs;
                    </li>
                    <li>
                      Consulting and application for Portuguese/European grants,
                      soft loans and investment incentives;
                    </li>
                    <li>Visa application, legal assistance and review;</li>
                    <li>Multi language and highly professional service.</li>
                  </ol>
                  Get in touch with our team today at{" "}
                  <a
                    href="mailto:info@visadiscovery.com"
                    style={{
                      color: "rgb(47, 47, 162)",
                      textDecoration: "none",
                    }}
                  >
                    info@visadiscovery.com
                  </a>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="wrapper"
          padding="2.5rem 0"
          sx={{
            width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
          }}
          margin="auto"
        >
          <Box
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Box sx={{ width: { xs: "100%", md: "50%" } }}>
              <Typography variant="h4">About Visa Discovery</Typography>
              <Typography
                mt="1rem"
                gutterBottom
                variant="body1"
                fontWeight="400"
              >
                Over the years, many of us in Portugal have heard of stories of
                people from other countries in the Region like Pakistan, Sri
                Lanka, Bangladesh, Nepal etc. coming to Portugal after a long
                and tedious visa process.
              </Typography>
              <Typography
                mt="1rem"
                gutterBottom
                variant="body1"
                fontWeight="400"
              >
                What was worse, many people have been scammed by these “agents”
                who claim to guarantee a Visa.
              </Typography>
              <Typography
                mt="1rem"
                gutterBottom
                variant="body1"
                fontWeight="400"
              >
                With Visa Discovery, we want to change this system. We hope to
                guide you through the entire process with complete transparency.
              </Typography>
              <Typography
                mt="1rem"
                gutterBottom
                variant="body1"
                fontWeight="400"
              >
                We partner only with government agencies, registered companies
                and experienced professionals to ensure security and compliance
                of processes.
              </Typography>
              <Typography
                mt="1rem"
                gutterBottom
                variant="body1"
                fontWeight="400"
              >
                We hope to talk with you soon to get your journey to Portugal
                started, with safety and security.
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                mt: { xs: "1rem", md: "0" },
                padding: { xs: "", md: "0 2rem" },
              }}
              textAlign="center"
            >
              <Grid
                container
                rowSpacing={{ xs: 2, md: 4 }}
                columnSpacing={{ xs: 2, md: 5 }}
              >
                <Grid item xs={6}>
                  {card1}
                </Grid>
                <Grid item xs={6}>
                  {card2}
                </Grid>
                <Grid item xs={6}>
                  {card3}
                </Grid>
                <Grid item xs={6}>
                  {card4}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <img
            className="about-us-divider-images"
            src={rect69}
            alt="Rectangle 69"
            style={{ width: "100%" }}
            loading="lazy"
          ></img>
          <img
            className="about-us-divider-images"
            src={rect70}
            alt="Rectangle 70"
            style={{ width: "100%" }}
            loading="lazy"
          ></img>
          <img
            className="about-us-divider-images"
            src={rect71}
            alt="Rectangle 71"
            style={{ width: "100%" }}
            loading="lazy"
          ></img>
        </Box>
        <Box backgroundColor="#F0F0FF" width="100vw" maxWidth="100%">
          <Box
            className="wrapper"
            padding="2.5rem 0"
            sx={{
              width: { xs: "calc(100vw - 3rem)", lg: "calc(100vw - 12rem)" },
            }}
            margin="auto"
          >
            <Typography variant="h4">The Team</Typography>
            <Typography variant="body1" fontWeight="400" mt="1rem">
              At Visa Discovery, our core team consists of experts with a
              background of different industries and experience.
            </Typography>
            <Typography variant="body1" fontWeight="400">
              We specialize in visas, tourism, investment advisory, business
              consulting and compliance processes - all of which we hope to use
              for your benefit
            </Typography>
            <Box
              mt="2rem"
              width="100%"
              display="flex"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: "1.5rem", md: "5rem" },
              }}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box
                borderRadius=".75rem"
                sx={{ width: { xs: "100%", md: "300px" } }}
              >
                <img
                  src={member1}
                  alt="Paulo Pereira"
                  width="100%"
                  className="team-member-img"
                ></img>
                <Box mt="10px">
                  <Typography variant="caption" className="team-name-text">
                    Paulo Pereira
                  </Typography>
                  <Box sx={{ float: "right" }}>
                    <IconButton
                      sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                      onClick={() =>
                        openInNewTab(
                          "https://www.linkedin.com/in/paulopereiraportugal/"
                        )
                      }
                    >
                      <LinkedInIcon sx={{ fill: "rgb(47, 47, 162)" }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box
                borderRadius=".75rem"
                sx={{ width: { xs: "100%", md: "300px" } }}
              >
                <img
                  src={member2}
                  alt="João Feliciano Martins"
                  width="100%"
                  className="team-member-img"
                ></img>
                <Box mt="10px">
                  <Typography variant="caption" className="team-name-text">
                    João Feliciano Martins
                  </Typography>
                  <Box sx={{ float: "right" }}>
                    <IconButton
                      sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                      onClick={() =>
                        openInNewTab(
                          "https://www.linkedin.com/in/joao-martins-19bb82150/"
                        )
                      }
                    >
                      <LinkedInIcon sx={{ fill: "rgb(47, 47, 162)" }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              {/* <Box borderRadius=".75rem" sx={{ width: { xs: '100%', md: '300px' } }}>
                            <img src={rect47} alt="rectangle 47" width="100%" className="team-member-img"></img>
                            <Box mt="10px">
                                <Typography variant="caption" className='team-name-text'>Name Last Name</Typography>
                                <Box sx={{ float: 'right' }}>
                                    <IconButton sx={{ paddingTop: '0px', paddingBottom: '0px' }} onClick={() => openInNewTab('https://www.linkedin.com/in/paulopereiraportugal/')}>
                                        <LinkedInIcon sx={{ fill: 'rgb(47, 47, 162)' }} />
                                    </IconButton>
                                </Box>
                            </Box>

                        </Box> */}
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default AboutUs;
