import React from 'react'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import arrowsvg from '../../Images/Icon feather-arrow-right-circle.svg'
import '../../Styling/desktop/homepage.css'
import { useHistory } from 'react-router-dom'
import Header from './Header';
import { Link } from 'react-router-dom';
function Homesection1() {
    const history = useHistory();
    return (
        <div className="home-section1">
            <Header />
            <Box mt="calc(12vh + 1rem)" width="100%" sx={{
                height: { xs: "auto", md: "30rem" },
                flexDirection: { xs: 'column', md: 'row' }
            }} display="flex">

                <Box sx={{
                    width: { xs: '100%', md: "50%" },
                    height: { xs: 'auto', md: '100%' },
                    textAlign: { xs: 'center', md: "left" },
                    mt: { xs: '2rem', sm: '4rem', md: 'unset' }, mb: { xs: '2rem', sm: '4rem', md: 'unset' },
                    padding: { xs: '0 1rem', md: '0' }
                }}
                    display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant='h1' fontSize='50px !important' sx={{
                        ml: { xs: "0rem", md: "5rem" },
                    }}
                    >Visas for Portugal - Simplified</Typography>
                    <Typography variant='subtitle1' sx={{
                        ml: { xs: "0rem", md: "5rem" },
                        fontSize: { xs: '20px', md: '24px' }
                    }} mt="1rem">Get all the information and application processes for all visas for Portugal</Typography>
                </Box>
                <Box className="passport-image-container"
                    sx={{
                        width: { xs: "calc(100% - 1rem)", md: "50%" },
                        height: { xs: '-webkit-fill-available', md: "100%" },
                        minHeight: { xs: '280px', sm: '540px', md: '480px' },
                        margin: '0 auto'
                    }}>
                </Box>
            </Box>
            <Box style={{ background: 'linear-gradient(98deg, #E165FF00 0%, #ADADE5 100%) 0% 0% no-repeat padding-box', boxShadow: '4px 4px 20px #00000029', }}
                sx={{
                    width: { xs: 'calc(100vw - 1.5rem)', sm: '75%', md: "auto" }, maxWidth: { xs: '100%', md: '62rem' },
                    height: { xs: '30vh', sm: '22vh', md: "10rem" }, borderRadius: { xs: '1.2rem', md: '.75vw' },
                    position: { xs: 'relative', md: 'static' },
                    bottom: { xs: '-15vh', md: '-11vh' }
                }}
                margin="auto"
            >
                <Box style={{ background: 'linear-gradient(230deg, #FFFFFF00 0%, #CECEFF 100%) 0% 0% no-repeat padding-box' }}
                    sx={{
                        width: '100%',
                        height: '100%',
                        padding: { xs: '0 1rem', md: '0px clamp(1rem, 5vw, 4rem)' },
                        borderRadius: { xs: '1.2rem', md: '.75vw' },
                        flexDirection: { xs: 'column', md: 'row' },
                        mb: { xs: '0', md: '2rem' },
                        mt: '0',
                        justifyContent: { xs: 'center', md: 'space-between' }
                    }} display="flex" alignItems="center" >
                    <Box sx={{ width: { xs: '100%', md: 'auto' }, textAlign: { xs: 'center', md: 'left' } }}>
                        <Typography variant="body2">Not sure which visa would be right for your trip to Portugal?</Typography>
                        <Box minHeight=".5rem" sx={{ display: { xs: 'none', md: 'block' } }} />
                        <Typography variant="h6">Discover the right Visa for you - <span style={{ color: `var(--tblue)`, whiteSpace: 'nowrap' }}>Visa Discovery Tool</span></Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, minHeight: { xs: ".5rem", sm: '2.5rem' } }} />
                    <Link style={{textDecoration: 'none'}} to={'/visa-discovery-tool'}>
                        <Box style={{ cursor: 'pointer' }}
                            sx={{
                                width: { xs: '100%', sm: 'auto' },
                                borderRadius: { xs: '.8rem', md: '.75vw' },
                            }} boxShadow=" 4px 4px 20px #00000029" className="discover-now-box" display="flex" alignItems="center" justifyContent="space-around">
                            <Typography variant="body1" padding="1rem" style={{ whiteSpace: 'nowrap' }}>DISCOVER NOW</Typography>
                            <Box padding="0 1rem" mt="5px">
                                <img src={arrowsvg} alt="arrow"></img>
                            </Box>
                        </Box>
                    </Link>
                </Box>
            </Box>
        </div>
    )
}

export default Homesection1
