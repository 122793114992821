import React from 'react';
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
function CostQuestion3({ spouse, setspouse, dependentObj, setdependentObj }) {
    const { less11year,
        less17year,
        less24year,
        less64year,
        more65year } = dependentObj;
    const handleChange = (event) => {
        setspouse(event.target.value);
    };
    return (<>
        <Box width="100%" display="flex" flexDirection="column" style={{ gap: '1.5rem' }} mt="1rem">
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant='body1' fontWeight="400">Spouse</Typography>
                <FormControl sx={{
                    width: '140px', height: '40px', backgroundColor: 'white', borderRadius: '12px',
                    boxShadow: '4px 4px 20px #00000029', position: 'relative', zIndex: '1'
                }}>
                    <RadioGroup
                        row
                        aria-labelledby="spouse-radio-buttons-group"
                        name="spouse-radio-buttons-group"
                        value={spouse}
                        onChange={handleChange}
                        sx={{ position: 'relative', height: '100%', width: '100%', justifyContent: 'space-around' }}
                    >
                        <FormControlLabel
                            sx={{
                                marginLeft: '0px',
                                marginRight: '0px',
                                '& .MuiButtonBase-root': { display: 'none' },
                                '& .MuiTypography-root': {
                                    fontWeight: '400',
                                    color: spouse === "1" ? '#2F2FA2' : 'black'
                                }
                            }}
                            value="1"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            sx={{
                                marginLeft: '0px',
                                marginRight: '0px',
                                '& .MuiButtonBase-root': { display: 'none' },
                                '& .MuiTypography-root': {
                                    fontWeight: '400',
                                    color: spouse === "0" ? '#2F2FA2' : 'black',
                                }
                            }}
                            value="0"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                    <div className="spouse_slider"
                        style={{
                            transform: spouse === "0" ? 'translateX(70px)' : 'translateX(0px)'
                        }}></div>
                </FormControl>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width="fit-content" display="flex" alignItems="center">
                    <Typography variant='body1' fontWeight="400" display="inline-block">Dependents</Typography>
                    <Typography variant='caption' fontWeight="400" ml="12px" display="inline-block" color="rgb(137, 137, 137)">(&#60; 11 Years)</Typography>
                </Box>
                <Box width='140px' height="40px" display="flex" alignItems="center" justifyContent="space-between" style={{ boxShadow: '4px 4px 20px #00000029', borderRadius: '10px' }}>
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => { if (less11year === 0) { return } setdependentObj({ ...dependentObj, less11year: less11year - 1 }) }}>
                        <RemoveIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                    <Box fontWeight="400">
                        {dependentObj.less11year}
                    </Box>
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => setdependentObj({ ...dependentObj, less11year: less11year + 1 })}>
                        <AddIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width="fit-content" display="flex" alignItems="center">
                    <Typography variant='body1' fontWeight="400" display="inline-block">Dependents</Typography>
                    <Typography variant='caption' fontWeight="400" ml="12px" display="inline-block" color="rgb(137, 137, 137)">(12 to 17 Years)</Typography>
                </Box>
                <Box width='140px' height="40px" display="flex" alignItems="center" justifyContent="space-between" style={{ boxShadow: '4px 4px 20px #00000029', borderRadius: '10px' }}>
                    
                <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => { if (less17year === 0) { return } setdependentObj({ ...dependentObj, less17year: less17year - 1 }) }}>
                        <RemoveIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                    
                    <Box fontWeight="400">
                        {dependentObj.less17year}
                    </Box>
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => setdependentObj({ ...dependentObj, less17year: less17year + 1 })}>
                        <AddIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width="fit-content" display="flex" alignItems="center">
                    <Typography variant='body1' fontWeight="400" display="inline-block">Dependents</Typography>
                    <Typography variant='caption' fontWeight="400" ml="12px" display="inline-block" color="rgb(137, 137, 137)">(18 to 24 Years)</Typography>
                </Box>
                <Box width='140px' height="40px" display="flex" alignItems="center" justifyContent="space-between" style={{ boxShadow: '4px 4px 20px #00000029', borderRadius: '10px' }}>
                <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => { if (less24year === 0) { return } setdependentObj({ ...dependentObj, less24year: less24year - 1 }) }}>
                        <RemoveIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                    <Box fontWeight="400">
                        {dependentObj.less24year}
                    </Box>
                    
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => setdependentObj({ ...dependentObj, less24year: less24year + 1 })}>
                        <AddIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width="fit-content" display="flex" alignItems="center">
                    <Typography variant='body1' fontWeight="400" display="inline-block">Dependents</Typography>
                    <Typography variant='caption' fontWeight="400" ml="12px" display="inline-block" color="rgb(137, 137, 137)">(25 to 64 Years)</Typography>
                </Box>
                <Box width='140px' height="40px" display="flex" alignItems="center" justifyContent="space-between" style={{ boxShadow: '4px 4px 20px #00000029', borderRadius: '10px' }}>
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => { if (less64year === 0) { return } setdependentObj({ ...dependentObj, less64year: less64year - 1 }) }}>
                        <RemoveIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                    <Box fontWeight="400">
                        {dependentObj.less64year}
                    </Box>
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => setdependentObj({ ...dependentObj, less64year: less64year + 1 })}>
                        <AddIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width="fit-content" display="flex" alignItems="center">
                    <Typography variant='body1' fontWeight="400" display="inline-block">Dependents</Typography>
                    <Typography variant='caption' fontWeight="400" ml="12px" display="inline-block" color="rgb(137, 137, 137)">(&#62; 65 Years)</Typography>
                </Box>
                <Box width='140px' height="40px" display="flex" alignItems="center" justifyContent="space-between" style={{ boxShadow: '4px 4px 20px #00000029', borderRadius: '10px' }}>
                <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => { if (more65year === 0) { return } setdependentObj({ ...dependentObj, more65year: more65year - 1 }) }}>
                        <RemoveIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                    <Box fontWeight="400">
                        {dependentObj.more65year}
                    </Box>
                    
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() => setdependentObj({ ...dependentObj, more65year: more65year + 1 })}>
                        <AddIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    </>)
}

export default CostQuestion3;
