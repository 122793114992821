import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import arrowsvg from '../../Images/Icon feather-arrow-right-circle.svg'
import '../../Styling/desktop/homepage.css'
import "../../Styling/desktop/homepage.css"
import {Link} from 'react-router-dom'
function Homesection5mid() {
    return (
        <Box className="home-section6-container-wrapper"  sx={{width: { xs: 'calc(100vw - 2rem)', md: 'min(85%, 69rem)' }, borderRadius: {xs: '1.2rem', md: '.75vw'}}} margin="auto">
        <Box className="home-section6-container" margin="auto" 
            sx={{ 
                width: { xs: 'calc(100vw - 2rem)', md: '100%' },
                p: {xs: "0 1rem", md:"0 clamp(3rem, 7%, 6rem)"},
                flexDirection: {xs: "column", md:"row"},
                height: {xs: '16rem', md:"13rem"},
                justifyContent:{xs:"center", md: 'space-between'},
                borderRadius: {xs: '1.2rem', md: '.75vw'},
                marginTop:{xs: '-8rem', md: '-6.5rem'},
                marginBottom:{xs: '-8rem', md: '-6.5rem'} 
             }}  display="flex" alignItems="center">
            <Box sx={{
                width: {xs: "100%", md: 'auto'},
                textAlign: {xs: 'center', md:"left"}
                }}>
                <Typography gutterBottom variant="h4" color="black">
                    Book a Visa Consultation and simplify your visa process <span style={{ color: `var(--tblue)`, whiteSpace: 'nowrap' }}>with our experts.</span>
                </Typography>
                
            </Box>

            <Link style={{textDecoration: 'none'}} to={'/contact-us'}>
            <Box sx={{
                width:{xs:"auto", md:"auto"},
                marginTop: {xs: '.5rem', md: 'initial'}, cursor:'pointer'
                }} boxShadow=" 4px 4px 20px #00000029" borderRadius=".75rem" className="discover-now-box" display="flex" alignItems="center" justifyContent="space-around"
            >
                <Typography variant="body1" padding="1rem" whiteSpace='nowrap'>BOOK NOW</Typography>
                <Box padding="1rem" mt="5px">
                    <img src={arrowsvg} alt="arrow"></img>
                </Box>
            </Box>
            </Link>
            
        </Box>
        </Box>
    )
}

export default Homesection5mid
