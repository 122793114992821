import React from 'react'
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import arrowbluesvg from '../../Images/Icon feather-arrow-right-circle-blue.svg'
function CalculatorDiv({title, subtitle, link}) {
  return (
    <>
    <Link to={link} style={{textDecoration: 'none', color: '#2F2FA2'}}>
        <Box display='flex' alignItems='center' justifyContent="space-between" p='1rem'
            sx={{width: '100%', boxShadow:'4px 4px 20px #00000029', border: '1px solid #2F2FA2;', borderRadius: '1.5rem'}}
        >
            <Box display='flex' flexDirection='column' gap='1.5rem'>
                <Typography variant="body2" sx={{color: '#2F2FA2'}}>{subtitle}</Typography>
                <Typography variant="body1" sx={{ fontSize: { xs: '1.3rem', md: "1.6rem !important" }, color: '#2F2FA2' }} fontWeight="400 !important">{title}</Typography>
            </Box>
            <img src={arrowbluesvg} alt="arrow_blue"></img>
        </Box>
    </Link>
    </>
  )
}

export default CalculatorDiv