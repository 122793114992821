import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Bookconsultation from "./Common Components/Bookconsultation";
import Wheretoapply from "./Common Components/Wheretoapply";
import Header from "./Homepage/Header";
import Homesection5mid from "./Homepage/Homesection5mid";
import Footer from "./Homepage/Footer";
import Shortstaycard from "./Short stay visa page/Shortstaycard";
// import Additionalservices from './Common Components/Additionalservices';
import parentpic from "../Images/Accompanying ill family member.jpg";
import seasonalpic from "../Images/TemporaryStay/temp stay E8 - Seasonal Work (more than 90 days).jpg";
import corporatepic from "../Images/TemporaryStay/temp stay E2 - Intra Corporate Transfer.jpg";
import workertransferpic from "../Images/TemporaryStay/temp stay E2 - Temporary stay visa for transfer of workers.jpg";
import e4highedupic from "../Images/TemporaryStay/temp stay E4 - Higher Education Visa.jpg";
import e6studentexchangepic from "../Images/TemporaryStay/temp stay E6 - Student Exchange.jpg";
import worktrainingpic from "../Images/TemporaryStay/temp stay E6 - work training.jpg";
import e6unpaidinternpic from "../Images/TemporaryStay/temp stay E6 unpaid internship.jpg";
import volunteerpic from "../Images/TemporaryStay/temp stay volunteer.jpg";
import highqualifiedpic from "../Images/TemporaryStay/temp visa E4 - Highly Qualified Activity Visa.jpg";
import profactivitypic from "../Images/TemporaryStay/temp stay E4 - Professional Activity Visa.jpg";
import commitpic from "../Images/TemporaryStay/temp stay E6 - Commitments pursuant to international agreements or conventions.jpg";
import studyprogpic from "../Images/TemporaryStay/temp stay study program.jpg";
import sciencepic from "../Images/Science.jpg";
import amateurpic from "../Images/Amateur Sports.jpg";
import edupic from "../Images/Educational Course.jpg";
import indepic from "../Images/Independent Work.jpg";
import medipic from "../Images/Medical Treatment.jpg";
import "../Styling/desktop/visastyle.css";
import { Helmet } from "react-helmet";

function Temporarystayvisa() {
  useEffect(() => {
    document.title = "Temporary Stay Visa Portugal | Visa Discovery";
  }, []);
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Visa Discovery",
                item: "https://visadiscovery.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Temporary Stay Visas",
                item: "https://visadiscovery.com/portugal-temporary-stay-visa",
              },
            ],
          })}
        </script>
      </Helmet>
      <Box width="100vw" padding="1rem 0 0 0" maxWidth="100%">
        <Header />
        <Box
          className="visas-container"
          width="100%"
          mt="calc(12vh + 1rem)"
          display="flex"
          sx={{
            padding: { xs: "0rem 1.5rem 3rem" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "80%" },
              padding: { xs: "0rem 0 3rem", md: "0rem 2rem 0 0" },
            }}
            height="100%"
          >
            <Typography gutterBottom variant="h1" fontSize="4.5rem">
              Temporary Stay Visas
            </Typography>
            <Typography gutterBottom variant="body2">
              A Temporary Stay visa grants you permission to stay in Portugal
              for up to 1 year. These visas are perfect for people who are
              coming to Portugal for a long period of time for any professional
              or personal reasons.
            </Typography>
            <Typography gutterBottom variant="body2">
              Here is a list of visas along with the purpose of travel -
            </Typography>
            <Typography
              gutterBottom
              variant="h4"
              color="#2F2FA2"
              fontWeight="bold"
              mt="1.5rem"
            >
              Type of Temporary Stay Visas
            </Typography>
            <Box>
              <Grid container spacing={2} rowSpacing={6}>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={medipic}
                    title="Medical Treatment"
                    altText="temporary stay visa portugal"
                    tagName="Visa E1"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E1 - Medical Treatment"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={corporatepic}
                    tagName="Visa E2"
                    title="Intra Corporate Transfer"
                    altText="temporary resident visa portugal"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E2 - Intra Corporate Transfer Of Citizens Between States Party To Wto"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={workertransferpic}
                    tagName="Visa E2"
                    altText="temporary stay visa in portugal"
                    title="Temporary stay visa for transfer of workers purposes for state parties to the WTO, for provision of services or professional training"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E2 - Intra Corporate Transfer Of Citizens Between States Party To Wto"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={indepic}
                    tagName="Visa E3"
                    title="Independent Work"
                    altText="independent work visa portugal"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E3 - Independent Work"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={sciencepic}
                    tagName="Visa E4"
                    title="Scientific Research Visa"
                    altText="portugal research visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E4 - Scientific Research/ University Teaching Position/ Highly Qualified Activity"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={e4highedupic}
                    tagName="Visa E4"
                    title="Higher Education Visa"
                    altText="study visa portugal"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E4 - Scientific Research/ University Teaching Position/ Highly Qualified Activity"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={profactivitypic}
                    tagName="Visa E4"
                    title="Professional Activity Visa"
                    altText="professional visa portugal"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E4 - Scientific Research/ University Teaching Position/ Highly Qualified Activity"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={highqualifiedpic}
                    tagName="Visa E4"
                    title="Highly Qualified Activity Visa"
                    altText="E4 portugal visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E4 - Scientific Research/ University Teaching Position/ Highly Qualified Activity"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={amateurpic}
                    tagName="Visa E5"
                    title="Amateur Sports"
                    day="Upto 1 year"
                    altText="sports visa in portugal"
                    cost="EUR 75"
                    description="E5 - Amateur Sports"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={studyprogpic}
                    tagName="Visa E6"
                    title="Study Programs Visa"
                    altText="how to apply portugal study visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E6 - Student Exchange/ Unpaid Professional Internship/ Volunteer Work"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={e6studentexchangepic}
                    tagName="Visa E6"
                    title="Student Exchange"
                    altText="student visa portugal"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E6 - Student Exchange/ Unpaid Professional Internship/ Volunteer Work"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={e6unpaidinternpic}
                    tagName="Visa E6"
                    title="Unpaid Internship"
                    altText="E6 portugal visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E6 - Student Exchange/ Unpaid Professional Internship/ Volunteer Work"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={volunteerpic}
                    tagName="Visa E6"
                    title="Volunteer Work"
                    altText="volunteer work visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E6 - Student Exchange/ Unpaid Professional Internship/ Volunteer Work"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={commitpic}
                    tagName="Visa E6"
                    title="Commitments pursuant to international agreements or conventions"
                    altText="portugal temporary stay visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E6 - Student Exchange/ Unpaid Professional Internship/ Volunteer Work"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={worktrainingpic}
                    tagName="Visa E6"
                    title="Work Training"
                    altText="work training visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E6 - Student Exchange/ Unpaid Professional Internship/ Volunteer Work"
                    applicationtime="30 days"
                  />
                </Grid>
                {/* <Grid item sm={6} lg={4}>
                                <Shortstaycard pic={studentpic} tagName="Visa E6" title="Study Programs Visa" day="Upto 1 year" cost="EUR 75" description="" applicationtime="30 days"/>
                            </Grid> */}
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={parentpic}
                    tagName="Visa E7"
                    title="Accompanying family member undergoing medical treatment purposes"
                    altText="temporary residence permit portugal"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E7 - Accompanying Of A Currently Ill Family Member."
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={seasonalpic}
                    tagName="Visa E8"
                    title="Seasonal Work (more than 90 days)"
                    altText="portugal temporary visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E8 - Seasonal Work"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    pic={edupic}
                    tagName="Visa E9"
                    title="Course in Educational or professional training institution"
                    altText="portugal e9 visa"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description="E9 - Educational / Professional Course"
                    applicationtime="30 days"
                  />
                </Grid>
                <Grid item sm={6} lg={4}>
                  <Shortstaycard
                    title="Exceptional and duly justified cases"
                    altText="portugal visa 2022"
                    day="Upto 1 year"
                    cost="EUR 75"
                    description=""
                    applicationtime="30 days"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "20%" },
              mb: { xs: "1rem", md: "1rem" },
            }}
          >
            <Bookconsultation fixed="fixed" title="Temporary Stay Visa Page" />
          </Box>
        </Box>
        <Wheretoapply />
        {/* <Additionalservices /> */}

        <Homesection5mid />
        <Footer />
      </Box>
    </>
  );
}

export default Temporarystayvisa;
