import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Header from '../Homepage/Header'
import Footer from '../Homepage/Footer'
import Inquiryform from '../Common Components/Inquiryform';
import '../../Styling/desktop/contactus.css';
import Servicecard from './Servicecard';
import Shortstaycard2 from '../Common Components/Shortstaycard2';
import '../../Styling/desktop/visastyle.css';
function Services4() {
    const history = useHistory();
    useEffect(()=>{
        document.title="Foreign Exchange | Service | Visa Discovery"
      },[])
    return (
        <Box width="100vw" maxWidth="100%" paddingTop="1rem">
            <Header />
            <Box className="services-cover-container" mt="-1rem" width="100%" height="45vh" textAlign="center">
                <Typography variant="h4" color="white" paddingTop="30vh ">Foreign Exchange</Typography>
            </Box>
            <Box className="services-box" sx={{ width: { xs: 'calc(100vw - 3rem)' }, mt: '2rem', mb: '2rem' }} margin="auto">
                <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ width: { xs: '100%', md: 'calc(100% - 30rem)' }, paddingRight: { xs: '0', md: '3rem' } }}>
                        <Typography mb=".5rem" variant="h4" ml="0px important">Foreign Exchange</Typography>
                        <Typography variant="body2" style={{ textAlign: 'justify' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Typography>
                        <Typography mt="1rem" mb="1rem" variant="h4" ml="0px important">Service Providers</Typography>
                        <Servicecard
                            title="ForeignExchange.com"
                            caption="LearnPortugues.com is an online portal that connects you with real Portuguese people to help you learn."
                            location="Mumbai"
                            website="ForeignExchange.com"
                        />
                        <Servicecard
                            title="ForeignExchange.com"
                            caption="LearnPortugues.com is an online portal that connects you with real Portuguese people to help you learn."
                            location="Mumbai"
                            website="ForeignExchange.com"
                        />
                        <Servicecard
                            title="ForeignExchange.com"
                            caption="LearnPortugues.com is an online portal that connects you with real Portuguese people to help you learn."
                            location="Mumbai"
                            website="ForeignExchange.com"
                        />
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '30rem' }, display: { xs: 'none', md: 'block' } }}>
                        <Inquiryform caller="Service: Foreign Exchange" />
                    </Box>
                </Box>
                <Box mt="2rem" width="100%" >
                    <Typography mb="1rem" variant="h4" ml="0px important">Other Services</Typography>
                    <Box width="100%" display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }} alignItems="center" gap="2rem">
                        <Shortstaycard2
                            title="Insurance"
                            description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                            path='/services2'
                        />
                        <Shortstaycard2
                            title="Visa Application Agents"
                            description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                            path='/services3'
                        />
                        <Shortstaycard2
                            title="Learn Portuguese"
                            description="Make your application stronger by learning the Portuguese language with organisations and teachers near you."
                            path='/services1'
                        />
                    </Box>
                </Box>
                <Box mt="2rem" sx={{ width: { xs: '100%', md: '30rem' }, display: { xs: 'block', md: 'none' } }}>
                    <Inquiryform caller="Service: Foreign Exchange"/>
                </Box>
            </Box>
            <Footer />
        </Box>
    )
}

export default Services4
