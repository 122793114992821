import React from 'react';
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IRRtooltip from './IRRtooltip';

export default function Button({BtnName, step=1, value, setValue, min=0, tooltiptxt}){
    return (
        <>
          <Box display="flex" flexDirection='column'>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography gutterBottom variant="overline" style={{textTransform: 'initial'}} fontWeight="400">{BtnName}</Typography>
                    <IRRtooltip titletxt={tooltiptxt}/>
                </Box>
                <Box width='240px' height="40px" display="flex" alignItems="center" justifyContent="space-between" style={{ boxShadow: '4px 4px 20px #00000029', borderRadius: '10px' }}>
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() =>{if(value-1<min){return};setValue(prevValue=>Number(prevValue) - Number(step))}}>
                        <RemoveIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                    <Box fontWeight="400" textAlign='center' style={{fontSize: '16px'}}>
                        <Typography variant='body2'>{value}</Typography>
                    </Box>
                    <IconButton sx={{ borderRadius: '10px', backgroundColor: '#F0F0FF' }} onClick={() =>setValue(prevValue=>Number(prevValue) + Number(step))}>
                        <AddIcon style={{ fill: "#2F2FA2" }} />
                    </IconButton>
                </Box>
            </Box>
        </>
    )
}